/*
 * IVF Payment
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Divider,
  Box,
} from "@mui/material";

import { SadActionButtons } from "../../../sad/sad-user/components/ActionButtons";
import { AllRoutes } from "../../../../routes";
import { CommonModal, HeadAccordion, Tooltip } from "../../../../components";
import { IvfApis, PaymentApis } from "../../../../api";
import { formattedMDYDate } from "../../../../utils/CommonUtils";
import { INSURANCE_TYPES } from "../../../../constants";
import { usePlansWithoutRiders } from "../../../../hooks";

const IVFPayment = ({ setLoading, parentId, applicationsLength }) => {
  const navigate = useNavigate();
  const { id: application_id } = useParams();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [payNowLater, setPayNowLater] = useState(true);
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const { credit_card_charges } = lookups ?? {};
  const { getIndemnityLabelByValue } = usePlansWithoutRiders(
    INSURANCE_TYPES.ivf
  );
  const invoice_id = applications[0]?.invoice_id;

  useEffect(() => {
    setLoading(false);
    if (application_id) {
      getSummaryId();
    } else {
      getSummary();
    }
  }, []);

  useEffect(() => {
    setInvoiceIds(applications?.map((x) => x?.invoice_id));
  }, [applications]);

  const getSummary = async () => {
    setLoading(true);
    const resp = await IvfApis.getSummaryData({
      parent_id: parentId,
    });
    setLoading(false);
    if (resp?.success) {
      setApplications(resp?.data);
    }
  };

  const getSummaryId = async () => {
    setLoading(true);
    const resp = await IvfApis.getApplicationData({
      application_id: application_id,
    });
    setLoading(false);
    if (resp?.success) {
      setApplications([resp?.data]);
    }
  };

  const createPayment = async () => {
    setButtonLoading(true);
    try {
      if (payNowLater == false) {
        const resp = await Promise.all(
          invoiceIds?.map((i_id) =>
            PaymentApis.createPayment({
              invoice_id: i_id,
              insurance_type: INSURANCE_TYPES.ivf,
              payment_mode: "offline",
            })
          )
        );
        !!resp && setButtonLoading(false);
      }
      if (payNowLater == true) {
        const resp = await PaymentApis.createPayment({
          invoice_id,
          insurance_type: INSURANCE_TYPES.ivf,
        });
        !!resp && setButtonLoading(false);
        payNowLater && !!resp?.success && (window.location = resp?.data?.url);
      }
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const DividerLine = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider aria-hidden="true" />
        </Grid>
      </Grid>
    );
  };

  const GridItem = ({
    keyName,
    description,
    value,
    value1,
    value2,
    value3,
    value4,
  }) => {
    return (
      <>
        <Grid item xl={6} lg={5} md={4} sm={5} xs={5} mb="0.5rem">
          <Typography
            sx={{
              fontSize: "0.9rem",
              lineHeight: "1.5rem",
              paddingLeft: "25px",
              textAlign: "left",
            }}
          >
            {keyName}:
          </Typography>
          {!!description && (
            <Typography
              sx={{
                fontSize: "0.7rem",
                paddingLeft: "25px",
                textAlign: "left",
                my: "0.5rem",
              }}
            >
              {description}
            </Typography>
          )}
        </Grid>
        <Grid item xl={6} lg={7} md={8} sm={7} xs={7}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              lineHeight: "2rem",
              textAlign: "right",
            }}
          >
            {value} {value1} {value2} {value3} {value4}{" "}
          </Typography>
        </Grid>
      </>
    );
  };

  const getTotalAmount = () => {
    let total = 0;
    applications.forEach((application) => {
      let amount = payNowLater
        ? Number(application?.invoice_total) +
          Number((application?.invoice_total * credit_card_charges) / 100)
        : application?.invoice_total;
      total = Number(total) + Number(amount || 0);
    });
    return total?.toFixed(2);
  };

  const getPaymentCredit = () => {
    let total = 0;
    applications.forEach((application) => {
      total = Number(total) + Number(application?.invoice_payment_credits || 0);
    });
    return total?.toFixed(2);
  };

  return (
    <>
      {applications?.map((application, index) => (
        <HeadAccordion
          key={index}
          showDelete={false}
          defaultExpanded={true}
          header={`Application-${index + 1}`}
          showAccordion={applicationsLength > 1 ? true : false}
          value={`$${
            payNowLater
              ? (
                  Number(application?.invoice_total) +
                  Number(
                    (application?.invoice_total * credit_card_charges) / 100
                  )
                )?.toFixed(2)
              : application?.invoice_total
          }`}
        >
          <Grid container columnSpacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box>
                <Typography
                  component={applications.length > 1 ? "h3" : "h2"}
                  sx={{
                    fontSize: "1.05em",
                    fontWeight: "bold",
                    lineHeight: "3rem",
                    bgcolor: "#E7EAEC",
                    paddingLeft: "20px",
                  }}
                >
                  Billing To
                </Typography>
              </Box>
              <Grid container sx={{ paddingY: 3 }}>
                <GridItem
                  keyName="Name"
                  value={application?.invoice_billto_name || "-"}
                />
                <GridItem
                  keyName="Address"
                  value={application?.invoice_billto_address || "-"}
                />
                <GridItem
                  keyName="Email"
                  value={application?.created_by_email}
                />
                {!!application.financial_email &&
                  application.financial_email !==
                    application?.created_by_email && (
                    <GridItem
                      keyName="Additional Email(s)"
                      value2={application.financial_email.replace(/;/g, ", ")}
                    />
                  )}

                <GridItem
                  keyName="Date of Application"
                  value={formattedMDYDate()}
                />
                <GridItem
                  keyName="Policy Number"
                  value={application.application_number}
                />
                <GridItem
                  keyName="Limit of Indemnity"
                  value={getIndemnityLabelByValue(
                    application?.coverage_type,
                    application?.term_length,
                    application?.idemnity_limit
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box>
                <Typography
                  component={applications.length > 1 ? "h3" : "h2"}
                  sx={{
                    fontSize: "1.05em",
                    fontWeight: "bold",
                    lineHeight: "3rem",
                    bgcolor: "#E7EAEC",
                    paddingLeft: "20px",
                  }}
                >
                  Order Summary
                </Typography>
              </Box>
              <Grid container sx={{ paddingY: 3 }}>
                {(payNowLater
                  ? [
                      ...application?.invoice_line_items,
                      {
                        label: "Administrative Fee",
                        description: "Non refundable fee",
                        total: (
                          Number(
                            application?.invoice_total * credit_card_charges ||
                              0
                          ) / 100
                        )?.toFixed(2),
                      },
                    ]
                  : application?.invoice_line_items
                )?.map((item, index) => (
                  <GridItem
                    key={index}
                    keyName={item?.label}
                    description={item?.description}
                    value={"$" + item?.total}
                  />
                ))}
              </Grid>

              <DividerLine />
              <DividerLine />
              <Grid container>
                <Grid item xl={6} lg={5} md={5} sm={5} xs={5}>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      color: "black",
                      lineHeight: "2rem",
                      paddingLeft: "25px",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Total:
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={7} md={7} sm={7} xs={7}>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "800",
                      lineHeight: "2rem",
                      textAlign: "right",
                    }}
                  >
                    {payNowLater
                      ? `$${
                          (
                            Number(application?.invoice_total) +
                            Number(
                              (application?.invoice_total *
                                credit_card_charges) /
                                100
                            )
                          )?.toFixed(2) ?? "0.00"
                        }`
                      : `$${
                          Number(application?.invoice_total)?.toFixed(2) ??
                          "0.00"
                        }`}
                  </Typography>
                </Grid>
              </Grid>
              <DividerLine />
              <DividerLine />
              <Grid container>
                <Grid item xl={6} lg={5} md={5} sm={5} xs={5}>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      color: "black",
                      lineHeight: "2rem",
                      paddingLeft: "25px",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    Payment Made:
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={7} md={7} sm={7} xs={7}>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "800",
                      lineHeight: "2rem",
                      textAlign: "right",
                    }}
                  >
                    {application?.invoice_payment_credits !== null
                      ? `$${Number(
                          application?.invoice_payment_credits
                        )?.toFixed(2)}`
                      : "$0.00"}
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={5} md={5} sm={5} xs={5}>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      lineHeight: "2rem",
                      paddingLeft: "25px",
                      textAlign: "left",
                    }}
                  >
                    Balance Due:
                  </Typography>
                </Grid>
                <Grid item xl={6} lg={7} md={7} sm={7} xs={7}>
                  <Typography
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "800",
                      lineHeight: "2rem",
                      textAlign: "right",
                    }}
                  >
                    {payNowLater
                      ? `$${
                          (
                            Number(application?.invoice_total) +
                            Number(
                              (application?.invoice_total *
                                credit_card_charges) /
                                100
                            )
                          )?.toFixed(2) ?? "0.00"
                        }`
                      : `$${
                          Number(application?.invoice_total)?.toFixed(2) ??
                          "0.00"
                        }`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </HeadAccordion>
      ))}
      {applicationsLength > 1 && (
        <>
          <DividerLine />
          <DividerLine />
          <Grid container>
            <Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "black",
                  lineHeight: "2rem",
                  paddingLeft: "25px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                Total:
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "800",
                  lineHeight: "2rem",
                  textAlign: "right",
                }}
              >
                ${getTotalAmount()}
              </Typography>
            </Grid>
          </Grid>
          <DividerLine />
          <DividerLine />
          <Grid container>
            <Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "black",
                  lineHeight: "2rem",
                  paddingLeft: "25px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                Payment Made:
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "800",
                  lineHeight: "2rem",
                  textAlign: "right",
                }}
              >
                ${getPaymentCredit()}
              </Typography>
            </Grid>
            <Grid item xl={11} lg={11} md={11} sm={10} xs={10}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                  lineHeight: "2rem",
                  paddingLeft: "25px",
                  textAlign: "right",
                }}
              >
                Balance Due:
              </Typography>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  fontWeight: "800",
                  lineHeight: "2rem",
                  textAlign: "right",
                }}
              >
                ${getTotalAmount()}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <DividerLine />
      <DividerLine />

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            component="h2"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginY: "1.5rem",
            }}
          >
            Payment
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <FormLabel
              id="payment-options"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              Select a Payment Options and Continue
            </FormLabel>
            <RadioGroup
              sx={{ alignItems: "center", justifyContent: "space-around" }}
              row
              aria-labelledby="payment-options"
              name="payment-options-group"
            >
              <FormControlLabel
                control={<Radio />}
                label="Pay Now"
                checked={applications.length > 1 ? false : !!payNowLater}
                value={!!payNowLater}
                disabled={buttonLoading || applications.length > 1}
                onChange={() => setPayNowLater(true)}
              />
              <Tooltip
                placement="bottom"
                tooltip={`Online payments will include a ${
                  credit_card_charges || 0
                }% administrative fee`}
              />
              <FormControlLabel
                control={<Radio />}
                label="Pay Later"
                disabled={buttonLoading}
                checked={!payNowLater}
                value={!payNowLater}
                onChange={() => setPayNowLater(false)}
                sx={{ marginLeft: "1rem" }}
              />
              <Tooltip
                placement="bottom"
                tooltip="You have 15 days to complete your payment"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <SadActionButtons
        OnPressContinue={() => {
          createPayment();
          !payNowLater && setUpdateModal(true);
        }}
        disableContinue={applications.length > 1 && !!payNowLater}
        buttonLoading={buttonLoading}
      />

      {/* Modal when user proceed with pay-later option */}
      <CommonModal
        open={updateModal}
        type="success"
        {...(applications.length == 1 && {
          onCancel: () => setUpdateModal(false),
        })}
        onConfirm={() => navigate(AllRoutes.IvfPoliciesPage.route)}
        title="Application Submitted!"
        subTitle="Your Application has been successfully submitted. Payment is required for your policy to be effective. Payment is due by the date on your invoice. Failure to receive prompt payment can affect your eligibility and/or effective date of your policy. If Payment is not received by the due date on your invoice, your application will be canceled and voided with or without notice given by New Life Agency, Inc. "
        subTitle2="Thank you for applying!"
      />
    </>
  );
};

export { IVFPayment };
