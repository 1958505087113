/*
 * SAD form action buttons
 */

import React from "react";
import { AccordionActions } from "@mui/material";

import { theme } from "../../../../styles/theme";
import { Button } from "../../../../components";

const SadActionButtons = ({
  disableBack = false,
  disableContinue = false,
  onPressBack,
  OnSaveExit,
  OnPressContinue,
  confirmButtonLabel = "Continue",
  buttonLoading,
  disableSave,
  saveExitButtonLoading,
}) => {
  return (
    <AccordionActions sx={{ justifyContent: "center" }}>
      <Button
        variant="outlined"
        size="large"
        disableElevation
        style={{
          border: `2px ${theme.grey} solid`,
        }}
        title="Back"
        id="back-button"
        sx={{
          color: theme.grey,
          display: !!onPressBack ? "block" : "none",
          "&:hover": { background: theme.fadeGrey },
          "&.Mui-disabled": {
            background: theme.lightGrey,
            color: theme.white,
          },
        }}
        disabled={buttonLoading || saveExitButtonLoading || disableBack}
        onClick={onPressBack}
      />
      <Button
        variant="outlined"
        size="large"
        style={{ border: `2px ${theme.primaryDark} solid` }}
        title="Save & Exit"
        sx={{ display: !!OnSaveExit ? "block" : "none" }}
        id="save-exit-button"
        loading={saveExitButtonLoading}
        disabled={buttonLoading || saveExitButtonLoading || disableSave}
        onClick={OnSaveExit}
      />
      <Button
        variant="contained"
        type="submit"
        size="large"
        style={{ border: `2px ${theme.primaryDark} solid` }}
        id="continue-button"
        disabled={disableContinue || buttonLoading || saveExitButtonLoading}
        loading={buttonLoading}
        title={confirmButtonLabel}
        onClick={OnPressContinue}
      />
    </AccordionActions>
  );
};

export { SadActionButtons };
