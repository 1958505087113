/*
 * BROKER SAD- TABLE DATA
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  RenderTableHeader,
  Tooltip,
} from "../../../../components";
import { PaymentTypes, PolicyTypes } from "../../../sad/constants";
import { formatString, formattedMDYDate } from "../../../../utils/CommonUtils";
import { AllRoutes } from "../../../../routes";
import ReactTable from "../../../../React Table";
import { AttachmentApis } from "../../../../api";
import { coverageTypeLabel, strings } from "../../../../constants";
import { setSadSummary } from "../../../../redux/slices/globalSlice";

const { canceled, underReview } = PolicyTypes;

const TableData = ({
  caption,
  dataList,
  listData,
  setSearchText,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  loading,
  dataLoading,
  setSearchParams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [hiddenTitle, setHiddenTitle] = useState("");

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const openActionDropDown = (e, rowData) => {
    const application_id = rowData?.id || {};
    setClickable([
      {
        text: "Add comment",
        route: () => {
          dispatch(setSadSummary({}));
          navigate(`${AllRoutes.SadBrokerAddNotes.route}/${application_id}`);
        },
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const getPolicyDocs = async (application_id, policy_doc_id) => {
    setShowDocumentModal(true);
    setHiddenTitle(formatString(policy_doc_id));

    const payload = { application_id, policy_doc_id };
    const resp = await AttachmentApis.getPolicyDoc(payload);
    !!resp ? setDocumentLink(resp) : setShowDocumentModal(false);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => {
        const { is_suspended, status } = row?.original ?? {};
        return (
          <IconButton
            disabled={status === canceled && !is_suspended}
            onClick={(e) => openActionDropDown(e, row.original)}
            aria-label={"More actions of " + row.original?.application_number}
          >
            <MoreHorizIcon />
          </IconButton>
        );
      },
    },
    {
      id: "Policy Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        const { is_suspended } = info?.row?.original ?? {};
        return (
          <RenderChip
            label={
              status === underReview
                ? "Submitted"
                : status === canceled && is_suspended
                ? strings.suspended
                : undefined
            }
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: "Policy Number",
      accessorKey: "application_number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Coverage Type"
          headerKey="coverage_type"
        />
      ),
      accessorKey: "coverage_type",
      cell: (info) => {
        const { coverage_type, insurance_type } = info.row?.original;
        return (
          <RenderCellInfo
            text={coverageTypeLabel(insurance_type, coverage_type)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Alert Status",
      header: "Alert Status",
      accessorKey: "alerts",
      cell: (info) => {
        const { cleared_at } = info?.row?.original ?? {};
        const alertStatus = info.getValue()?.length
          ? info
              .getValue()
              ?.map((alert, index) => (
                <div key={index}> {`${alert ?? ""}`}</div>
              ))
          : "";
        return info.getValue()?.length === 0 ? (
          <div className="text-center">
            <Tooltip title="No Alerts">
              <FiberManualRecordIcon
                fontSize="small"
                className="none-text-grey"
                title={undefined}
              />
            </Tooltip>
            <span className="hidden-visually">No Alerts</span>
          </div>
        ) : (
          <div className="text-center">
            <Tooltip tooltip={alertStatus}>
              {!!cleared_at ? (
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              ) : (
                <FiberManualRecordIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              )}
            </Tooltip>
            <div className="hidden-visually">{alertStatus}</div>
          </div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      accessorKey: "invoice_payment_status",
      cell: (info) => {
        const paymentStatus = info.getValue();
        let icon;

        if (paymentStatus == PaymentTypes.paid) {
          icon = (
            <>
              <Tooltip title={"Paid"}>
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Paid</span>
            </>
          );
        } else if (paymentStatus === PaymentTypes.pending) {
          icon = (
            <>
              <Tooltip title={"Pending"}>
                <WatchLaterIcon
                  fontSize="small"
                  className="none-text-orange"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Pending</span>
            </>
          );
        } else {
          icon = (
            <>
              <Tooltip title={"Unpaid"}>
                <CancelIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Unpaid</span>
            </>
          );
        }
        return (
          <div style={{ textAlign: "center", margin: "auto" }}>{icon}</div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { allowed_policy_docs } = info.row.original ?? {};
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {allowed_policy_docs?.map?.((d) => (
                    <RenderDocumentIcon
                      key={d.label}
                      tooltip={d?.label}
                      policy_doc_id={d?.document_id}
                      info={info}
                      getPolicyDocs={getPolicyDocs}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "State",
      accessorKey: "intended_parent_state",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State"
          headerKey="intended_parent_state"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Applicant Name & Email",
      accessorKey: "intended_parent1_first_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Applicant Name & Email"
          headerKey="intended_parent1_first_name"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={
            <Box>
              <Typography>
                {info?.row?.original?.intended_parent1_first_name || ""}
              </Typography>
              <Typography>
                {info?.row?.original?.intended_parent1_email || ""}
              </Typography>
            </Box>
          }
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "IP Last Name",
      accessorKey: "intended_parent1_last_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="IP Last Name"
          headerKey="intended_parent1_last_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Surrogate Last Name",
      accessorKey: "surrogate_last_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Surrogate Last Name"
          headerKey="surrogate_last_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Agency",
      accessorKey: "agency_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Agency"
          headerKey="agency_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      accessorKey: "submitted_on",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Date"
          headerKey="submitted_on"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "MSD/COP",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="MSD/COP"
          headerKey="confirmation_of_pregnancy_date"
        />
      ),
      cell: (info) => {
        const {
          is_currently_pregnant,
          medication_start_date,
          confirmation_of_pregnancy_date,
        } = info?.row?.original;
        return (
          <RenderCellInfo
            text={
              !!confirmation_of_pregnancy_date || !!medication_start_date
                ? formattedMDYDate(
                    is_currently_pregnant
                      ? confirmation_of_pregnancy_date
                      : medication_start_date
                  )
                : ""
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "invoice_payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="invoice_payment_date"
        />
      ),
      cell: (info) => {
        const paymentDate = info.getValue();
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: "Effective Date",
      accessorKey: "effective_from",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: "Expiration Date",
      accessorKey: "effective_till",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expiration Date"
          headerKey="effective_till"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {/* Documents modal */}
      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        maxWidth="lg"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={documentLink}
            width="100%"
            height="500"
            style={{ display: !documentLink ? "none" : "block" }}
          />
        )}
      </CommonModal>
    </>
  );
};

export { TableData };
