/*
 *Admin -> Sales Report -> Table Header
 */

import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import { theme } from "../../styles/theme";
import { PaymentTypes } from "../sad/constants";
import { DateRangePicker, Dropdown, GridActionButtons } from "../../components";
import { INSURANCE_TYPES, INSURANCE_TYPES_LIST } from "../../constants";
import {
  convertDateRangeToString,
  oneMonthDateRangeString,
  oneMonthDateRangeText,
} from "../../utils/CommonUtils";

const TableHeader = ({
  advancedSearch,
  setAdvancedSearch,
  insuranceType,
  setInsuranceType,
  dateRange,
  setDateRange,
  effectiveDateRange,
  setEffectiveDateRange,
  paymentStatus,
  setPaymentStatus,
  setPage,
  searchButton,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  setSearchParams,
}) => {
  const setDefaultDate = () => {
    const curDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, curDate]);
    setEffectiveDateRange([null, null]);
  };

  useEffect(() => {
    setDefaultDate();
  }, []);

  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchButton(!searchButton);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      Object.entries(advancedSearch).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      return searchParams;
    });

    setAdvancedSearch(advancedSearch);
  };

  const setSearchObject = (key, value) => {
    setAdvancedSearch((p) => ({ ...p, [key]: value }));
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setAdvancedSearch((p) => ({
      ...p,
      ["submitted_on"]: convertDateRangeToString(newDateRange),
    }));
  };

  const handleEffectiveDateChange = (newDateRange) => {
    setEffectiveDateRange(newDateRange);
    setAdvancedSearch((p) => ({
      ...p,
      ["effective_from"]: convertDateRangeToString(newDateRange),
    }));
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(!searchButton);
    setSearchParams({
      ["submitted_on"]: oneMonthDateRangeText(),
      ["page"]: 1,
      ["insurance_type"]: INSURANCE_TYPES.ivf,
    });
    setPage(1);
    setInsuranceType(INSURANCE_TYPES.ivf);
    setDefaultDate();
    setPaymentStatus("");
    setAdvancedSearch({
      ...oneMonthDateRangeString("submitted_on"),
      ["insurance_type"]: INSURANCE_TYPES.ivf,
    });
  };

  return (
    <form>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginY: "1rem",
          paddingY: "1rem",
        }}
      >
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              size="small"
              label="Insurance Type"
              options={INSURANCE_TYPES_LIST}
              required={false}
              value={insuranceType}
              onChange={(e) => {
                setInsuranceType(e.target.value);
                setSearchObject("insurance_type", e.target.value);
              }}
              margin="none"
              sx={{ backgroundColor: theme.white }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateRangePicker
              dateRange={dateRange}
              handleDateChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DateRangePicker
              label="Effective"
              dateRange={effectiveDateRange}
              handleDateChange={handleEffectiveDateChange}
              maxDate={null}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Dropdown
              size="small"
              label="Payment Status"
              id="invoice_payment_status"
              options={[
                { label: "All", value: "" },
                { label: "Paid", value: PaymentTypes.paid },
                { label: "Pending", value: PaymentTypes.notAttempted },
              ]}
              required={false}
              value={paymentStatus}
              onChange={(e) => {
                setPaymentStatus(e.target.value);
                setSearchObject("invoice_payment_status", e.target.value);
              }}
              margin="none"
              sx={{ backgroundColor: "white" }}
            />
          </Grid>
        </Grid>

        <GridActionButtons
          onSearch={searchValues}
          onClear={onClear}
          searchButtonLoading={buttonLoading == "search"}
          clearButtonLoading={buttonLoading == "clear"}
          wrapperStyle={{ marginTop: "1rem" }}
        />
      </Box>
    </form>
  );
};

export { TableHeader };
