/*
 * SAD ADMIN Edit Form-Application Details Component // 1st tab
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { theme } from "../../../../styles/theme";
import { AddNewAgency } from "../../components/AddNewAgency";
import { SadActionButtons } from "../components/ActionButtons";
import { SadFormInitialValues } from "../../constants";
import {
  AgencyApis,
  BeneficiaryApis,
  CommonApis,
  SadApis,
} from "../../../../api";
import {
  Accordion,
  AddressInput,
  AutoComplete,
  CommonModal,
  DatePicker,
  Dropdown,
  PhoneInput,
  TextInput,
  Tooltip,
} from "../../../../components";
import {
  focusFirstErrorField,
  UtilFunctions,
  isErrorOpenAccordion,
  testNonLetter,
  constructStateList,
} from "../../../../utils/CommonUtils";
import {
  setSadSummary,
  sadGetBeneficiaries,
  setSadBeneficiaries,
} from "../../../../redux/slices/globalSlice";
import {
  CHARGE_GROUPS,
  financialEmailTooltip,
  INSURANCE_TYPES,
} from "../../../../constants";
import { usePlansWithoutRiders, usePlansWithRiders } from "../../../../hooks";

const Edit_ApplicationForm = ({ loading, setLoading }) => {
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const agencies = useSelector((state) => state.sadReducer.agencies);
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const beneficiariesData = useSelector(
    (state) => state.sadReducer.beneficiaries
  );
  const [formikErrors, setFormikErrors] = useState({});
  const [beneficiaryErrors, setBeneficiaryErrors] = useState([]);
  const [showAddNewAgency, setShowAddNewAgency] = useState(false);
  const [agencyList, setAgencyList] = useState(agencies);
  const [updateModal, setUpdateModal] = useState(false);
  const [indemnityLimitModal, setIndemnityLimitModal] = useState(false);
  const [isConfirmIndemnityLimit, setConfirmIndemnityLimit] = useState(false);
  const [surrogateDobModal, setSurrogateDobModal] = useState({
    show: false,
    text: "",
  });
  const [COP_MST_DateModal, setCOP_MST_DateModal] = useState(false);
  // const [countryStateModal, setCountryStateModal] = useState({
  //   show: false,
  //   text: "",
  // });
  // const [isIpCountryStateChanged, setIpCountryStateChanged] = useState(false);
  const [beneficiaryDeleteModal, setBeneficiaryDeleteModal] = useState({
    show: false,
    beneficiary: {},
    index: -1,
  });
  const [stateList, setStateList] = useState([]);
  const [agencyValue, setAgencyValue] = useState({});
  const [isBlackListedAgency, setBlackListedAgency] = useState(false);
  const [isNewAgencyAdded, setNewAgencyAdded] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");

  const {
    yes_no_list,
    sad_indemnity_surrogate_options_list,
    sad_indemnity_ip_options_list,
    countries_list,
    // restricted_states,
  } = lookups ?? {};

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: SadFormInitialValues,
  });

  const {
    fields,
    append,
    remove: removeBenFromFields,
  } = useFieldArray({
    name: "beneficiaries",
    keyName: "customId",
    control,
  });

  const isNotCurrentlyPregnant = watch("is_currently_pregnant") === 0;
  const isCurrentlyPregnant = watch("is_currently_pregnant") === 1;
  const disableLimit_Riders = !watch("term_length");
  const { uniqueTermLengths } = usePlansWithoutRiders(INSURANCE_TYPES.sad);
  const { getRiders } = usePlansWithRiders(INSURANCE_TYPES.sad);
  const reproductiveRiders = getRiders(
    watch("term_length"),
    CHARGE_GROUPS.REPRODUCTIVE_LOSS_RIDER
  );
  const disabilityRiders = getRiders(
    watch("term_length"),
    CHARGE_GROUPS.DISABILITY_RIDER
  );

  useEffect(() => {
    if (application_id) {
      dispatch(sadGetBeneficiaries({ application_id }));
    }
  }, [application_id]);

  // Get state list based on country for agencies
  useEffect(() => {
    !!watch("agency_country_code") && getStateList();
  }, [watch("agency_country_code")]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({
      country_code: watch("agency_country_code"),
    });
    const getStates = constructStateList(resp);
    setStateList(getStates);
  };

  // Get agency list based on state
  useEffect(() => {
    !!watch("agency_state_code") && getAgencyList();
  }, [watch("agency_state_code"), isNewAgencyAdded]);

  const getAgencyList = async () => {
    setLoading(true);
    const resp = await AgencyApis.getAgenciesList({
      country_code: watch("agency_country_code"),
      state_code: watch("agency_state_code"),
    });
    !!resp && setLoading(false);
    setAgencyList(resp);
  };

  // Set agency auto-complete field value
  useEffect(() => {
    setAgencyValue(agencyList?.find((i) => i.name === watch("agency_name")));
  }, [agencyList]);

  // Update formik values initially
  useEffect(() => {
    setDataInitially();
  }, [summaryData, beneficiariesData]);

  const setDataInitially = () => {
    if (summaryData) {
      // Set dates in form
      if (summaryData?.medication_start_date)
        setValue(
          "medication_start_date",
          dayjs(summaryData?.medication_start_date)
        );

      if (summaryData?.confirmation_of_pregnancy_date)
        setValue(
          "confirmation_of_pregnancy_date",
          dayjs(summaryData?.confirmation_of_pregnancy_date)
        );
      if (summaryData?.surrogate_dob)
        setValue("surrogate_dob", dayjs(summaryData?.surrogate_dob));

      // Set rest form data
      const {
        medication_start_date,
        confirmation_of_pregnancy_date,
        surrogate_dob,
        ...rest
      } = summaryData ?? {};
      Object.keys(rest).forEach((key) => {
        if (getValues()?.hasOwnProperty(key)) {
          setValue(key, rest[key]);
        }
      });
    }

    if (watch("beneficiaries").length && beneficiariesData?.length > 0) {
      beneficiariesData?.forEach((i) => {
        append(i);
      });
      removeBenFromFields(0);
    } else {
      if (fields?.length == 0) {
        append({
          ben_id: 1,
          application_id,
          first_name: "",
          last_name: "",
          relationship: "",
          email: "",
        });
      }
    }
  };

  // Show alert pop if IP's restricted country/state is selected
  // useEffect(() => {
  //   if (isIpCountryStateChanged) {
  //     if (
  //       watch("intended_parent_country_code") !== "US" ||
  //       watch("surrogate_country_code") !== "US"
  //     ) {
  //       setCountryStateModal({ show: true, text: "Non US Country" });
  //     } else if (
  //       restricted_states?.includes?.(watch("intended_parent_state_code"))
  //     ) {
  //       setCountryStateModal({ show: true, text: "Restricted State" });
  //     } else {
  //       setCountryStateModal({ show: false, text: "" });
  //     }
  //   }
  // }, [
  //   watch("intended_parent_country_code"),
  //   watch("intended_parent_state_code"),
  //   watch("surrogate_country_code"),
  // ]);

  //  Add new-beneficary
  const addBeneficiary = () => {
    if (fields.length < 6) {
      append({
        ben_id: fields.length + 1,
        application_id,
        first_name: "",
        last_name: "",
        relationship: "",
        email: "",
      });
      setTimeout(() => {
        document.getElementById(`first_name_${fields.length + 1}`)?.focus();
      }, 500);
    }
  };

  //  Remove last added-beneficary
  const removeBeneficiary = async () => {
    setButtonLoading("delete");
    const { beneficiary, index } = beneficiaryDeleteModal;
    if (!beneficiary?.ben_id) {
      const resp = await BeneficiaryApis.deleteBeneficiary({
        application_id,
        id: beneficiary.id,
      });
      !!resp && setButtonLoading("");
    }
    setButtonLoading("");
    setBeneficiaryDeleteModal({
      show: false,
      beneficiary: {},
      index: -1,
    });

    removeBenFromFields(index);
    setTimeout(() => {
      document.getElementById(`first_name_${index}`)?.focus();
    }, 500);
  };

  //  Handle beneficiary data change
  const handleBeneficiaryChange = (e, index) => {
    if (beneficiaryErrors?.length > 0) {
      const eventName = e.target.name;
      const fieldName = eventName?.split(".").pop();

      if (beneficiaryErrors?.[index]?.[fieldName]) {
        const updatedBeneficiaryErrors = [...beneficiaryErrors];
        updatedBeneficiaryErrors[index] = {
          ...updatedBeneficiaryErrors[index],
          [fieldName]: undefined,
        };
        setBeneficiaryErrors(updatedBeneficiaryErrors);
      }
    }
  };

  // Function to handle new SAD API errors
  const handleFormikErrors = (apiErrors) => {
    setFormikErrors({ ...formikErrors, ...apiErrors });
  };

  const deleteFormikError = (name) => {
    if (formikErrors.hasOwnProperty(name)) {
      const newState = { ...formikErrors };
      delete newState[name];
      setFormikErrors(newState);
    }
  };

  const handleInputChange = (e) => {
    deleteFormikError(e.target.name);
  };

  const handlePhoneChange = (name, value) => {
    setValue(name, value);
    deleteFormikError(name);
  };

  const handleBeneficiaryResp = (results = []) => {
    const beneficiariesErrors = [];
    results?.forEach((result, i) => {
      if (!result.success) {
        if (result?.errors) {
          beneficiariesErrors[i] = result.errors;
        }
      }
    });
    setBeneficiaryErrors(beneficiariesErrors);
    return beneficiariesErrors;
  };

  // On submit form
  const onSubmitForm = async (data) => {
    setButtonLoading("continue");

    if (
      (data?.indemnity_surrogates_beneficiary +
        data?.indemnity_intended_parents || 0) > 750000 &&
      !isConfirmIndemnityLimit
    ) {
      setIndemnityLimitModal(true);
      setButtonLoading("");
      return;
    }

    // open the accordion for error display
    isErrorOpenAccordion();

    // SAD new-application payload

    const { beneficiaries, ...rest } = data;

    let payload = rest;
    const {
      is_currently_pregnant,
      reproductive_organ_loss_rider,
      disability_rider,
      working_with_agency,
      intended_parent2_first_name,
      intended_parent2_last_name,
      intended_parent2_email,
      intended_parent2_phone,
    } = payload;

    payload = UtilFunctions.convertToDayjsYMDFormat(payload, [
      "medication_start_date",
      "confirmation_of_pregnancy_date",
      "surrogate_dob",
    ]);
    payload = UtilFunctions.deleteKeys(payload, [
      "agency_country_code",
      "agency_state_code",
      "agency_name",
      // "agency_contact_person_name",
      "agency_address_line_1",
      "agency_city",
      "agency_zipcode",
    ]);
    is_currently_pregnant === 1 &&
      (payload = UtilFunctions.deleteKeys(payload, ["medication_start_date"]));
    is_currently_pregnant === 0 &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "confirmation_of_pregnancy_date",
      ]));

    reproductive_organ_loss_rider === 0 &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "reproductive_organ_loss_rider_option",
      ]));
    disability_rider === 0 &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "disability_rider_option",
      ]));
    if (
      !intended_parent2_first_name &&
      !intended_parent2_last_name &&
      !intended_parent2_email &&
      intended_parent2_phone === "+1"
    ) {
      payload.intended_parent2_phone = null;
    }
    working_with_agency !== 1 &&
      (payload = UtilFunctions.deleteKeys(payload, [
        "agency_id",
        "agency_name",
        "agency_contact_person_name",
        "agency_country_code",
        "agency_state_code",
      ]));

    // ADD SAD-new-application API
    const addNewSADResp = await SadApis.updateApplication({
      application_id,
      params: payload,
    });
    addNewSADResp?.errors
      ? handleFormikErrors(addNewSADResp?.errors)
      : setFormikErrors({});

    focusFirstErrorField();

    // Add-update sad beneficiary API
    let beneficiariesArray = data.beneficiaries || [];
    const beneficiaryResp = [];
    for (let i = 0; i < beneficiariesArray.length; i++) {
      const beneficiary = beneficiariesArray[i];
      let response;
      if (beneficiary?.ben_id) {
        response = await BeneficiaryApis.createBeneficiary({
          application_id,
          params: beneficiary,
        });
      } else {
        response = await BeneficiaryApis.updateBeneficiary({
          application_id,
          id: beneficiary.id,
          params: beneficiary,
        });
      }
      if (response && response.success && response?.data) {
        beneficiariesArray[i] = {
          ...beneficiariesArray[i],
          id: response?.data?.id,
        };
        delete beneficiariesArray[i].ben_id;
      }
      beneficiaryResp.push(response);
      if (response?.errors) {
        break;
      }
    }

    handleBeneficiaryResp(beneficiaryResp || []);
    // Update beneficiaries with the new IDs
    setValue("beneficiaries", beneficiariesArray);

    if (addNewSADResp && beneficiaryResp) {
      setButtonLoading("");
    }

    if (
      !!addNewSADResp &&
      !!addNewSADResp?.success &&
      !addNewSADResp?.errors &&
      !handleBeneficiaryResp(beneficiaryResp)?.length
    ) {
      dispatch(setSadSummary(addNewSADResp?.data));
      dispatch(setSadBeneficiaries([]));
      // dispatch(sadGetBeneficiaries({ application_id }));
      setUpdateModal(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      {/* General Information */}
      <Accordion
        defaultExpanded={true}
        header="General Information"
        component="h2"
        name="general-info"
      >
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Dropdown
              label="Is Surrogate Currently Pregnant?"
              id="is_currently_pregnant"
              disabled={buttonLoading}
              options={yes_no_list}
              value={watch("is_currently_pregnant")}
              register={register}
              registerChange={() =>
                setValue(
                  isCurrentlyPregnant
                    ? "medication_start_date"
                    : "confirmation_of_pregnancy_date",
                  ""
                )
              }
              isError={formikErrors.is_currently_pregnant}
              errorMsg={formikErrors.is_currently_pregnant}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <DatePicker
              disableFuture={isCurrentlyPregnant}
              id={
                isNotCurrentlyPregnant
                  ? "medication_start_date"
                  : "confirmation_of_pregnancy_date"
              }
              label={
                isNotCurrentlyPregnant
                  ? "Medication Start Date"
                  : "Confirmation of Pregnancy Date"
              }
              disabled={
                buttonLoading ||
                (!isCurrentlyPregnant && !isNotCurrentlyPregnant)
              }
              {...(isNotCurrentlyPregnant && {
                minDate: dayjs().subtract(35, "weeks"),
              })}
              {...(isNotCurrentlyPregnant && {
                maxDate: dayjs().add(45, "day"),
              })}
              tooltip={
                isNotCurrentlyPregnant
                  ? "The Medication start date can be selected no more than 35 weeks prior to the submission date and no more than 45 days in the future of the submission date"
                  : "Pregnancy Confirmation Date must be within the last 6 months from the Application Date"
              }
              onChange={(newValue) => {
                const sixMonthsAgo = dayjs()
                  .subtract(6, "months")
                  ?.subtract(1, "day");
                const minDate = dayjs()
                  .subtract(35, "weeks")
                  ?.subtract(1, "day");
                const maxDate = dayjs().add(45, "day");
                if (isCurrentlyPregnant) {
                  setCOP_MST_DateModal(dayjs(newValue).isBefore(sixMonthsAgo));
                } else {
                  setCOP_MST_DateModal(
                    dayjs(newValue).isBefore(minDate) ||
                      dayjs(newValue).isAfter(maxDate)
                  );
                }
                setValue(
                  isNotCurrentlyPregnant
                    ? "medication_start_date"
                    : "confirmation_of_pregnancy_date",
                  isNotCurrentlyPregnant &&
                    (dayjs(newValue).isBefore(minDate) ||
                      dayjs(newValue).isAfter(maxDate))
                    ? ""
                    : newValue
                );
                deleteFormikError(
                  isNotCurrentlyPregnant
                    ? "medication_start_date"
                    : "confirmation_of_pregnancy_date"
                );
              }}
              {...((watch("confirmation_of_pregnancy_date") ||
                watch("medication_start_date")) && {
                value: dayjs(
                  watch(
                    isNotCurrentlyPregnant
                      ? "medication_start_date"
                      : "confirmation_of_pregnancy_date"
                  )
                ),
              })}
              isError={
                isNotCurrentlyPregnant
                  ? formikErrors.medication_start_date
                  : formikErrors.confirmation_of_pregnancy_date
              }
              errorMsg={
                isNotCurrentlyPregnant
                  ? formikErrors.medication_start_date
                  : formikErrors.confirmation_of_pregnancy_date
              }
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={3}>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <Dropdown
              label="Term Length"
              id="term_length"
              disabled
              options={uniqueTermLengths}
              value={watch("term_length")}
              register={register}
              registerChange={(e) => {
                handleInputChange(e);
                setValue("reproductive_organ_loss_rider_option", null);
                setValue("disability_rider_option", null);
              }}
              isError={formikErrors.term_length}
              errorMsg={formikErrors.term_length}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <Dropdown
              label="Limit of Indemnity for Surrogate’s Beneficiary"
              id="indemnity_surrogates_beneficiary"
              options={sad_indemnity_surrogate_options_list}
              disabled={buttonLoading || disableLimit_Riders}
              value={watch("indemnity_surrogates_beneficiary")}
              register={register}
              registerChange={(e) => {
                handleInputChange(e);
                setIndemnityLimitModal(
                  (e.target.value + getValues("indemnity_intended_parents") ??
                    0) > 750000
                );
              }}
              helperText="Note: Maximum Limit of Indemnity is $750K"
              tooltip="Combined maximum benefit amount for Surrogate and Intended Parent is $750,000. Combined maximum coverage of over $750,000 requires medical underwriting"
              isError={formikErrors.indemnity_surrogates_beneficiary}
              errorMsg={formikErrors.indemnity_surrogates_beneficiary}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <Dropdown
              label="Limit of Indemnity for Intended Parents"
              id="indemnity_intended_parents"
              options={sad_indemnity_ip_options_list}
              disabled={buttonLoading || disableLimit_Riders}
              value={watch("indemnity_intended_parents")}
              register={register}
              registerChange={(e) => {
                handleInputChange(e);
                setIndemnityLimitModal(
                  (e.target.value +
                    getValues("indemnity_surrogates_beneficiary") ?? 0) > 750000
                );
              }}
              helperText="Note: Maximum Limit of Indemnity is $250K"
              tooltip="Combined maximum benefit amount for Surrogate and Intended Parent is $750,000. Combined maximum coverage of over $750,000 requires medical underwriting"
              isError={formikErrors.indemnity_intended_parents}
              errorMsg={formikErrors.indemnity_intended_parents}
            />
          </Grid>
          <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
            <TextInput
              inputKey="financial_email"
              inputLabel="Additional Email Address(s) that need a copy of the Invoice and Application"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              tooltip={financialEmailTooltip}
              // helperText="Note: Send a copy of invoice to this email address who needs to be contacted for any financial matters."
              isError={formikErrors.financial_email}
              errorMsg={formikErrors.financial_email}
              required={false}
            />
          </Grid>
        </Grid>
      </Accordion>

      {/* Optional Rider Coverages */}
      <Accordion
        header="Optional Rider Coverages"
        component="h2"
        name="rider-coverages"
      >
        <FormHelperText
          sx={{
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.9rem",
            marginBottom: "0.5rem",
          }}
        >
          Note: Please choose the Term Length before selecting any Optional
          Riders.
        </FormHelperText>
        <Grid container columnSpacing={3} rowSpacing={2}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Dropdown
              label="Reproductive Organ Loss Rider"
              id="reproductive_organ_loss_rider"
              options={yes_no_list}
              disabled={buttonLoading || disableLimit_Riders}
              value={watch("reproductive_organ_loss_rider")}
              register={register}
              tooltip="Coverage will pay the benefit amount specified in the event the Surrogate were to lose a reproductive organ(s) due to the surrogate pregnancy, as defined by the policy."
              registerChange={(e) => {
                handleInputChange(e);
                !e.target.value &&
                  setValue("reproductive_organ_loss_rider_option", null);
              }}
              isError={formikErrors.reproductive_organ_loss_rider}
              errorMsg={formikErrors.reproductive_organ_loss_rider}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Dropdown
              label="If Yes Select Coverage Limit"
              id="reproductive_organ_loss_rider_option"
              options={reproductiveRiders}
              register={register}
              value={watch("reproductive_organ_loss_rider_option")}
              registerChange={handleInputChange}
              disabled={
                buttonLoading || !watch("reproductive_organ_loss_rider")
              }
              required={!!watch("reproductive_organ_loss_rider")}
              isError={formikErrors.reproductive_organ_loss_rider_option}
              errorMsg={formikErrors.reproductive_organ_loss_rider_option}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Dropdown
              label="Total Disability Rider"
              id="disability_rider"
              options={yes_no_list}
              disabled={buttonLoading || disableLimit_Riders}
              register={register}
              value={watch("disability_rider")}
              registerChange={(e) => {
                handleInputChange(e);
                !e.target.value && setValue("disability_rider_option", null);
              }}
              tooltip="Coverage will pay the benefit amount specified if the Surrogate suffers Total Disablement, as defined by the policy."
              isError={formikErrors.disability_rider}
              errorMsg={formikErrors.disability_rider}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Dropdown
              label="If Yes Select Coverage Limit"
              id="disability_rider_option"
              options={disabilityRiders}
              value={watch("disability_rider_option")}
              register={register}
              registerChange={handleInputChange}
              disabled={buttonLoading || !watch("disability_rider")}
              required={!!watch("disability_rider")}
              isError={formikErrors.disability_rider_option}
              errorMsg={formikErrors.disability_rider_option}
            />
          </Grid>
        </Grid>
      </Accordion>

      {/* Intended Parent/s Details */}
      <Accordion
        header="Intended Parent/s Details"
        component="h2"
        name="parent-details"
      >
        <Grid container columnSpacing={3} marginBottom={2} rowSpacing={2}>
          <AddressInput
            register={register}
            disabled={buttonLoading}
            disableCountry={!!summaryData?.invoice_id}
            disableZip={!!summaryData?.invoice_id}
            disableState={!!summaryData?.invoice_id}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            addressKey="intended_parent_address_line_1"
            countryKey="intended_parent_country_code"
            zipcodeKey="intended_parent_zipcode"
            stateKey="intended_parent_state_code"
            cityKey="intended_parent_city"
            onAddressChange={() =>
              deleteFormikError("intended_parent_address_line_1")
            }
            onCountryChange={() => {
              // setIpCountryStateChanged(true);
              deleteFormikError("intended_parent_country_code");
            }}
            onZipcodeChange={() => {
              // if (watch("intended_parent_country_code") === "US") {
              //   setIpCountryStateChanged(true);
              // }
              deleteFormikError("intended_parent_zipcode");
              deleteFormikError("intended_parent_state_code");
              deleteFormikError("intended_parent_city");
            }}
            onStateChange={() => {
              // if (watch("intended_parent_country_code") === "US") {
              //   setIpCountryStateChanged(true);
              // }
              deleteFormikError("intended_parent_state_code");
            }}
            onCityChange={() => deleteFormikError("intended_parent_city")}
            addressError={formikErrors.intended_parent_address_line_1}
            countryError={formikErrors.intended_parent_country_code}
            zipcodeError={formikErrors.intended_parent_zipcode}
            stateError={formikErrors.intended_parent_state_code}
            cityError={formikErrors.intended_parent_city}
            setLoading={setLoading}
            setError={setError}
            clearErrors={clearErrors}
          />
        </Grid>
        <Typography
          id="intended-Parent-1"
          sx={{ fontSize: "0.9rem", fontWeight: "700", marginTop: "2rem" }}
        >
          Intended Parent - 1
        </Typography>
        <Grid
          container
          columnSpacing={3}
          marginBottom={2}
          rowSpacing={2}
          role="group"
          aria-labelledby="intended-Parent-1"
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="intended_parent1_first_name"
              inputLabel="First Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.intended_parent1_first_name}
              errorMsg={formikErrors.intended_parent1_first_name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="intended_parent1_last_name"
              inputLabel="Last Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.intended_parent1_last_name}
              errorMsg={formikErrors.intended_parent1_last_name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <PhoneInput
              inputKey="intended_parent1_phone"
              inputLabel="Contact No."
              autoComplete="off"
              disabled={buttonLoading}
              {...(testNonLetter(watch("intended_parent1_phone")) && {
                value: watch("intended_parent1_phone"),
              })}
              register={register}
              registerChange={handlePhoneChange}
              isError={formikErrors.intended_parent1_phone}
              errorMsg={formikErrors.intended_parent1_phone}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="intended_parent1_email"
              inputLabel="Email"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.intended_parent1_email}
              errorMsg={formikErrors.intended_parent1_email}
            />
          </Grid>
        </Grid>
        <Typography
          id="intended-Parent-2"
          sx={{ fontSize: "0.9rem", fontWeight: "700", marginTop: "2rem" }}
        >
          Intended Parent - 2
        </Typography>
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          role="group"
          aria-labelledby="intended-Parent-2"
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="intended_parent2_first_name"
              inputLabel="First Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              required={false}
              isError={formikErrors.intended_parent2_first_name}
              errorMsg={formikErrors.intended_parent2_first_name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="intended_parent2_last_name"
              inputLabel="Last Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              required={false}
              isError={formikErrors.intended_parent2_last_name}
              errorMsg={formikErrors.intended_parent2_last_name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <PhoneInput
              inputKey="intended_parent2_phone"
              inputLabel="Contact No."
              autoComplete="off"
              disabled={buttonLoading}
              {...(testNonLetter(watch("intended_parent2_phone")) && {
                value: watch("intended_parent2_phone"),
              })}
              register={register}
              registerChange={handlePhoneChange}
              required={false}
              isError={formikErrors.intended_parent2_phone}
              errorMsg={formikErrors.intended_parent2_phone}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="intended_parent2_email"
              inputLabel="Email"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              required={false}
              isError={formikErrors.intended_parent2_email}
              errorMsg={formikErrors.intended_parent2_email}
            />
          </Grid>
        </Grid>
      </Accordion>

      {/* Surrogate Information */}
      <Accordion
        header="Surrogate Information"
        component="h2"
        name="surrogate-info"
      >
        <Grid container columnSpacing={3} marginBottom={2} rowSpacing={2}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="surrogate_first_name"
              inputLabel="First Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.surrogate_first_name}
              errorMsg={formikErrors.surrogate_first_name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="surrogate_last_name"
              inputLabel="Last Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.surrogate_last_name}
              errorMsg={formikErrors.surrogate_last_name}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <PhoneInput
              inputKey="surrogate_phone"
              inputLabel="Contact No."
              autoComplete="off"
              disabled={buttonLoading}
              {...(testNonLetter(watch("surrogate_phone")) && {
                value: watch("surrogate_phone"),
              })}
              register={register}
              registerChange={handlePhoneChange}
              isError={formikErrors.surrogate_phone}
              errorMsg={formikErrors.surrogate_phone}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextInput
              inputKey="surrogate_email"
              inputLabel="Email"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.surrogate_email}
              errorMsg={formikErrors.surrogate_email}
            />
          </Grid>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <DatePicker
              id="surrogate_dob"
              label="DOB"
              disabled={buttonLoading}
              maxDate={dayjs().subtract(21, "years")}
              minDate={dayjs().subtract(45, "years")}
              onChange={(newValue) => {
                const below21 = dayjs().subtract(21, "years");
                const ageLessThan21 = dayjs(newValue).isAfter(below21);
                const above45 = dayjs()
                  .subtract(45, "years")
                  ?.subtract?.(1, "day");
                const ageGreaterThan45 = dayjs(newValue).isBefore(above45);
                const above41 = dayjs().subtract(41, "years");
                const ageGreaterThan41 = dayjs(newValue).isBefore(above41);
                const age = dayjs().diff(newValue, "years");
                setSurrogateDobModal({
                  show: ageLessThan21 || ageGreaterThan45 || ageGreaterThan41,
                  text: `${
                    ageLessThan21 || ageGreaterThan45
                      ? "The age of this person is either below the minimum age requirement which is 21 years or maximum age allowed by this automated system. Due to the Surrogate Age, we cannot approve coverage at this time."
                      : "Please be aware! Due to the Surrogate Age, this application will need to be reviewed before approval."
                  }
                  If you have any questions, please contact your broker.`,
                });
                setValue(
                  "surrogate_dob",
                  !!ageLessThan21 || !!ageGreaterThan45 ? "" : newValue
                );
                deleteFormikError("surrogate_dob");
              }}
              {...(watch("surrogate_dob") && {
                value: dayjs(watch("surrogate_dob")),
              })}
              tooltip="Surrogate's minimum age is 21 years and maximum age is 45 years."
              isError={formikErrors.surrogate_dob}
              errorMsg={formikErrors.surrogate_dob}
              defaultCalendarMonth={dayjs().subtract(21, "years")}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} rowSpacing={2}>
          <AddressInput
            register={register}
            disabled={buttonLoading}
            disableCountry={!!summaryData?.invoice_id}
            disableZip={!!summaryData?.invoice_id}
            disableState={!!summaryData?.invoice_id}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            addressKey="surrogate_address_line_1"
            countryKey="surrogate_country_code"
            zipcodeKey="surrogate_zipcode"
            stateKey="surrogate_state_code"
            cityKey="surrogate_city"
            onAddressChange={() =>
              deleteFormikError("surrogate_address_line_1")
            }
            onCountryChange={(e) => {
              // if (e !== "US") {
              //   setIpCountryStateChanged(true);
              // }
              deleteFormikError("surrogate_country_code");
            }}
            onZipcodeChange={() => {
              deleteFormikError("surrogate_zipcode");
              deleteFormikError("surrogate_state_code");
              deleteFormikError("surrogate_city");
            }}
            onStateChange={() => deleteFormikError("surrogate_state_code")}
            onCityChange={() => deleteFormikError("surrogate_city")}
            addressError={formikErrors.surrogate_address_line_1}
            countryError={formikErrors.surrogate_country_code}
            zipcodeError={formikErrors.surrogate_zipcode}
            stateError={formikErrors.surrogate_state_code}
            cityError={formikErrors.surrogate_city}
            setLoading={setLoading}
            setError={setError}
            clearErrors={clearErrors}
          />
        </Grid>
      </Accordion>

      {/* Surrogate’s Designated Beneficiary/Beneficiaries */}
      <Accordion
        header="Surrogate’s Designated Beneficiary/Beneficiaries"
        component="h2"
        name="surrogate-beneficiary"
      >
        <Box
          sx={{ display: "flex", alignItems: "center", marginBottom: "1.5rem" }}
        >
          <FormHelperText
            sx={{
              fontStyle: "italic",
              fontWeight: 600,
              fontSize: "0.9rem",
            }}
          >
            Note: You can add upto 6 beneficiaries
          </FormHelperText>
          <Tooltip
            placement="right"
            tooltip="Please note in the event of a qualifying death of the Surrogate, the limits of indemnity shall be equally divided between the Surrogate’s designated beneficiaries."
          />
        </Box>
        {fields?.map((beneficiary, index) => (
          <div
            key={index}
            role="group"
            aria-labelledby={"beneficiary-" + (index + 1)}
            style={{ marginBottom: "20px" }}
          >
            <Grid
              container
              columnSpacing={3}
              rowSpacing={2}
              alignItems="center"
            >
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <Typography
                  id={"beneficiary-" + (index + 1)}
                  sx={{ fontSize: "0.9rem", fontWeight: "700" }}
                >
                  Beneficiary-{index + 1}
                </Typography>
              </Grid>
              {index === fields.length - 1 && index > 0 && (
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} textAlign="right">
                  <IconButton
                    aria-label={"Delete Beneficiary-" + (index + 1)}
                    disabled={buttonLoading}
                    onClick={() =>
                      setBeneficiaryDeleteModal({
                        show: true,
                        beneficiary,
                        index,
                      })
                    }
                    sx={{ color: theme.black }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid
              sx={{ marginTop: -1.2 }}
              container
              rowSpacing={2}
              columnSpacing={3}
            >
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey={`beneficiaries.${index}.first_name`}
                  inputLabel="First Name"
                  autoComplete="off"
                  disabled={buttonLoading}
                  register={register}
                  registerChange={(e) => handleBeneficiaryChange(e, index)}
                  isError={beneficiaryErrors[index]?.first_name}
                  errorMsg={beneficiaryErrors[index]?.first_name}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey={`beneficiaries.${index}.last_name`}
                  inputLabel="Last Name"
                  autoComplete="off"
                  disabled={buttonLoading}
                  register={register}
                  registerChange={(e) => handleBeneficiaryChange(e, index)}
                  isError={beneficiaryErrors[index]?.last_name}
                  errorMsg={beneficiaryErrors[index]?.last_name}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey={`beneficiaries.${index}.relationship`}
                  inputLabel="Relationship"
                  autoComplete="off"
                  disabled={buttonLoading}
                  register={register}
                  registerChange={(e) => handleBeneficiaryChange(e, index)}
                  isError={beneficiaryErrors[index]?.relationship}
                  errorMsg={beneficiaryErrors[index]?.relationship}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey={`beneficiaries.${index}.email`}
                  inputLabel="Email"
                  autoComplete="off"
                  disabled={buttonLoading}
                  register={register}
                  registerChange={(e) => handleBeneficiaryChange(e, index)}
                  isError={beneficiaryErrors[index]?.email}
                  errorMsg={beneficiaryErrors[index]?.email}
                />
              </Grid>
            </Grid>
          </div>
        ))}
        {fields.length < 6 && (
          <Button
            variant="text"
            sx={{
              color: theme.mistBlue,
              letterSpacing: 0,
              fontSize: "0.9rem",
            }}
            disabled={buttonLoading}
            onClick={addBeneficiary}
          >
            <AddCircleOutlineOutlinedIcon sx={{ marginRight: "0.3rem" }} />
            Add More
          </Button>
        )}
      </Accordion>

      {/* Agency Details */}
      <Accordion header="Agency Details" component="h2" name="agency-details">
        <FormControl sx={{ marginBottom: "1rem" }}>
          <FormLabel
            sx={{
              fontSize: "0.9rem",
              color: "black",
              "&.Mui-focused": {
                color: "black",
              },
            }}
          >
            Are you working with an Agency?
            <span className="text-danger" style={{ marginLeft: "0.2rem" }}>
              *&nbsp;
            </span>
          </FormLabel>
          <RadioGroup row={true} value={watch("working_with_agency")}>
            {yes_no_list?.map((item) => (
              <FormControlLabel
                key={item.value}
                sx={{ marginRight: "4rem", fontSize: "2" }}
                componentsProps={{ typography: { variant: "body2" } }}
                disabled={buttonLoading || !!summaryData?.invoice_id}
                control={<Radio size="small" />}
                value={item.value}
                label={item.label}
                checked={watch("working_with_agency") == item.value}
                onChange={() => {
                  setValue("working_with_agency", item.value);
                  deleteFormikError("working_with_agency");
                }}
              />
            ))}
          </RadioGroup>
          {formikErrors.working_with_agency && (
            <FormHelperText
              error
              sx={{
                whiteSpace: "nowrap",
                marginLeft: 0,
                fontSize: "0.77rem",
                letterSpacing: 0,
                position: "absolute",
                marginTop: 7,
              }}
            >
              {formikErrors.working_with_agency}
            </FormHelperText>
          )}
        </FormControl>
        {!!formikErrors.agency_id && (
          <FormHelperText error sx={{ mb: "0.5rem" }} id={"error-agency_id"}>
            {formikErrors.agency_id}
          </FormHelperText>
        )}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          sx={{
            display: !watch("working_with_agency") && "none",
          }}
        >
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Dropdown
              label="Country"
              id="agency_country_code"
              disabled={buttonLoading || !!summaryData?.invoice_id}
              options={countries_list}
              register={register}
              value={watch("agency_country_code")}
              registerChange={(e) => {
                handleInputChange(e);
                setValue("agency_state_code", null);
                setValue("agency_id", null);
                setValue("agency_name", null);
                setValue("agency_contact_person_name", null);
              }}
              isError={formikErrors.agency_country_code}
              errorMsg={formikErrors.agency_country_code}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Dropdown
              label="State"
              id="agency_state_code"
              disabled={
                buttonLoading ||
                !watch("agency_country_code") ||
                !!summaryData?.invoice_id
              }
              options={stateList}
              value={watch("agency_state_code")}
              register={register}
              registerChange={(e) => {
                handleInputChange(e);
                setValue("agency_id", null);
                setValue("agency_name", null);
                setValue("agency_contact_person_name", null);
              }}
              isError={formikErrors.agency_state_code}
              errorMsg={formikErrors.agency_state_code}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <AutoComplete
              {...register("agency_name")}
              label="Agency Name"
              id="agency_name"
              placeholder="Start typing agency name..."
              disabled={
                buttonLoading ||
                !watch("agency_state_code") ||
                !!summaryData?.invoice_id
              }
              options={agencyList ?? []}
              optionLabel={(option) =>
                option?.name || getValues("agency_name") || ""
              }
              setShowAddNewAgency={setShowAddNewAgency}
              buttonText="Add New Agency"
              loading={loading}
              onInputChange={(_, value) => setValue(`agency_name`, value)}
              value={getValues("agency_name")}
              onChange={(data) => {
                if (data?.is_active === -1) {
                  setBlackListedAgency(true);
                  setValue("agency_id", null);
                  setValue("agency_name", null);
                  setValue("agency_contact_person_name", null);
                } else {
                  setValue("agency_id", data?.id);
                  setValue("agency_name", data?.name);
                  deleteFormikError("agency_name");
                  deleteFormikError("agency_id");
                  setValue(
                    "agency_contact_person_name",
                    data?.contact_person_name
                  );
                }
              }}
              errorMsg={formikErrors.agency_name}
              isError={formikErrors.agency_name}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="agency_contact_person_name"
              inputLabel="Contact Person Name"
              autoComplete="off"
              disabled={buttonLoading}
              required={false}
              register={register}
              registerChange={handleInputChange}
              isError={formikErrors.agency_contact_person_name}
              errorMsg={formikErrors.agency_contact_person_name}
            />
          </Grid>
        </Grid>
      </Accordion>

      {/* Action Buttons */}
      <SadActionButtons
        onCancel={setDataInitially}
        buttonLoading={buttonLoading == "continue"}
      />

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Application Updated Successfully!"
      />

      {/* Warning modal for confirmation pregnancy date and medication start date */}
      <CommonModal
        open={COP_MST_DateModal}
        type="warning"
        onConfirm={() => {
          setCOP_MST_DateModal(false);
          document
            .getElementById(
              isNotCurrentlyPregnant
                ? "medication_start_date"
                : "confirmation_of_pregnancy_date"
            )
            ?.focus();
        }}
        title="Please Note"
        subTitle={
          isNotCurrentlyPregnant
            ? "Medication Start Date cannot be more than 45 days in the future and more than 35 weeks prior from the date of application. Please reapply when you are within 45 days of medication start date commencing."
            : "Due to Late-Stage Pregnancy, this application will need to be reviewed before approval. If you have any questions, please contact your broker."
        }
      />

      {/* Warning modal if IP's non US country or restricted state selected */}
      {/* <CommonModal
        open={countryStateModal.show}
        type="warning"
        onConfirm={() => {
          setCountryStateModal({ show: false, text: "" });
          setIpCountryStateChanged(false);
        }}
        title="Please Note"
        subTitle={`Due to ${countryStateModal.text}, this application will need to be reviewed before approval. If you have any questions, please contact your broker.`}
      /> */}

      {/* Warning modal for exceeding the range of limits of idemnities */}
      <CommonModal
        open={indemnityLimitModal}
        type="warning"
        onCancel={() => {
          setIndemnityLimitModal(false);
          setConfirmIndemnityLimit(false);
        }}
        onConfirm={() => {
          setIndemnityLimitModal(false);
          setConfirmIndemnityLimit(true);
        }}
        title="Please Note"
        subTitle="Coverage request is over $750,000 and will require medical underwriting for approval. Please continue with the rest of the application. You will be contacted by your agent or a representative at New Life Agency for the next steps once your application is submitted. If you would like to continue with your request for combined limits of indemnity over $750,000, please click OKAY button below to acknowledge this condition. If you would not like to continue then, please click CANCEL to close this message and adjust your selected limits of indemnity to a combined limit of $750,000 or less."
      />

      {/* Warning modal to validate surrogate's dob */}
      <CommonModal
        open={surrogateDobModal.show}
        type="warning"
        onConfirm={() => {
          setSurrogateDobModal({ show: false, text: "" });
          document.getElementById("surrogate_dob")?.focus();
        }}
        title="Please Note"
        subTitle={surrogateDobModal.text}
      />

      {/* Modal to confirm delete beneficiary */}
      <CommonModal
        open={beneficiaryDeleteModal.show}
        type="delete"
        onCancel={() =>
          setBeneficiaryDeleteModal({ show: false, beneficiary: {}, index: -1 })
        }
        confirmButtonLabel="Delete"
        onConfirm={removeBeneficiary}
        confirmButtonLoading={buttonLoading == "delete"}
        title="Are You Sure?"
        subTitle="Do you want to delete this beneficiary?"
      />

      {/* Warning modal if BLACKlISTED agency is selected */}
      <CommonModal
        open={isBlackListedAgency}
        type="warning"
        onConfirm={() => setBlackListedAgency(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this agency.<br/>If you have any questions, please contact your broker.`}
      />

      {showAddNewAgency && (
        <AddNewAgency
          open={showAddNewAgency}
          onClose={() => setShowAddNewAgency(false)}
          setLoading={setLoading}
          newAgencyData={({
            id,
            country_code,
            state_code,
            name,
            contact_person_name,
            address_line_1,
            zipcode,
          }) => {
            setNewAgencyAdded(!isNewAgencyAdded);
            setValue("agency_country_code", country_code);
            setValue("agency_state_code", state_code);
            setValue("agency_id", data?.id);
            setValue("agency_name", name);
            setValue("agency_contact_person_name", contact_person_name);
            setValue("agency_address_line_1", address_line_1);
            setValue("agency_zipcode", zipcode);
            setValue("agency_city", state_code);
            deleteFormikError("agency_name");
            deleteFormikError("agency_id");
            deleteFormikError("agency_contact_person_name");
          }}
        />
      )}
    </form>
  );
};

export { Edit_ApplicationForm };
