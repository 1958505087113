/**
 * Clinic APIs
 */

import httpClient from "../utils/HttpUtils";

export const ClinicApis = {
  // we consider limit false for dropdowns (to show all data)
  // and true for grid (to show 20 data)
  getClinicsList: async (payload) => {
    const {
      country_code = "",
      state_code = "",
      name = null,
      limit = false,
      sortOrder,
      sortKey,
      page,
      only_deleted,
      is_active,
    } = payload ?? {};

    let reqUrl = `/clinics?limit=${
      limit ? 20 : -1
    }&filters[country_code]=${country_code}`;
    if (sortOrder || sortKey) {
      reqUrl += `&sort=${sortOrder ? "-" : ""}${sortKey}`;
    }
    if (page) {
      reqUrl += `&page=${page}`;
    }
    if (state_code) {
      reqUrl += `&filters[state_code]=${state_code}`;
    }
    if (typeof name == "string") {
      reqUrl += `&filters[name]=${name}`;
    }
    if (only_deleted) {
      reqUrl += `&filters[only_deleted]=${only_deleted}`;
    }
    if (is_active) {
      reqUrl += `&filters[is_active]=${is_active}`;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return limit ? resp : resp?.data?.results;
    } catch (error) {
      console.log("Error getClinicsList", error);
    }
  },

  createClinic: async (payload) => {
    try {
      const resp = await httpClient.post(`clinics`, payload);
      return resp;
    } catch (error) {
      console.log("Error createClinic", error);
    }
  },

  getClinicById: async (payload) => {
    const { clinic_id } = payload;
    try {
      const resp = await httpClient.get(`clinics/${clinic_id}`);
      return resp;
    } catch (error) {
      console.log("Error getClinicById:", error);
    }
  },

  updateClinic: async (payload) => {
    const { clinic_id, params } = payload;
    try {
      const resp = await httpClient.put(`clinics/${clinic_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updateClinic", error);
    }
  },

  deleteClinic: async ({ clinic_id, restore }) => {
    try {
      let reqUrl = `/clinics/${clinic_id}`;
      if (restore) {
        reqUrl += `?restore=1`;
      }
      const resp = await httpClient.delete(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error deleteClinic", error);
    }
  },
};
