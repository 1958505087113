/*
 * Zoho landing page
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ZohoApis } from "../../api";
import { AllRoutes } from "../../routes";
import { CommonModal } from "../../components";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";

const ZohoLandingPage = () => {
  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [popupData, setPopupData] = useState({});

  useEffect(() => {
    const handleSyncToken = async () => {
      if (location.search && status) {
        if (status === "error") {
          setPopupData({
            title: "Oh, something went wrong",
            type: "warning",
            subTitle:
              "Failed to connect Zoho due to a technical error. It happens, just try again now, or after a couple of minutes.",
          });
        } else if (status === "success") {
          setPopupData({
            type: "success",
            title: "Zoho Connected Successfully!",
          });
        }
      }
    };

    handleSyncToken();
  }, [location.search]);

  const connectZoho = async () => {
    setButtonLoading(true);
    try {
      const resp = await ZohoApis.connect();
      !!resp && setButtonLoading(false);
    } catch (error) {
      console.log("connectZoho", error);
    }
  };

  const onPressOkay = () => {
    if (status == "error") {
      connectZoho();
    } else {
      navigate(AllRoutes.Profile.route);
      setShowModal(false);
    }
  };

  if (!location.search || !status) return null;

  return (
    <AdminPageLayout hideDrawer={true}>
      {!!popupData?.type && (
        <CommonModal
          open={showModal}
          type={popupData.type}
          onConfirm={onPressOkay}
          {...(status === "error" && {
            onCancel: () => {
              navigate(AllRoutes.Profile.route);
              setShowModal(false);
            },
          })}
          confirmButtonLoading={buttonLoading}
          title={popupData?.title || ""}
          subTitle={popupData.subTitle || ""}
        />
      )}
    </AdminPageLayout>
  );
};

export { ZohoLandingPage };
