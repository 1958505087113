/*
 * IVF application Constant Data
 */

export const IvfFormInitialValues = {
  idemnity_limit: "",
  reproductive_organ_loss_rider_option: "",
  donor_name_id: "",
  recipient_name_id: "",
  donor_has_cycled_before: "",
  donor_has_cycled_before_complications: "",
  recipient_has_cycled_before: "",
  donor_cycle_complications_description: "",
  recipient_has_cycled_before_complications: "",
  recipient_cycle_complications_description: "",
  donor_num_previous_cycles: null,
  recipient_num_previous_cycles: null,
  contractually_liable_party: "",
  contractually_liable_party_first_name: "",
  contractually_liable_party_address_line_1: "",
  contractually_liable_party_city: "",
  contractually_liable_party_country_code: "US",
  contractually_liable_party_zipcode: "",
  contractually_liable_party_state_code: "",
  financial_email: "",
  optional_rider_intended_for: "",
  coverage_type: "",
  term_length: "",
  reproductive_organ_loss_rider: "",
  recipient_dob: "",
  recipient_medication_start_date: "",
  donor_dob: "",
  donor_medication_start_date: "",
  working_with_agency: "",
  clinic_name: "",
  clinic_doctor_name: "",
  clinic_country_code: "US",
  clinic_state_code: "",
  clinic_address_line_1: "",
  clinic_zipcode: "",
  clinic_city: "",
  agency_country_code: "US",
  agency_state_code: "",
  agency_contact_person_name: "",
  agency_name: "",
  agency_zipcode: "",
  agency_city: "",
  agency_address_line_1: "",
};

export const getLossRiderOption = (
  ivf_reproductive_coverage_options_list,
  term_length,
  reproductive_organ_loss_rider_option
) => {
  return ivf_reproductive_coverage_options_list?.[term_length]?.filter?.(
    (item) => item.value == reproductive_organ_loss_rider_option
  )?.[0]?.label;
};
