/*
 * Common slice
 */

import { createSlice } from "@reduxjs/toolkit";

const commonInitialState = {
  lookups: {},
  meData: null,
};

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState: commonInitialState,
  reducers: {
    setLookups(state, action) {
      state.lookups = action.payload;
    },
    setMeData(state, action) {
      state.meData = action.payload;
    },
  },
});

export const { setLookups, setMeData } = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
