/*
 * Applications Utils
 */

import { ALLOWED_ACTIONS } from "../constants";
import { PolicyDocTypes } from "../pages/sad/constants";

export const ApplicationUtils = {
  nonEditableApplication: (application = {}) => {
    return !application?.allowed_actions?.some(
      (action) => action?.value === ALLOWED_ACTIONS.EDIT_APPLICATION
    );
  },

  isInvoiceVisible: (application = {}) => {
    return application?.allowed_policy_docs?.some(
      (action) => action?.document_id === PolicyDocTypes.invoice
    );
  },
};
