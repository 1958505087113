/*
 * Admin -> Wizard -> State table (Only US) -> Table Data
 */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { MoreVert } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import { AllRoutes } from "../../../routes";
import { CountriesApis } from "../../../api";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  TextInput,
} from "../../../components";
import { useDebounce } from "../../../hooks";
import { theme } from "../../../styles/theme";
import { DEBOUNCE_DELAY, strings } from "../../../constants";

const RenderCard = ({
  country_id,
  state,
  setClickable,
  setActionAnchorEl,
  setActionLoading,
  setUpdateModal,
  setDataList,
  navigate,
}) => {
  const {
    state: stateName,
    state_code,
    country_code,
    broker_surplus_line_no,
    newlife_surplus_line_no,
    fee_list,
    should_round_fee,
  } = state ?? {};

  const handleFeeRound = async (should_round_fee) => {
    setActionLoading(true);
    const resp = await CountriesApis.updateStateofCountry({
      country_id,
      state_id: state?.id,
      params: { should_round_fee },
    });
    setActionLoading(false);
    if (resp?.success) {
      setDataList((p) =>
        p?.map((i) =>
          i?.id === resp?.data?.id ? { ...i, should_round_fee } : i
        )
      );
      setUpdateModal(
        `${
          should_round_fee ? "Enabled" : "Disabled"
        } Fee Rounding Successfully!`
      );
    }
  };

  const openActionDropDown = (e, rowData) => {
    setClickable([
      {
        text: "Edit State",
        route: () =>
          navigate(`${AllRoutes.EditState.route}/${country_id}/${rowData?.id}`),
      },
      {
        text: strings.manageFee,
        route: () =>
          navigate(`${AllRoutes.StateTax.route}/${country_code}/${state_code}`),
      },
      {
        text: !!should_round_fee
          ? strings.disableFeeRounding
          : strings.enableFeeRounding,
        route: () => handleFeeRound(Number(!should_round_fee)),
      },
    ]);
    setActionAnchorEl(e.currentTarget);
  };

  return (
    <Card
      sx={{
        display: "flex",
        p: "1rem",
        backgroundColor: "#cfc0b51a",
        boxShadow: "0 4px 10px 0 rgba(71, 62, 103, 0.1)",
        border: "1px solid #d4b89f",
        overflow: "hidden",
        clear: "both",
        borderRadius: "5px",
        height: "100%",
        position: "relative",
      }}
    >
      <CardContent sx={{ flex: 2, paddingY: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0.2rem",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h2"
            className="fw-bold"
            sx={{ paddingRight: "0.9rem" }}
          >
            {stateName} | {state_code}
          </Typography>
          {!!should_round_fee && (
            <Typography
              title="Enabled Fee Rounding"
              sx={{
                fontSize: "1.2rem",
                color: "green",
                mr: "1.2rem",
                mt: -1.1,
                cursor: "default",
              }}
            >
              $
            </Typography>
          )}
        </Box>
        {(!!broker_surplus_line_no || newlife_surplus_line_no) && (
          <Box
            sx={{
              height: "0.1rem",
              background: theme.lightGrey,
              my: "0.5rem",
            }}
          />
        )}
        {!!broker_surplus_line_no && (
          <Typography
            variant="subtitle2"
            component="div"
            className="text-break"
            sx={{
              marginBottom: "0.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.85rem",
                verticalAlign: "middle",
                marginRight: "0.3rem",
                fontWeight: "bold",
              }}
            >
              Broker Surplus Line No. :
            </Typography>
            {broker_surplus_line_no}
          </Typography>
        )}
        {!!newlife_surplus_line_no && (
          <Typography
            variant="subtitle2"
            component="div"
            className="text-break"
            sx={{
              marginBottom: "0.2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.85rem",
                verticalAlign: "middle",
                marginRight: "0.3rem",
                fontWeight: "bold",
              }}
            >
              NewLife Surplus Line No. :
            </Typography>
            {newlife_surplus_line_no}
          </Typography>
        )}
        {!!fee_list?.length && (
          <>
            <Box
              sx={{
                height: "0.1rem",
                background: theme.lightGrey,
                my: "0.5rem",
              }}
            />
            {fee_list?.map?.((fee) => {
              const { label, fee_flat, fee_percent } = fee || {};
              return (
                <Typography
                  variant="subtitle2"
                  component="div"
                  className="text-break"
                  sx={{
                    marginBottom: "0.2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.85rem",
                      verticalAlign: "middle",
                      marginRight: "0.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    {label} :
                  </Typography>
                  {fee_flat > 0
                    ? `$${fee_flat}`
                    : fee_percent > 0
                    ? `${fee_percent}%`
                    : ""}
                </Typography>
              );
            })}
          </>
        )}
      </CardContent>

      <IconButton
        onClick={(e) => openActionDropDown(e, state)}
        aria-label={"More actions for " + state_code}
        sx={{ position: "absolute", top: "2px", right: "2px" }}
      >
        <MoreVert />
      </IconButton>
    </Card>
  );
};

const TableData = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { country_id } = useParams();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState("");
  const [dataLoading, setDataLoading] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState({});
  const { to, from, total, total_pages } = listData ?? {};

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await CountriesApis.getStatesOfCountry({ country_id });
      setHasRecords(resp?.data?.results?.length > 0);
      setLoading(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    if (obj?.hasOwnProperty("q")) {
      setSearchText(obj["q"]);
    }

    setPage(Number(obj["page"]));
    const { page, ...rest } = obj;
    setAdvancedSearch(rest);
  }, []);

  useEffect(() => {
    !!hasRecords && getStateList();
  }, [hasRecords, page, advancedSearch]);

  // Get SAD records
  const getStateList = async () => {
    setDataLoading(true);
    const params = { country_id, page, advancedSearch, limit: true };
    const resp = await CountriesApis.getStatesOfCountry(params);
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
  };

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const onEnterSearchText = (e) => {
    setSearchParams({ ["page"]: 1, ["q"]: e });
    setAdvancedSearch({ ["q"]: e });
    setPage(1);
  };

  const debounceOnSearch = useDebounce(async (value) => {
    onEnterSearchText(value);
  }, DEBOUNCE_DELAY);

  const closeActionDropDown = () => {
    setActionAnchorEl(null);
    setClickable([]);
  };

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <>
          <Box>
            <Box
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                my: "1rem",
              }}
            >
              <Box role="search">
                <TextInput
                  inputKey="search"
                  inputLabel="Search"
                  autoComplete="off"
                  margin="none"
                  required={false}
                  value={searchText}
                  onChange={(e) => {
                    debounceOnSearch(e.target.value);
                    setSearchText(e.target.value);
                  }}
                  sx={{ height: "2.5rem", borderRadius: "100px" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Box>
              <Box>
                <Box
                  mx={1}
                  sx={{ marginLeft: "2rem", display: "inline-block" }}
                >
                  {`${from || 0} - ${to || 0}`}
                  &nbsp;of {dataList?.length === 0 ? "0" : total}
                </Box>
                <Button
                  disabled={dataLoading || page === 1}
                  onClick={() =>
                    page > 1 && handlePageChange((prev) => prev - 1)
                  }
                  style={{ minWidth: 0 }}
                  aria-label="Previous"
                >
                  {"<"}
                </Button>
                <Button
                  disabled={dataLoading || page === total_pages}
                  onClick={() =>
                    page <= total_pages && handlePageChange((prev) => prev + 1)
                  }
                  style={{ minWidth: 0 }}
                  aria-label="Next"
                >
                  {">"}
                </Button>
              </Box>
            </Box>
            <Typography variant="span" className="hidden-visually" role="alert">
              {total} {total === 1 ? "result" : "results"} found.
            </Typography>
            <Grid container spacing={2} sx={{ mt: "10px" }}>
              {dataLoading ? (
                <CircularProgress
                  size={28}
                  sx={{ position: "absolute", left: { xs: "50%" } }}
                />
              ) : (
                dataList?.map((state) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    key={state.id}
                  >
                    <RenderCard
                      state={state}
                      setClickable={setClickable}
                      setActionAnchorEl={setActionAnchorEl}
                      setActionLoading={setActionLoading}
                      setUpdateModal={setUpdateModal}
                      setDataList={setDataList}
                      navigate={navigate}
                      country_id={country_id}
                    />
                  </Grid>
                ))
              )}
            </Grid>
            <RenderActionMenu
              anchorEl={actionAnchorEl}
              handleClose={closeActionDropDown}
              actions={clickable}
            />
          </Box>

          {actionLoading && <GlobalLoader loading={actionLoading} />}

          <CommonModal
            open={!!updateModal}
            type="success"
            onConfirm={() => setUpdateModal("")}
            title={updateModal}
          />
        </>
      ) : (
        <NoRecords />
      )}
    </>
  );
};

export { TableData };
