import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { CommonModal, DatePicker, Tooltip } from "../../../../components";
import { FilePickerDonor } from "../../ivf-user/components/FilePickerdonor";
import DropdownIVF from "../../ivf-user/ivf-form/common/Dropdown";
import TextInputIvf from "../../ivf-user/ivf-form/common/TextInput";
import { useEffect, useState } from "react";
import { AttachmentApis } from "../../../../api";
import dayjs from "dayjs";
import { natureOfComplicationsTooltip } from "../../../../constants";

const AdminDonor = ({
  register,
  disabled = false,
  setValue,
  errors,
  setError,
  lookups,
  setAgeModal,
  ageModal,
  cycleModal,
  setCycleModal,
  complicationCycleModal,
  setComplicationCycleModal,
  watch,
  application_id,
}) => {
  const [files, setFiles] = useState([]);
  const [isValidFile, setIsValidFile] = useState(true);
  const [fileToBeDeleted, setFileToBeDeleted] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDocUploading, setDocUploading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const [docRequired, setDocRequired] = useState("");

  const openPdfInNewTab = (file) => {
    !!file?.url && window.open(file?.url, "_blank");
  };

  const removePdf = async () => {
    setButtonLoading("delete");
    setIsValidFile(true);
    const resp = await AttachmentApis.deleteAttachment({
      application_id,
      document_id: fileToBeDeleted,
    });
    !resp?.success && setButtonLoading("");
    !!resp?.success && getAttachments();
    setShowDeleteModal(false);
    setFileToBeDeleted();
  };

  const getAttachments = async () => {
    const resp = await AttachmentApis.getAttachments({ application_id });
    if (resp) {
      setDocUploading(false);
      setButtonLoading("");
    }
    if (resp?.success && resp?.data?.length) {
      const formattedFiles = resp.data?.map((file) => ({
        name: file.original_name,
        type: "",
        size: 0,
        id: file.id,
        url: file.url,
        labelled_as: file.labelled_as,
      }));
      setFiles(formattedFiles);
    } else {
      setFiles([]);
    }
  };

  const handleFileUpload = async (newFile) => {
    setDocUploading(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", `DOC_donor_${application_id}`);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      application_id: application_id,
      formData,
      labelled_as: `DOC_donor_${application_id}`,
    });
    !resp?.success && setDocUploading(false);
    if (resp?.success) {
      setDocRequired("");
      getAttachments();
    }
  };

  const getDocs = () => {
    getAttachments();
  };

  useEffect(() => {
    getDocs();
  }, [application_id]);

  return (
    <>
      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <TextInputIvf
          register={register}
          disabled={disabled}
          setError={setError}
          isError={errors?.[`donor_name_id`]?.message}
          errorMsg={errors?.[`donor_name_id`]?.message}
          id={`donor_name_id`}
          name={`donor_name_id`}
          type="text"
          label={`Name/ID of Donor`}
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: "16px", width: "100%" }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label={`Medication Start Date of Donor`}
          id={`donor_medication_start_date`}
          name={`donor_medication_start_date`}
          disabled={disabled}
          onChange={(date) => {
            setError(`donor_medication_start_date`, null);
            setValue(`donor_medication_start_date`, date);
          }}
          isError={errors?.[`donor_medication_start_date`]?.message}
          errorMsg={errors?.[`donor_medication_start_date`]?.message}
          minDate={dayjs()}
          value={dayjs(watch(`donor_medication_start_date`))}
          maxDate={dayjs().add(60, "day")}
          tooltip={
            "The MSD can be selected no more than 60 days in the future of the submission date."
          }
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label={`DOB of Donor`}
          id={`donor_dob`}
          name={`donor_dob`}
          disabled={disabled}
          minDate={dayjs().subtract(45, "years")}
          maxDate={dayjs().subtract(20, "years")}
          value={dayjs(watch(`donor_dob`))}
          onChange={(date) => {
            setError(`donor_dob`, null);
            setValue(`donor_dob`, date);
            if (
              dayjs?.(date)?.isAfter?.(dayjs()?.subtract?.(20, "years")) ||
              dayjs?.(date)?.isBefore?.(
                dayjs?.()?.subtract?.(45, "years")?.subtract?.(1, "day")
              )
            ) {
              setAgeModal(true);
            }
          }}
          isError={errors?.[`donor_dob`]?.message}
          errorMsg={errors?.[`donor_dob`]?.message}
          disableFuture
          defaultCalendarMonth={dayjs().subtract(21, "years")}
          tooltip="Minimum age should be 20 years and maximum age should be 45 years."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DropdownIVF
          label={`Has Donor cycled before?`}
          id={`donor_has_cycled_before`}
          name={`donor_has_cycled_before`}
          disabled={disabled}
          options={lookups?.yes_no_list}
          value={watch(`donor_has_cycled_before`)}
          {...register(`donor_has_cycled_before`, {
            onChange: (e) => {
              setValue(`donor_has_cycled_before`, e.target.value);
              setError(`donor_has_cycled_before`, null);
              setValue(`donor_num_previous_cycles`, "");
              setValue(`donor_has_cycled_before_complications`, null);
              setValue(`donor_cycle_complications_description`, "");
            },
          })}
          isError={errors?.[`donor_has_cycled_before`]?.message}
          errorMsg={errors?.[`donor_has_cycled_before`]?.message}
        />
      </Grid>

      {watch(`donor_has_cycled_before`) === 1 && (
        <>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextInputIvf
              register={register}
              disabled={disabled}
              setError={setError}
              isError={errors?.[`donor_num_previous_cycles`]?.message}
              errorMsg={errors?.[`donor_num_previous_cycles`]?.message}
              id={`donor_num_previous_cycles`}
              name={`donor_num_previous_cycles`}
              type="text"
              label="Number of Previous Cycles?"
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: "16px", width: "100%" }}
              onRegisterChange={(e) => {
                if (e.target.value > 6) {
                  setCycleModal(true);
                }
              }}
            />
          </Grid>

          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <DropdownIVF
              label={`Has the Donor had Previous Cycle Related Complications?`}
              id={`donor_has_cycled_before_complications`}
              name={`donor_has_cycled_before_complications`}
              disabled={disabled}
              options={lookups?.yes_no_list}
              value={watch(`donor_has_cycled_before_complications`)}
              {...register(`donor_has_cycled_before_complications`, {
                onChange: (e) => {
                  e.target.value === 1 && setComplicationCycleModal(true);
                  setValue(
                    `donor_has_cycled_before_complications`,
                    e.target.value
                  );
                  setError(`donor_has_cycled_before_complications`, null);
                  setValue(`donor_cycle_complications_description`, "");
                },
              })}
              isError={
                errors?.[`donor_has_cycled_before_complications`]?.message
              }
              errorMsg={
                errors?.[`donor_has_cycled_before_complications`]?.message
              }
            />
          </Grid>
          {watch(`donor_has_cycled_before_complications`) == 1 && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                paddingTop={"18px"}
              >
                <TextInputIvf
                  register={register}
                  name={`donor_cycle_complications_description`}
                  id={`donor_cycle_complications_description`}
                  label="Please Describe Nature of Complications"
                  disabled={disabled}
                  sx={{ width: "100%" }}
                  multiline
                  rows={3}
                  {...register(`donor_cycle_complications_description`)}
                  variant="outlined"
                  tooltip={natureOfComplicationsTooltip}
                  isError={
                    errors?.[`donor_cycle_complications_description`]?.message
                  }
                  errorMsg={
                    errors?.[`donor_cycle_complications_description`]?.message
                  }
                />
              </Grid>

              <Box
                sx={{
                  display:
                    watch(`donor_has_cycled_before_complications`) == 1
                      ? "block"
                      : "none",
                }}
                alignItems="center"
                width="100%"
                marginY="2rem"
                mt={3}
                px={3}
              >
                <Typography
                  marginBottom="1rem"
                  id={`lbl-upload-file-donor_${application_id}`}
                >
                  Please upload the supporting documents.
                  <Tooltip tooltip="File can only be type of pdf / word" />
                </Typography>
                <FilePickerDonor
                  files={files}
                  disabled={disabled}
                  setFiles={setFiles}
                  setIsValidFile={setIsValidFile}
                  openPdfInNewTab={openPdfInNewTab}
                  setFileToBeDeleted={setFileToBeDeleted}
                  setShowDeleteModal={setShowDeleteModal}
                  handleFileUpload={(newFile) => handleFileUpload(newFile)}
                  isDocUploading={isDocUploading}
                  id={`donor_${application_id}`}
                  labelled_as_id={application_id}
                />
                <FormHelperText
                  sx={{
                    marginLeft: 0,
                    fontStyle: "italic",
                    fontWeight: 600,
                    letterSpacing: 0,
                    marginTop: "1rem",
                  }}
                  id="helper-text-upload-file"
                >
                  Note:{" "}
                  {isValidFile
                    ? "Two Files Max"
                    : "Only pdf and word file allowed"}
                </FormHelperText>
                <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
                  {docRequired}
                </FormHelperText>
              </Box>
            </>
          )}
        </>
      )}

      <CommonModal
        open={ageModal}
        type="warning"
        onCancel={() => {
          setValue(`donor_dob`, null);
          setAgeModal(false);
          setTimeout(() => {
            document.getElementById(`donor_dob`)?.focus();
          }, 100);
        }}
        onConfirm={() => {
          setValue(`donor_dob`, null);
          setAgeModal(false);
          setTimeout(() => {
            document.getElementById(`donor_dob`)?.focus();
          }, 100);
        }}
        title="Please Note"
        subTitle="The age of this person is either below the minimum age requirement which is 20 years or maximum age allowed by this automated system.
          Due to the Donor Age, we cannot approve coverage at this time.
          If you have any questions, please contact your broker."
      />
      <CommonModal
        open={cycleModal}
        type="warning"
        // onCancel={() => setCycleModal(false)}
        onConfirm={() => setCycleModal(false)}
        title="Please Note"
        subTitle="Please be aware!        
          Due to the lifetime cycles count this application will need to be reviewed.        
          If you have any questions, please contact your broker."
      />

      <CommonModal
        open={complicationCycleModal}
        type="warning"
        onConfirm={() => setComplicationCycleModal(false)}
        title="Please Note"
        subTitle="Please be aware!        
        Due to the complications cycles this application will need to be reviewed.        
        If you have any questions, please contact your broker."
      />

      <CommonModal
        open={showDeleteModal}
        type="delete"
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonLabel="Delete"
        onConfirm={removePdf}
        confirmButtonLoading={buttonLoading == "delete"}
        title="Are You Sure?"
        subTitle="Do you want to delete this file?"
      />
    </>
  );
};

export default AdminDonor;
