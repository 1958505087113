/*
 * IVF Clinic page
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { AllRoutes } from "../../../routes";
import { TableHeader } from "./TableHeader";
import { Button, GoBack } from "../../../components";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { ClinicApis } from "../../../api";

const IvfClinics = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState(false);
  const [searchButton, setSearchButton] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [hasRecords, setHasRecords] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [buttonLoading, setButtonLoading] = useState("");
  const [country, setCountry] = useState("US");
  const [state, setState] = useState(null);
  const [clinicStatus, setClinicStatus] = useState(null);
  const [viewDeleted, setViewDeleted] = useState(false);

  const propertyMapping = {
    country_code: setCountry,
    state_code: setState,
    name: setSearchText,
    is_active: setClinicStatus,
  };

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      if (!searchParams.has("is_active")) {
        searchParams.set("is_active", 1);
      }
      if (!searchParams.has("only_deleted")) {
        searchParams.set("only_deleted", 0);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    Object.keys(propertyMapping)?.forEach((key) => {
      if (obj?.hasOwnProperty(key)) {
        propertyMapping?.[key]?.(obj?.[key]);
      }
    });

    setPage(Number(obj["page"]));
    setViewDeleted(Number(obj["only_deleted"]));

    const checkRecords = async () => {
      const resp = await ClinicApis.getClinicsList({
        limit: true,
        country_code: "US",
        is_active: 1,
      });
      setHasRecords(resp?.data?.results?.length > 0);
    };
    checkRecords();
  }, []);

  useEffect(() => {
    if (hasRecords && page && searchButton) {
      getClinics();
    }
  }, [hasRecords, page, searchButton]); // Removed sortKey, sortOrder to avoid sorting from API

  // Get all change/cancel records
  const getClinics = async () => {
    setDataLoading(true);
    let params = {
      sortOrder: "", // Remove "" to sort from API
      sortKey: "", // Remove "" to sort from API
      page,
      country_code: country,
      state_code: state,
      name: searchText,
      limit: true,
      is_active: clinicStatus,
      only_deleted: Number(viewDeleted),
    };
    const resp = await ClinicApis.getClinicsList(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setSearchButton(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GoBack navigateURL={AllRoutes.WizardSettings.route} />
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
            <Typography variant="h1" sx={{ margin: "0" }}>
              IVF Clinics
            </Typography>
          </Grid>
          <Grid item xs={6} sm={5} md={4} lg={3} xl={2}>
            <Button
              title="Add New Clinic "
              variant="contained"
              sx={{ width: "100%", minHeight: "2rem" }}
              startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
              onClick={() => navigate(`${AllRoutes.AddClinic.route}`)}
            />
          </Grid>
        </Grid>
      </Box>

      <TableHeader
        searchText={searchText}
        setSearchText={setSearchText}
        setPage={setPage}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        country={country}
        setCountry={setCountry}
        state={state}
        setState={setState}
        clinicStatus={clinicStatus}
        viewDeleted={viewDeleted}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="Clinics"
        hasRecords={hasRecords}
        dataList={dataList}
        setDataList={setDataList}
        listData={listData}
        setSearchText={setSearchText}
        setSortKey={setSortKey}
        setSortOrder={setSortOrder}
        page={page}
        setPage={setPage}
        loading={loading}
        dataLoading={dataLoading}
        setSearchButton={setSearchButton}
        clinicStatus={clinicStatus}
        setClinicStatus={setClinicStatus}
        viewDeleted={viewDeleted}
        setViewDeleted={setViewDeleted}
        setSearchParams={setSearchParams}
      />
    </AdminPageLayout>
  );
};

export { IvfClinics };
