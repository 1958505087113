import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { CommonModal, DatePicker, Tooltip } from "../../../../../components";
import { useSelector } from "react-redux";
import DropdownIVF from "../common/Dropdown";
import { AttachmentApis } from "../../../../../api";
import TextInputIvf from "../common/TextInput";
import { FilePickerDonor } from "../../components/FilePickerdonor";
import { useDispatch } from "react-redux";
import { setIvfDocs } from "../../../../../redux/slices/globalSlice";
import { natureOfComplicationsTooltip } from "../../../../../constants";

const Donor = ({
  id,
  index,
  disabled = false,
  register,
  coverageTypeSelected,
  setValue,
  watch,
  errors,
  setError,
  application_id,
}) => {
  const dispatch = useDispatch();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const docs = useSelector((state) => state.ivfReducer.ivfDocs);
  const [cycleModal, setCycleModal] = useState(false);
  const [complicationCycleModal, setComplicationCycleModal] = useState(false);
  const [donorAgeModal, setDonorAgeModal] = useState(false);
  const [hasCyledBefore, setHasCycledBefore] = useState("");
  const [hasCyledComplicationBefore, setHasCycledComplicationBefore] =
    useState("");
  const [files, setFiles] = useState([]);
  const [isValidFile, setIsValidFile] = useState(true);
  const [fileToBeDeleted, setFileToBeDeleted] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDocUploading, setDocUploading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState("");
  const [docRequired, setDocRequired] = useState("");

  const openPdfInNewTab = (file) => {
    !!file?.url && window.open(file?.url, "_blank");
  };

  const removePdf = async () => {
    setButtonLoading("delete");
    setIsValidFile(true);
    const resp = await AttachmentApis.deleteAttachment({
      application_id: id,
      document_id: fileToBeDeleted,
    });
    !resp?.success && setButtonLoading("");
    !!resp?.success && getAttachments();
    setShowDeleteModal(false);
    setFileToBeDeleted();
  };

  const docId = application_id ? application_id : id;
  const getAttachments = async () => {
    const resp = await AttachmentApis.getAttachments({ application_id: docId });
    if (resp) {
      setDocUploading(false);
      setButtonLoading("");
    }
    if (resp?.success && resp?.data?.length) {
      const formattedFiles = resp.data?.map((file) => ({
        name: file.original_name,
        type: "",
        size: 0,
        id: file.id,
        url: file.url,
        labelled_as: file.labelled_as,
      }));
      setFiles(formattedFiles);
      dispatch(setIvfDocs(formattedFiles, `donor_${id}`));
    } else {
      setFiles([]);
      dispatch(setIvfDocs([], docId));
    }
  };

  const handleFileUpload = async (newFile) => {
    setDocUploading(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", `DOC_donor_${id}`);
    // formData.append("labelled_as", docId);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      application_id: id,
      formData,
      labelled_as: `DOC_donor_${id}`,
    });
    !resp?.success && setDocUploading(false);
    if (resp?.success) {
      setDocRequired("");
      getAttachments();
    }
  };

  const getDocs = () => {
    if (coverageTypeSelected == "EDO") {
      getAttachments();
    }
  };

  useEffect(() => {
    if (application_id || docs.length > 0) {
      getDocs();
    }
  }, [application_id]);

  useEffect(() => {
    setHasCycledBefore(watch(`applications.${index}.donor_has_cycled_before`));
    setHasCycledComplicationBefore(
      watch(`applications.${index}.donor_has_cycled_before_complications`)
    );
  }, [
    watch(`applications.${index}.donor_has_cycled_before_complications`),
    watch(`applications.${index}.donor_has_cycled_before`),
  ]);

  return (
    <>
      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <TextInputIvf
          disabled={disabled}
          register={register}
          setError={setError}
          isError={errors?.applications?.[index]?.[`donor_name_id`]?.message}
          errorMsg={errors?.applications?.[index]?.[`donor_name_id`]?.message}
          id={`applications.${index}.donor_name_id`}
          name={`applications.${index}.donor_name_id`}
          type="text"
          label={`Name/ID of Donor`}
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: "16px", width: "100%" }}
          tooltip="The Name or ID number entered on application, will appear on the ID card and the certificate of coverage. You can enter the donor ID number or initials. Donor information is confidential and it is only part of the application process. No information will be released to anyone, but applicant. This information is part of our internal process to better service the reproductive industry."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label={`Medication Start Date of Donor`}
          id={`applications.${index}.donor_medication_start_date`}
          name={`applications.${index}.donor_medication_start_date`}
          disabled={disabled}
          onChange={(date) => {
            setError(`applications.${index}.donor_medication_start_date`, null);
            setValue(`applications.${index}.donor_medication_start_date`, date);
          }}
          isError={
            errors?.applications?.[index]?.[`donor_medication_start_date`]
              ?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`donor_medication_start_date`]
              ?.message
          }
          minDate={dayjs()}
          value={
            watch(`applications.${index}.donor_medication_start_date`)
              ? dayjs(
                  watch(`applications.${index}.donor_medication_start_date`)
                )
              : undefined
          }
          maxDate={dayjs().add(60, "day")}
          tooltip={
            "The MSD can be selected no more than 60 days in the future of the submission date."
          }
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label={`DOB of Donor`}
          id={`applications.${index}.donor_dob`}
          name={`applications.${index}.donor_dob`}
          disabled={disabled}
          minDate={dayjs().subtract(45, "years")}
          maxDate={dayjs().subtract(20, "years")}
          value={
            watch(`applications.${index}.donor_dob`)
              ? dayjs(watch(`applications.${index}.donor_dob`))
              : undefined
          }
          onChange={(date) => {
            setError(`applications.${index}.donor_dob`, null);
            setValue(`applications.${index}.donor_dob`, date);
            if (
              dayjs?.(date)?.isAfter?.(dayjs()?.subtract?.(20, "years")) ||
              dayjs?.(date)?.isBefore?.(
                dayjs?.()?.subtract?.(45, "years")?.subtract?.(1, "day")
              )
            ) {
              setDonorAgeModal(true);
            }
          }}
          defaultCalendarMonth={dayjs().subtract(20, "years")}
          isError={errors?.applications?.[index]?.[`donor_dob`]?.message}
          errorMsg={errors?.applications?.[index]?.[`donor_dob`]?.message}
          disableFuture
          tooltip="Minimum age should be 20 years and maximum age should be 45 years."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DropdownIVF
          label={`Has Donor cycled before?`}
          id={`applications.${index}.donor_has_cycled_before`}
          name={`applications.${index}.donor_has_cycled_before`}
          disabled={disabled}
          options={lookups?.yes_no_list}
          value={watch(`applications.${index}.donor_has_cycled_before`)}
          {...register(`applications.${index}.donor_has_cycled_before`, {
            onChange: (e) => {
              setError(`applications.${index}.donor_has_cycled_before`, null);
              setHasCycledBefore(e.target.value);
              setValue(
                `applications.${index}.donor_has_cycled_before`,
                e.target.value
              );
              setValue(`applications.${index}.donor_num_previous_cycles`, "");
              setValue(
                `applications.${index}.donor_has_cycled_before_complications`,
                null
              );
              setValue(
                `applications.${index}.donor_cycle_complications_description`,
                ""
              );
            },
          })}
          isError={
            errors?.applications?.[index]?.[`donor_has_cycled_before`]?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`donor_has_cycled_before`]?.message
          }
        />
      </Grid>

      {hasCyledBefore === 1 && (
        <>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextInputIvf
              disabled={disabled}
              register={register}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[`donor_num_previous_cycles`]
                  ?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[`donor_num_previous_cycles`]
                  ?.message
              }
              id={`applications.${index}.donor_num_previous_cycles`}
              name={`applications.${index}.donor_num_previous_cycles`}
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              inputProps={{
                min: 1,
                onInput: (e) => {
                  if (e.target.value < 1) {
                    e.target.value = "";
                  }
                },
              }}
              label="Number of Previous Cycles?"
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: "16px", width: "100%" }}
              onRegisterChange={(e) => {
                if (
                  `applications.${index}.donor_num_previous_cycles` &&
                  e.target.value > 6
                ) {
                  setCycleModal(true);
                }
              }}
            />
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <DropdownIVF
              label={`Has the Donor had Previous Cycle Related Complications?`}
              id={`applications.${index}.donor_has_cycled_before_complications`}
              name={`applications.${index}.donor_has_cycled_before_complications`}
              disabled={disabled}
              options={lookups?.yes_no_list}
              value={watch(
                `applications.${index}.donor_has_cycled_before_complications`
              )}
              {...register(
                `applications.${index}.donor_has_cycled_before_complications`,
                {
                  onChange: (e) => {
                    setHasCycledComplicationBefore(e.target.value);
                    e.target.value === 1 && setComplicationCycleModal(true);
                    setValue(
                      `applications.${index}.donor_has_cycled_before_complications`,
                      e.target.value
                    );
                    setError(
                      `applications.${index}.donor_has_cycled_before_complications`,
                      null
                    );
                    setValue(
                      `applications.${index}.donor_cycle_complications_description`,
                      ""
                    );
                  },
                }
              )}
              isError={
                errors?.applications?.[index]?.[
                  `donor_has_cycled_before_complications`
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  `donor_has_cycled_before_complications`
                ]?.message
              }
            />
          </Grid>

          {hasCyledComplicationBefore === 1 && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                paddingTop={"18px"}
              >
                <TextInputIvf
                  register={register}
                  name={`applications.${index}.donor_cycle_complications_description`}
                  id={`applications.${index}.donor_cycle_complications_description`}
                  disabled={disabled}
                  label="Please Describe Nature of Complications"
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                  setError={setError}
                  isError={
                    errors?.applications?.[index]?.[
                      `donor_cycle_complications_description`
                    ]?.message
                  }
                  errorMsg={
                    errors?.applications?.[index]?.[
                      `donor_cycle_complications_description`
                    ]?.message
                  }
                  tooltip={natureOfComplicationsTooltip}
                />
              </Grid>
              <Box
                sx={{
                  display: hasCyledComplicationBefore == 1 ? "block" : "none",
                }}
                alignItems="center"
                width="100%"
                marginY="2rem"
                mt={3}
                px={3}
              >
                <Typography
                  marginBottom="1rem"
                  id={`lbl-upload-file-donor_${application_id}`}
                  fontSize="14px"
                >
                  Please upload the supporting documents.
                  <Tooltip tooltip="File can only be type of pdf / word" />
                </Typography>
                <FilePickerDonor
                  disabled={disabled}
                  files={files}
                  setFiles={setFiles}
                  setIsValidFile={setIsValidFile}
                  openPdfInNewTab={openPdfInNewTab}
                  setFileToBeDeleted={setFileToBeDeleted}
                  setShowDeleteModal={setShowDeleteModal}
                  handleFileUpload={(newFile) => handleFileUpload(newFile)}
                  isDocUploading={isDocUploading}
                  id={`donor_${id}`}
                  labelled_as_id={id}
                />
                <FormHelperText
                  sx={{
                    marginLeft: 0,
                    fontStyle: "italic",
                    fontWeight: 600,
                    letterSpacing: 0,
                    marginTop: "1rem",
                  }}
                  id="helper-text-upload-file"
                >
                  Note:{" "}
                  {isValidFile
                    ? "Two Files Max"
                    : "Only pdf and word file allowed"}
                </FormHelperText>
                <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
                  {docRequired}
                </FormHelperText>
              </Box>
            </>
          )}
        </>
      )}

      <CommonModal
        open={donorAgeModal}
        type="warning"
        // onCancel={() => {
        //   setValue(`applications.${index}.donor_dob`, null);
        //   setDonorAgeModal(false);
        // }}
        onConfirm={() => {
          setValue(`applications.${index}.donor_dob`, null);
          setDonorAgeModal(false);
          setTimeout(() => {
            document.getElementById(`applications.${index}.donor_dob`)?.focus();
          }, 100);
        }}
        title="Please Note"
        subTitle="The age of this person is either below the minimum age requirement which is 20 years or maximum age allowed by this automated system.
        Due to the Donor Age, we cannot approve coverage at this time.
        If you have any questions, please contact your broker."
      />

      <CommonModal
        open={cycleModal}
        type="warning"
        // onCancel={() => setCycleModal(false)}
        onConfirm={() => setCycleModal(false)}
        title="Please Note"
        subTitle="Please be aware!        
        Due to the lifetime cycles count this application will need to be reviewed.        
        If you have any questions, please contact your broker"
      />

      <CommonModal
        open={complicationCycleModal}
        type="warning"
        onConfirm={() => setComplicationCycleModal(false)}
        title="Please Note"
        subTitle="Please be aware!        
        Due to the complications cycles this application will need to be reviewed.        
        If you have any questions, please contact your broker"
      />

      <CommonModal
        open={showDeleteModal}
        type="delete"
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonLabel="Delete"
        onConfirm={removePdf}
        confirmButtonLoading={buttonLoading == "delete"}
        title="Are You Sure?"
        subTitle="Do you want to delete this file?"
      />
    </>
  );
};

export default Donor;
