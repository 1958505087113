/*
 * SAD ADMIN Edit Form- Paid status tab // 2nd tab
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import dayjs from "dayjs";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";

import { SadApis } from "../../../../api";
import { SadActionButtons } from "../components/ActionButtons";
import { CommonModal, DatePicker } from "../../../../components";
import { UtilFunctions } from "../../../../utils/CommonUtils";
import { sadApplicationSummary } from "../../../../redux/slices/globalSlice";
import { PaymentTypes } from "../../constants";

const Paidstatus = () => {
  const dispatch = useDispatch();
  const { id: application_id } = useParams();
  const [updateModal, setUpdateModal] = useState(false);
  const [COP_MST_DateModal, setCOP_MST_DateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);

  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      payment_status: "",
      medication_start_date: null,
      confirmation_of_pregnancy_date: null,
      payment_date: null,
      effective_from: null,
      effective_till: null,
    },
  });

  const {
    is_currently_pregnant,
    confirmation_of_pregnancy_date,
    medication_start_date,
    effective_from,
    effective_till,
    invoice_id,
    invoice_payment_date,
    invoice_payment_mode,
    invoice_payment_status,
  } = summaryData ?? {};

  useEffect(() => {
    window?.scrollTo?.(0, 0);
  }, []);

  useEffect(() => {
    setDataInitially();
  }, [summaryData]);

  const setDataInitially = () => {
    if (summaryData) {
      if (confirmation_of_pregnancy_date)
        setValue(
          "confirmation_of_pregnancy_date",
          dayjs(confirmation_of_pregnancy_date)
        );
      if (medication_start_date)
        setValue("medication_start_date", dayjs(medication_start_date));
      if (effective_from) setValue("effective_from", dayjs(effective_from));
      if (effective_till) setValue("effective_till", dayjs(effective_till));
      if (invoice_payment_date)
        setValue("payment_date", dayjs(invoice_payment_date));

      setValue("payment_status", invoice_payment_status);
    }
  };

  const onSubmitForm = async (data) => {
    setButtonLoading(true);

    let payload = data;
    delete payload["effective_till"];
    delete payload[
      is_currently_pregnant
        ? "medication_start_date"
        : "confirmation_of_pregnancy_date"
    ];

    payload = UtilFunctions.convertToDayjsYMDFormat(payload, [
      "medication_start_date",
      "confirmation_of_pregnancy_date",
      "effective_from",
      "effective_till",
      "payment_date",
    ]);

    if (!invoice_id || invoice_payment_mode == PaymentTypes.online) {
      delete payload["payment_date"];
      delete payload["payment_status"];
    }

    // Filter out null values from the payload object
    payload = Object.fromEntries(
      Object.entries(payload).filter(([_, value]) => value !== null)
    );

    const resp = await SadApis.updateApplication({
      application_id,
      params: payload,
    });

    !!resp && setButtonLoading(false);
    if (resp?.success) {
      dispatch(sadApplicationSummary({ application_id }));
      setUpdateModal(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={watch("payment_status") == PaymentTypes.paid}
                  disabled={
                    !invoice_id ||
                    invoice_payment_mode == PaymentTypes.online ||
                    !!invoice_payment_date
                  }
                  value={watch("payment_status")}
                  onChange={() =>
                    setValue(
                      "payment_status",
                      !!invoice_payment_date
                        ? PaymentTypes.paid
                        : watch("payment_status") !== PaymentTypes.paid
                        ? PaymentTypes.paid
                        : ""
                    )
                  }
                />
              }
              label="Policy Paid Status"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} rowSpacing={2} mb={3}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            id="medication_start_date"
            label="Medication Start Date"
            disabled={is_currently_pregnant == 1}
            {...(watch("medication_start_date") && {
              value: dayjs(watch("medication_start_date")),
            })}
            minDate={dayjs().subtract(35, "weeks")}
            maxDate={dayjs().add(45, "day")}
            tooltip="The Medication start date can be selected no more than 35 weeks prior to the submission date and no more than 45 days in the future of the submission date"
            onChange={(newValue) => {
              const minDate = dayjs().subtract(35, "weeks")?.subtract(1, "day");
              const maxDate = dayjs().add(45, "day");
              setCOP_MST_DateModal(
                dayjs(newValue).isBefore(minDate) ||
                  dayjs(newValue).isAfter(maxDate)
              );
              setValue(
                "medication_start_date",
                dayjs(newValue).isBefore(minDate) ||
                  dayjs(newValue).isAfter(maxDate)
                  ? ""
                  : newValue
              );
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            id="confirmation_of_pregnancy_date"
            label="Confirmation of Pregnancy Date"
            disableFuture={is_currently_pregnant}
            disabled={!is_currently_pregnant}
            tooltip="Pregnancy Confirmation Date must be within the last 6 months from the Application Date"
            {...(watch("confirmation_of_pregnancy_date") && {
              value: dayjs(watch("confirmation_of_pregnancy_date")),
            })}
            onChange={(newValue) => {
              const sixMonthsAgo = dayjs()
                .subtract(6, "months")
                ?.subtract(1, "day");
              setCOP_MST_DateModal(dayjs(newValue).isBefore(sixMonthsAgo));
              setValue("confirmation_of_pregnancy_date", newValue);
            }}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            id="payment_date"
            label="Date Paid"
            disabled={
              !invoice_id || invoice_payment_mode == PaymentTypes.online
            }
            {...(watch("payment_date") && {
              value: dayjs(watch("payment_date")),
            })}
            onChange={(newValue) => setValue("payment_date", newValue)}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            id="effective_from"
            label="Effective Date"
            {...(watch("effective_from") && {
              value: dayjs(watch("effective_from")),
            })}
            onChange={(newValue) => setValue("effective_from", newValue)}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <DatePicker
            id="effective_till"
            label="Expiration Date"
            disabled
            maxDate={dayjs().add(2, "year").add(6, "month")}
            {...(watch("effective_till") && {
              value: dayjs(watch("effective_till")),
            })}
            onChange={(newValue) => setValue("effective_till", newValue)}
          />
        </Grid>
      </Grid>

      <SadActionButtons
        onCancel={setDataInitially}
        buttonLoading={buttonLoading}
      />

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title="Application Updated Successfully!"
      />

      {/* Warning modal for confirmation pregnancy date and medication start date */}
      <CommonModal
        open={COP_MST_DateModal}
        type="warning"
        onConfirm={() => {
          setCOP_MST_DateModal(false);
          document
            .getElementById(
              !is_currently_pregnant
                ? "medication_start_date"
                : "confirmation_of_pregnancy_date"
            )
            ?.focus();
        }}
        title="Please Note"
        subTitle={
          !is_currently_pregnant
            ? "Medication Start Date cannot be more than 45 days in the future and more than 35 weeks prior from the date of application. Please reapply when you are within 45 days of medication start date commencing."
            : "Due to Late-Stage Pregnancy, this application will need to be reviewed before approval. If you have any questions, please contact your broker."
        }
      />
    </form>
  );
};

export { Paidstatus };
