/*
 * User -> SAD Policies -> Table data
 */

import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import {
  PaymentTypes,
  PolicyDocTypes,
  PolicyTypes,
  RequestTypes,
} from "../../constants";
import {
  AttachmentApis,
  PaymentApis,
  SadApis,
  UsersApis,
} from "../../../../api";
import {
  ALLOWED_ACTIONS,
  coverageTypeLabel,
  DEBOUNCE_DELAY,
  INSURANCE_TYPES,
  SAD_CR_VALIDITY,
} from "../../../../constants";
import {
  CommonModal,
  Dropdown,
  GlobalLoader,
  Loader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  RenderTableHeader,
  TextInput,
} from "../../../../components";
import {
  comma_separated,
  downloadBlobAsFile,
  formatString,
  formattedMDYDate,
} from "../../../../utils/CommonUtils";
import {
  setSadBeneficiaries,
  setSadSummary,
} from "../../../../redux/slices/globalSlice";
import { AllRoutes } from "../../../../routes";
import { useDebounce } from "../../../../hooks";
import ReactTable from "../../../../React Table";
import { theme } from "../../../../styles/theme";
import { ApplicationUtils } from "../../../../utils";

const {
  CONTINUE_SUBMIT_APPLICATION,
  EXTENSION_REQUEST_PAYMENT,
  PAYNOW,
  CHANGE_REQUEST,
  CANCEL_REQUEST,
  EXTENSION_REQUEST,
  DOWNLOAD_ALL_DOCS,
} = ALLOWED_ACTIONS;
const { paid } = PaymentTypes;
const { underReview } = PolicyTypes;
const { cancelRequest, changeRequest } = RequestTypes;

const TableData = ({ caption }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myData = useSelector((state) => state.commonReducer.meData);
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hasRecords, setHasRecords] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [page, setPage] = useState(1);
  const [listData, setListData] = useState();
  const [searchText, setSearchText] = useState("");
  const [submittedBy, setSubmittedBy] = useState(null);
  const [orgUsers, setOrgUsers] = useState([]);
  const [showCRInfoPopup, setShowCRInfoPopup] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [hiddenTitle, setHiddenTitle] = useState("");
  const [isOrgAdmin, setOrgAdmin] = useState(null);

  useEffect(() => {
    if (myData) {
      setOrgAdmin(Boolean(myData?.is_agency_admin || myData?.is_clinic_admin));
      setSubmittedBy(myData?.id);
    }
  }, [myData]);

  useEffect(() => {
    if (myData && isOrgAdmin) {
      getUsersList();
    }
  }, [isOrgAdmin]);

  useEffect(() => {
    if (isOrgAdmin !== null) {
      sadDataList();
    }
  }, [page, advancedSearch, isOrgAdmin]);

  useEffect(() => {
    const checkRecords = async () => {
      const resp = await SadApis.getAllPolicies({ page });
      setHasRecords(resp?.data?.results?.length > 0);
    };
    if (myData) {
      checkRecords();
    }
  }, [isOrgAdmin]);

  const getUsersList = async () => {
    const params = { sortOrder: "", sortKey: "first_name", limit: false };
    const res = await UsersApis.getAllUsers(params);
    const formatList = !!res?.length
      ? res?.map?.((user) => {
          const { first_name, last_name, id } = user || {};
          return { label: `${first_name || ""} ${last_name || ""}`, value: id };
        })
      : [];
    setOrgUsers(formatList);
  };

  // Generate ID to create new SAD application
  const createSADApplicationId = async () => {
    dispatch(setSadSummary({}));
    dispatch(setSadBeneficiaries([]));
    navigate(`${AllRoutes.SadForm.route}`);
  };

  // Get SAD records
  const sadDataList = async () => {
    setDataLoading(true);
    const { created_by, ...rest } = advancedSearch ?? {};
    const params = {
      page,
      advancedSearch:
        isOrgAdmin && submittedBy != myData?.id
          ? { ...advancedSearch, organization_policies: 1, exclude_draft: 1 }
          : rest,
    };
    const resp = await SadApis.getAllPolicies(params);
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
  };

  const handleListFilter = (e, key) => {
    setAdvancedSearch((p) => ({ ...p, [key]: e }));
    setPage(1);
  };

  const debounceOnSearch = useDebounce(async (value) => {
    handleListFilter(value, "q");
  }, DEBOUNCE_DELAY);

  const createPayment = async (invoice_id) => {
    setActionLoading(true);
    try {
      const resp = await PaymentApis.createPayment({
        invoice_id,
        insurance_type: INSURANCE_TYPES.sad,
      });
      !!resp && setActionLoading(false);
      !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const getPolicyDocs = async (application_id, policy_doc_id) => {
    setShowDocumentModal(true);
    setHiddenTitle(formatString(policy_doc_id));

    const resp = await AttachmentApis.getPolicyDoc({
      application_id,
      policy_doc_id,
    });

    if (resp) {
      setDocumentLink(resp);
    } else {
      setShowDocumentModal(false);
    }
  };

  const downloadDocs = async (application_id, policy_doc_id) => {
    setActionLoading(true);
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "all_docs.zip"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
      console.error("Failed to download policy docs");
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id, allowed_actions, has_cancel_request, invoice_id } =
      rowData || {};

    const allowedActions = allowed_actions?.map((item) => ({
      text: item.label,
      route: async () => {
        if (item.value === CONTINUE_SUBMIT_APPLICATION) {
          dispatch(setSadSummary({}));
          dispatch(setSadBeneficiaries([]));
          navigate(`${AllRoutes.SadForm.route}/${id}`);
        } else if (item.value === PAYNOW) {
          await createPayment(invoice_id);
        } else if (item.value === EXTENSION_REQUEST_PAYMENT) {
          navigate(`${AllRoutes.PolicyExtensionSummary.route}/${id}`);
        } else if (item.value === CHANGE_REQUEST) {
          setShowCRInfoPopup(changeRequest);
        } else if (item.value === CANCEL_REQUEST) {
          if (has_cancel_request) {
            setShowCRInfoPopup(cancelRequest);
          } else {
            dispatch(setSadSummary({}));
            navigate(`${AllRoutes.SadCancelReqPage.route}/${id}`);
          }
        } else if (item.value === EXTENSION_REQUEST) {
          navigate(`${AllRoutes.PolicyExtension.route}/${id}`);
        } else if (item.value === DOWNLOAD_ALL_DOCS) {
          await downloadDocs(id, PolicyDocTypes.all);
        }
      },
    }));

    setClickable(allowedActions);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    // setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => {
        const { allowed_actions } = row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              <IconButton
                disabled={!allowed_actions?.length}
                onClick={(e) => openActionDropDown(e, row.original)}
                aria-label={
                  "More actions of " + row.original?.application_number
                }
              >
                <MoreHorizIcon />
              </IconButton>
            }
          />
        );
      },
    },
    {
      id: "Policy Status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      accessorKey: "status",
      cell: (info) => {
        const { alerts } = info.row.original ?? {};
        const status = info.getValue();
        return (
          <RenderChip
            label={
              status === underReview
                ? !alerts?.length
                  ? "Submitted"
                  : "Under Review"
                : undefined
            }
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Coverage Type"
          headerKey="coverage_type"
        />
      ),
      accessorKey: "coverage_type",
      cell: (info) => {
        const { coverage_type, insurance_type } = info.row?.original;
        return (
          <RenderCellInfo
            text={coverageTypeLabel(insurance_type, coverage_type)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Term Length",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Term Length"
          headerKey="term_length"
        />
      ),
      accessorKey: "term_length",
      cell: (info) => {
        return (
          <RenderCellInfo
            text={info?.getValue() ? `${info.getValue()} Months` : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Limit of Indemnity for Surrogate's Beneficiary",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Limit of Indemnity for Surrogate's Beneficiary"
          headerKey="indemnity_surrogates_beneficiary"
        />
      ),
      accessorKey: "indemnity_surrogates_beneficiary",
      cell: (info) => {
        return (
          <RenderCellInfo
            text={
              info.getValue() !== null
                ? "$" + comma_separated(info.getValue())
                : ""
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Limit of Indemnity for IP",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Limit of Indemnity for IP"
          headerKey="indemnity_intended_parents"
        />
      ),
      accessorKey: "indemnity_intended_parents",
      cell: (info) => {
        return (
          <RenderCellInfo
            text={
              info.getValue() !== null
                ? `$${comma_separated(info.getValue())}`
                : ""
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Date"
          headerKey="submitted_on"
        />
      ),
      accessorKey: "submitted_on",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      accessorKey: "effective_from",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: "Expiration Date",
      accessorKey: "effective_till",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expiration Date"
          headerKey="effective_till"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "invoice_payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="invoice_payment_date"
        />
      ),
      cell: (info) => {
        const paymentDate = info.getValue();
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Total Payment",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Payment"
          headerKey="invoice_total"
        />
      ),
      accessorKey: "Total Payment",
      cell: (info) => {
        const data = info.row.original ?? {};
        const { invoice_total } = data;
        return (
          <RenderCellInfo
            text={
              ApplicationUtils.isInvoiceVisible(data) ? `$${invoice_total}` : ""
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Status"
          headerKey="invoice_payment_status"
        />
      ),
      accessorKey: "invoice_payment_status",
      cell: (info) => {
        const data = info.row.original ?? {};
        const paymentStatus = info.getValue();
        const isPaid = Boolean(paymentStatus === paid);
        const Icon = isPaid ? CheckCircleIcon : AccessTimeFilledIcon;
        const color = isPaid ? "#0D847D" : "#C0580E";

        return (
          <RenderCellInfo
            text={
              ApplicationUtils.isInvoiceVisible(data) && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon sx={{ fontSize: "1rem", color: color }} />
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      color: color,
                      marginLeft: "0.3rem",
                    }}
                  >
                    {isPaid ? "Paid" : "Pending"}
                  </Typography>
                </Box>
              )
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { allowed_policy_docs } = info.row.original ?? {};
        return (
          <RenderCellInfo
            text={
              <Box>
                {allowed_policy_docs?.map?.((d) => (
                  <RenderDocumentIcon
                    key={d.label}
                    tooltip={d?.label}
                    policy_doc_id={d?.document_id}
                    info={info}
                    getPolicyDocs={getPolicyDocs}
                  />
                ))}
              </Box>
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : (
        <>
          <Grid container spacing={2} mt={2}>
            {!isOrgAdmin && !hasRecords ? null : (
              <Grid item xs={3}>
                <TextInput
                  inputKey="search"
                  inputLabel="Search"
                  tooltip="Search by Application Number, Agency Name, Intended Parent Name, Surrogate Name, Applicant Name."
                  autoComplete="off"
                  margin="none"
                  required={false}
                  value={searchText}
                  onChange={(e) => {
                    debounceOnSearch(e.target.value);
                    setSearchText(e.target.value);
                  }}
                  sx={{ height: "2.5rem" }}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
            )}
            {!!isOrgAdmin && (
              <Grid item xs={3}>
                <Dropdown
                  size="small"
                  label="Submitted By"
                  id="created_by"
                  options={orgUsers || []}
                  required={false}
                  value={submittedBy}
                  onChange={(e) => {
                    setSubmittedBy(e.target.value);
                    handleListFilter(e.target.value, "created_by");
                  }}
                  margin="none"
                  sx={{ backgroundColor: theme.white }}
                />
              </Grid>
            )}
          </Grid>

          {dataList?.length > 0 ? (
            <Box>
              <ReactTable
                tableColumns={tableColumns}
                tableData={dataList}
                page={page}
                setPage={setPage}
                listData={listData}
                caption={caption}
                dataLoading={dataLoading}
              />
              <RenderActionMenu
                anchorEl={actionAnchorEl}
                handleClose={closeActionDropDown}
                actions={clickable}
                selectedRow={selectedRow}
              />
            </Box>
          ) : dataLoading ? (
            <Loader />
          ) : (
            <NoRecords
              {...(submittedBy == myData?.id &&
                !hasRecords && {
                  onPress: createSADApplicationId,
                })}
            />
          )}
        </>
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      <CommonModal
        open={!!showCRInfoPopup}
        type="info"
        onConfirm={() => {
          setShowCRInfoPopup("");
          if (
            showCRInfoPopup === changeRequest &&
            selectedRow?.is_change_request_allowed
          ) {
            dispatch(setSadSummary({}));
            navigate(`${AllRoutes.SadChangeReqPage.route}/${selectedRow?.id}`);
          }
        }}
        title="Information!"
        subTitle={
          showCRInfoPopup === cancelRequest && !!selectedRow?.has_cancel_request
            ? "Cancel Request Already Submitted!"
            : `New Life Agency will only accept change requests up to ${SAD_CR_VALIDITY} days from application date, if approved by New Life Agency.`
        }
      />

      {/* Documents modal */}
      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
        }}
        cancelButtonLabel="Close"
        maxWidth="lg"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Loader />
        ) : (
          <>
            <iframe
              src={documentLink}
              width="100%"
              height="500"
              style={{ display: !documentLink ? "none" : "block" }}
            />
          </>
        )}
      </CommonModal>
    </>
  );
};

export { TableData };
