/*
 * IVF Application Details
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { IvfApis } from "../../../../api";
import {
  setIvfSummary,
  setIvfTermCondition,
  setIvfUserAgreement,
} from "../../../../redux/slices/globalSlice";
import { AllRoutes } from "../../../../routes";
import { theme } from "../../../../styles/theme";
import AddNewApplications from "./AddNewApplications";
import { UtilFunctions } from "../../../../utils/CommonUtils";
import { LocalStorageHelper } from "../../../../utils/HttpUtils";
import { PaymentTypes, PolicyTypes } from "../../../sad/constants";
import DeleteIcon from "../../../../layouts/images/Delete-Icon.svg";
import { CommonModal, HeadAccordion } from "../../../../components";
import { INSURANCE_TYPES, USER_ROLE, USER_ROLES } from "../../../../constants";
import { SadActionButtons } from "../../../sad/sad-user/components/ActionButtons";

const IVFApplicationDetails = ({
  setActiveStep,
  setLoading,
  parentId,
  setparentId,
  applicationsId,
  setApplicationsId,
  setApplicationsLength,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: application_id, parent_id } = useParams();
  const isUserIP = LocalStorageHelper.get(USER_ROLE) === USER_ROLES.ip;
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) === USER_ROLES.agency;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [remove, setRemove] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState("");
  const [unverifiedOrgModal, setUnverifiedOrgModal] = useState("");
  const [showCLPModal, setShowCLPModal] = useState(false);
  const [autoFillCLPData, setAutoFillCLPData] = useState(false);
  const [showClinicModal, setShowClinicModal] = useState(false);
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);
  const myData = useSelector((state) => state.commonReducer.meData);

  const {
    register,
    control,
    setValue,
    formState: { errors },
    setError,
    getValues,
    watch,
  } = useForm({
    defaultValues: { applications: [] },
  });
  const {
    fields,
    append,
    remove: removeApplication,
  } = useFieldArray({
    keyName: "_id",
    name: "applications",
    control,
  });

  useEffect(() => {
    if (
      fields.length === 0 &&
      !application_id &&
      !parent_id &&
      summaryData.length < 1
    ) {
      append({});
      createIVFApplicationParentId();
    }
    return () => {
      removeApplication();
    };
  }, []);

  useEffect(() => {
    if (application_id) {
      append({});
      getApplicationHandler();
    }
  }, []);

  useEffect(() => {
    if (myData) {
      if (myData?.agency_is_active === 0 && myData?.clinic_is_active === 0) {
        setUnverifiedOrgModal("New Added Agency and Clinic");
      } else if (myData?.clinic_is_active === 0) {
        setUnverifiedOrgModal("New Added Clinic");
      } else if (myData?.agency_is_active === 0) {
        setUnverifiedOrgModal("New Added Agency");
      }
    }
  }, [myData]);

  useEffect(() => {
    if (summaryData) {
      removeApplication();
      append(summaryData);
    }
  }, [summaryData]);

  useEffect(() => {
    setApplicationsLength(fields.length);
  }, [fields]);

  useEffect(() => {
    const getIVFGroupAppsData = async () => {
      const resp = await IvfApis.getSummaryData({ parent_id });
      const updatedData = resp?.data?.map((application) => ({
        ...application,
        contractually_liable_party_country_code:
          application?.contractually_liable_party_country_code || "US",
        ...(application?.reproductive_organ_loss_rider_option === "" && {
          reproductive_organ_loss_rider_option: null,
        }),
      }));

      dispatch(setIvfSummary(updatedData));
      append(updatedData);
      setparentId(parent_id);
      setApplicationsId(updatedData?.[0]?.id);
    };
    if (parent_id && !application_id) {
      getIVFGroupAppsData();
    }
  }, [parent_id]);

  const getParentData = async () => {
    const resp = await IvfApis.getSummaryData({ parent_id: parentId });
    setApplicationsId(resp?.data[0]?.id);
  };

  const postDataForApplication = async (application) => {
    try {
      delete application["parent_id"];
      const responseData = await IvfApis.updateApplication({
        application_id: application?.id,
        params: { ...application },
      });
      return responseData;
    } catch (error) {
      return error;
    }
  };

  // Get Terms-conditions and user-agreement data
  const getTermsAgreement = async () => {
    const resp = await IvfApis.getTermsAgreements({
      application_id: applicationsId,
    });
    dispatch(setIvfTermCondition(resp?.data?.term ?? {}));
    dispatch(setIvfUserAgreement(resp?.data?.agreement ?? {}));
    return resp;
  };

  const onSubmitForm = async () => {
    const data = getValues();
    setButtonLoading(true);
    const promises = data?.applications?.map((application) => {
      let payload = application;
      const extraPayload = [
        "agreed_agreement_id",
        "agreed_term_id",
        "agreement_signature_name",
        "agency_country_code",
        "agency_state_code",
        "agency_name",
        // "agency_contact_person_name",
        "agency_zipcode",
        "agency_address_line_1",
        "agency_city",
        "clinic_country_code",
        "clinic_state_code",
        "clinic_name",
        // "clinic_doctor_name",
        "clinic_zipcode",
        "clinic_address_line_1",
        "clinic_city",
        "random",
      ];
      const { coverage_type, reproductive_organ_loss_rider } = payload;
      payload = UtilFunctions.deleteKeys(payload, extraPayload);

      if (coverage_type !== "EDR" || !reproductive_organ_loss_rider) {
        payload = UtilFunctions.deleteKeys(payload, [
          "optional_rider_intended_for",
        ]);
      }

      return postDataForApplication(payload);
    });

    const results = await Promise.all(promises);
    const allSuccess = results.every((result) => result?.success);

    if (allSuccess) {
      const tAndCResp = await getTermsAgreement();
      if (tAndCResp) {
        setButtonLoading(false);
      }
      dispatch(setIvfSummary(data?.applications));
      !!tAndCResp?.success && setActiveStep(1);
    } else {
      results.forEach((result, index) => {
        if (!result?.success) {
          setButtonLoading(false);
          for (const key in result?.errors) {
            setError(`applications.${index}.${key}`, {
              message: result?.errors?.[key],
            });
          }
        }
      });
    }
  };

  const deleteIVFApplicationId = async (id, index) => {
    setDeleteButtonLoading("delete");
    const resp = await IvfApis.deleteApplication({
      parent_id: parentId,
      application_id: id,
    });
    if (resp?.success) {
      setDeleteButtonLoading("");
      removeApplication(index);
      getParentData();
      setRemove(false);
      setTimeout(() => {
        document.getElementById("add-another-application").focus();
      }, 100);
    }
  };

  const createIVFApplicationId = async (
    parent_id,
    isRemove,
    fillCLPData,
    fillClinicData
  ) => {
    const payload = {
      insurance_type: INSURANCE_TYPES.ivf,
      parent_id: parent_id,
    };
    setLoading(true);
    const resp = await IvfApis.createApplicationId(payload);
    if (resp.data) {
      if (!isRemove) {
        removeApplication(0);
      }
      setApplicationsId(resp?.data?.id);

      const {
        contractually_liable_party,
        contractually_liable_party_first_name,
        contractually_liable_party_address_line_1,
        contractually_liable_party_country_code,
        contractually_liable_party_zipcode,
        contractually_liable_party_state_code,
        contractually_liable_party_city,
        clinic_id,
        clinic_country_code,
        clinic_country,
        clinic_state_code,
        clinic_name,
        clinic_doctor_name,
      } =
        getValues()?.applications?.[getValues()?.applications?.length - 1] ??
        {};

      let CLPData = {};
      if (fillCLPData || autoFillCLPData) {
        CLPData = {
          contractually_liable_party,
          contractually_liable_party_first_name,
          contractually_liable_party_address_line_1,
          contractually_liable_party_country_code,
          contractually_liable_party_zipcode,
          contractually_liable_party_state_code,
          contractually_liable_party_city,
        };
      } else {
        CLPData = { contractually_liable_party_country_code: "US" };
      }

      if (isUserAgency && fillClinicData) {
        CLPData = {
          ...CLPData,
          clinic_id,
          clinic_country_code,
          clinic_country,
          clinic_state_code,
          clinic_name,
          clinic_doctor_name,
        };
      }

      append({ ...resp?.data, ...CLPData });

      // Apply focus to newly added Application header button
      setTimeout(() => {
        const elements = document.querySelectorAll(
          '[id^="Application-"][id$="-header"]'
        );
        const lastElement = elements[elements.length - 1];
        if (lastElement) {
          lastElement.focus();
        }
      }, 100);
    }
    setLoading(false);
    setAutoFillCLPData(false);
  };

  const createIVFApplicationParentId = async () => {
    const payload = { insurance_type: INSURANCE_TYPES.ivf };
    setLoading(true);
    const resp = await IvfApis.createApplicationId(payload);
    if (resp?.success && resp?.data?.id && resp?.data?.is_parent) {
      const parentID = resp?.data?.id;
      setparentId(parentID);
      createIVFApplicationId(parentID);
      navigate(`${AllRoutes.IvfCycleParentPage.route}/${parentID}`, {
        replace: true,
      });
    }
  };

  const getApplicationHandler = async () => {
    setLoading(true);
    const resp = await IvfApis.getApplicationData({ application_id });
    if (resp?.success) {
      removeApplication(0);
      setparentId(resp?.data?.parent_id);
      setApplicationsId(application_id);
      const { status, alerts, invoice_payment_status } = resp?.data ?? {};
      if (status === PolicyTypes.underReview) {
        if (invoice_payment_status === PaymentTypes.paid) {
          navigate(AllRoutes.IvfPoliciesPage.route);
        } else if (
          !alerts?.length &&
          invoice_payment_status == PaymentTypes.notAttempted
        ) {
          setActiveStep(4);
        } else if (alerts?.length > 0) {
          navigate(AllRoutes.IvfPoliciesPage.route);
        }
      }
      setLoading(false);
      dispatch(setIvfSummary([resp?.data]));
    } else {
      setLoading(false);
      navigate(AllRoutes.IvfPoliciesPage.route);
    }
  };

  return (
    <>
      <form
        key={1}
        autoComplete={false}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitForm();
        }}
      >
        {/* --- This view has no impact, it's to be shown until data get load from API ---- */}
        {fields.length <= 0 && (
          <HeadAccordion
            defaultExpanded={true}
            showAccordion={fields.length > 1}
          >
            <AddNewApplications
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              setError={setError}
              setLoading={setLoading}
              setUnverifiedOrgModal={setUnverifiedOrgModal}
              application_id={application_id}
            />
          </HeadAccordion>
        )}
        {/* --------------------------------------------------------------------------------- */}

        {fields.length > 0 &&
          fields?.map((field, index) => {
            return (
              <div key={field?.id}>
                <HeadAccordion
                  defaultExpanded={true}
                  header={`Application-${index + 1}`}
                  handleDelete={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (fields.length > 1) {
                      setToDeleteId(field?.id);
                      setRemove(true);
                    }
                  }}
                  showAccordion={fields.length > 1}
                  showDelete={!application_id}
                >
                  <AddNewApplications
                    id={field?.id}
                    index={index}
                    disabled={buttonLoading}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                    setError={setError}
                    setLoading={setLoading}
                    application_id={application_id}
                    setUnverifiedOrgModal={setUnverifiedOrgModal}
                    headingLevel={fields.length > 1 ? "h3" : "h2"}
                  />
                </HeadAccordion>
                <CommonModal
                  type="delete"
                  open={remove && toDeleteId === field?.id}
                  dialogIcon={DeleteIcon}
                  cancelButtonLabel="Cancel"
                  onCancel={() => setRemove(false)}
                  confirmButtonLabel="Delete"
                  confirmButtonLoading={deleteButtonLoading == "delete"}
                  onConfirm={() => {
                    deleteIVFApplicationId(field?.id, index);
                  }}
                  title="Are You Sure?"
                  subTitle="Do you want to delete this Application?"
                />
              </div>
            );
          })}

        {!isUserIP &&
          fields.length > 0 &&
          fields?.length < 10 &&
          !application_id &&
          !!parent_id && (
            <Button
              variant="text"
              type="button"
              onClick={() => setShowCLPModal(true)}
              disabled={buttonLoading}
              sx={{
                color: theme.mistBlue,
                letterSpacing: 0,
                fontSize: "0.9rem",
              }}
              startIcon={
                <AddCircleOutlineIcon sx={{ color: theme.mistBlue }} />
              }
              id="add-another-application"
            >
              Add Another Application
            </Button>
          )}

        {/* Action Buttons */}
        <SadActionButtons
          onPressBack={() => navigate(-1)}
          buttonLoading={buttonLoading}
        />
      </form>

      {/* Warning modal if agency/clinic associated in user profile is unverified */}
      <CommonModal
        open={!!unverifiedOrgModal}
        type="warning"
        onConfirm={() => setUnverifiedOrgModal("")}
        title="Please Note"
        subTitle={`Due to ${unverifiedOrgModal}, this application will need to be reviewed before approval. If you have any questions, please contact your broker.`}
      />

      <CommonModal
        open={showCLPModal}
        type="warning"
        subTitle="Is the Contractually Liable Party Details in the next application same as the previous application?"
        cancelButtonLabel="Close"
        onCancel={() => {
          setShowCLPModal(false);
          setAutoFillCLPData(false);
          isUserAgency
            ? setShowClinicModal(true)
            : createIVFApplicationId(parentId, true);
        }}
        confirmButtonLabel="Yes"
        onConfirm={() => {
          setAutoFillCLPData(true);
          isUserAgency
            ? setShowClinicModal(true)
            : createIVFApplicationId(parentId, true, "autoFillCLPData");
          setShowCLPModal(false);
        }}
      />

      <CommonModal
        open={showClinicModal}
        type="warning"
        subTitle="Is the IVF Clinic Details in the next application same as the previous application?"
        cancelButtonLabel="Close"
        onCancel={() => {
          setShowClinicModal(false);
          createIVFApplicationId(parentId, true, autoFillCLPData, false);
        }}
        confirmButtonLabel="Yes"
        onConfirm={() => {
          createIVFApplicationId(
            parentId,
            true,
            autoFillCLPData,
            "autoFillClinicData"
          );
          setShowClinicModal(false);
        }}
      />
    </>
  );
};

export { IVFApplicationDetails };
