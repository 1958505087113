/*
 * SAD ADMIN GRID DATA
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  Checkbox,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import {
  ALLOWED_ACTIONS,
  coverageTypeLabel,
  strings,
  tableNames,
} from "../../../../constants";
import {
  CommonModal,
  Dropdown,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderChip,
  RenderDocumentIcon,
  RenderTableHeader,
  Tooltip,
} from "../../../../components";
import {
  downloadBlobAsFile,
  formatString,
  formattedMDYDate,
} from "../../../../utils/CommonUtils";
import { AllRoutes } from "../../../../routes";
import ReactTable from "../../../../React Table";
import { theme } from "../../../../styles/theme";
import { ApplicationUtils } from "../../../../utils";
import { setSadSummary } from "../../../../redux/slices/globalSlice";
import { ActionApis, AttachmentApis, SadApis } from "../../../../api";
import { PaymentTypes, PolicyDocTypes, PolicyTypes } from "../../constants";

const { canceled, underReview } = PolicyTypes;
const {
  EDIT_APPLICATION,
  EMAIL_APPLICATION,
  EMAIL_CERTIFICATE,
  EMAIL_INVOICE,
  DOWNLOAD_ALL_DOCS,
  UPLOAD_CERTIFICATE,
  ISSUE_REISSUE_CERTIFICATE,
} = ALLOWED_ACTIONS;

const TableData = ({
  caption,
  dataList,
  listData,
  setSearchText,
  brokerDropdown,
  page,
  setPage,
  loading,
  dataLoading,
  setDataList,
  setSearchParams,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [fileUploadDialog, setFileUploadDialog] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [documentLink, setDocumentLink] = useState("");
  const [updateModal, setUpdateModal] = useState({
    status: false,
    text: "",
    icon: "",
  });
  const [actionLoading, setActionLoading] = useState(false);
  const [hiddenTitle, setHiddenTitle] = useState("");
  const [isPreviewAction, setPreviewAction] = useState(false);

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const updateIndividualRow = async (application_id, showUpdateModal) => {
    const resp = await SadApis.getApplicationData({ application_id });
    setActionLoading(false);
    if (resp?.success) {
      setDataList((p) =>
        p?.map((i) => (i?.id === resp?.data?.id ? resp?.data : i))
      );
      if (showUpdateModal) {
        setUpdateModal({
          status: true,
          text: "Action Completed Successfully!",
        });
      }
    }
  };

  const updateApplication = async (application_id, params) => {
    setActionLoading(true);
    const res = await SadApis.updateApplication({ application_id, params });
    if (res?.success) {
      updateIndividualRow(application_id);
    } else {
      setActionLoading(false);
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id, allowed_actions } = rowData || {};
    const { all, invoice, application, certificate } = PolicyDocTypes;

    const allowedActions = allowed_actions?.map((item) => ({
      text: item.label,
      route: async () => {
        if (item.value === EDIT_APPLICATION) {
          dispatch(setSadSummary({}));
          navigate(`${AllRoutes.Sad_EditApplication.route}/${id}`);
        } else if (item.value === EMAIL_APPLICATION) {
          getPolicyDocs(id, application);
        } else if (item.value === EMAIL_CERTIFICATE) {
          getPolicyDocs(id, certificate);
        } else if (item.value === EMAIL_INVOICE) {
          getPolicyDocs(id, invoice);
        } else if (item.value === DOWNLOAD_ALL_DOCS) {
          await downloadDocs(id, all);
        } else if (item.value === UPLOAD_CERTIFICATE) {
          setFileUploadDialog(true);
        } else if (item.value === ISSUE_REISSUE_CERTIFICATE) {
          previewPolicyDoc(id, certificate);
        } else {
          policyAction(id, item.value);
        }
      },
    }));

    setClickable(allowedActions);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    // setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const downloadDocs = async (application_id, policy_doc_id) => {
    setActionLoading(true);
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "all_docs.zip"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
      console.error("Failed to download policy docs");
    }
  };

  const getPolicyDocs = async (application_id, policy_doc_id, showIframe) => {
    !showIframe && setActionLoading(true);
    if (showIframe) {
      setShowDocumentModal(true);
      setHiddenTitle(formatString(policy_doc_id));
    }

    let payload = { application_id, policy_doc_id };
    if (!showIframe) {
      payload = { ...payload, send_email: true };
    }
    const resp = await AttachmentApis.getPolicyDoc(payload);
    if (resp) {
      setActionLoading(false);
      showIframe
        ? setDocumentLink(resp)
        : setUpdateModal({ status: true, text: "Email Sent Successfully!" });
    } else {
      setActionLoading(false);
      setShowDocumentModal(false);
    }
  };

  const previewPolicyDoc = async (application_id, policy_doc_id) => {
    setShowDocumentModal(true);
    setHiddenTitle(formatString(policy_doc_id));
    setPreviewAction(true);
    const resp = await AttachmentApis.previewPolicyDoc({
      application_id,
      policy_doc_id,
    });
    setActionLoading(false);
    if (resp) {
      setDocumentLink(resp);
    } else {
      setShowDocumentModal(false);
      setPreviewAction(false);
    }
  };

  const policyAction = async (application_id, action) => {
    setActionLoading(true);
    let payload = { application_id, action };
    if (action === UPLOAD_CERTIFICATE) {
      const formData = new FormData();
      formData.append("file", fileToUpload);
      payload = { ...payload, formData };
    }
    const resp = await ActionApis.updatePolicyAction(payload);
    if (action === UPLOAD_CERTIFICATE && resp?.success) {
      setFileUploadDialog(false);
      setFileToUpload(null);
    }
    if (resp?.success) {
      updateIndividualRow(application_id, true);
    } else {
      setUpdateModal({
        status: true,
        text: Object.values?.(resp?.errors)
          ?.map((err) => `${err}`)
          .join("<br /> "),
        icon: "error",
      });
      setActionLoading(false);
    }
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => {
        const { allowed_actions } = row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              <IconButton
                disabled={!allowed_actions?.length}
                onClick={(e) => openActionDropDown(e, row.original)}
                aria-label={
                  "More actions of " + row.original?.application_number
                }
              >
                <MoreHorizIcon />
              </IconButton>
            }
          />
        );
      },
    },
    {
      id: "Policy Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        const { is_suspended } = info?.row?.original ?? {};
        return (
          <RenderChip
            label={
              status === underReview
                ? "Submitted"
                : status === canceled && is_suspended
                ? strings.suspended
                : undefined
            }
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Number",
      header: "Policy Number",
      accessorKey: "application_number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Coverage Type",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Coverage Type"
          headerKey="coverage_type"
        />
      ),
      accessorKey: "coverage_type",
      cell: (info) => {
        const { coverage_type, insurance_type } = info.row?.original;
        return (
          <RenderCellInfo
            text={coverageTypeLabel(insurance_type, coverage_type)}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Alert Status",
      header: "Alert Status",
      accessorKey: "alerts",
      cell: (info) => {
        const { cleared_at } = info?.row?.original ?? {};
        const alertStatus = info.getValue()?.length
          ? info
              .getValue()
              ?.map((alert, index) => (
                <div key={index}> {`${alert ?? ""}`}</div>
              ))
          : "";
        return info.getValue()?.length === 0 ? (
          <div className="text-center">
            <Tooltip title="No Alerts">
              <FiberManualRecordIcon
                fontSize="small"
                className="none-text-grey"
                title={undefined}
              />
            </Tooltip>
            <span className="hidden-visually">No Alerts</span>
          </div>
        ) : (
          <div className="text-center">
            <Tooltip tooltip={alertStatus}>
              {!!cleared_at ? (
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              ) : (
                <FiberManualRecordIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              )}
            </Tooltip>
            <div className="hidden-visually">{alertStatus}</div>
          </div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Status",
      header: "Payment Status",
      accessorKey: "invoice_payment_status",
      cell: (info) => {
        const paymentStatus = info.getValue();
        let icon;

        if (paymentStatus == PaymentTypes.paid) {
          icon = (
            <>
              <Tooltip title={"Paid"}>
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Paid</span>
            </>
          );
        } else if (paymentStatus === PaymentTypes.pending) {
          icon = (
            <>
              <Tooltip title={"Pending"}>
                <WatchLaterIcon
                  fontSize="small"
                  className="none-text-orange"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Pending</span>
            </>
          );
        } else {
          icon = (
            <>
              <Tooltip title={"Unpaid"}>
                <CancelIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">Unpaid</span>
            </>
          );
        }
        return (
          <div style={{ textAlign: "center", margin: "auto" }}>{icon}</div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Zoho Status",
      header: "Zoho Status",
      accessorKey: "invoice_zoho_invoice_synced_at",
      cell: (info) => {
        const { invoice_zoho_invoice_sync_comments } =
          info?.row?.original ?? {};
        let icon;
        if (info.getValue()) {
          icon = (
            <>
              <Tooltip title={formattedMDYDate(info.getValue(), true)}>
                <CheckCircleIcon
                  fontSize="small"
                  className="none-text-green"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">
                {formattedMDYDate(info.getValue(), true)}
              </span>
            </>
          );
        } else {
          icon = (
            <>
              <Tooltip title={invoice_zoho_invoice_sync_comments || "NA"}>
                <CancelIcon
                  fontSize="small"
                  className="none-text-red"
                  title={undefined}
                />
              </Tooltip>
              <span className="hidden-visually">
                {invoice_zoho_invoice_sync_comments || "NA"}
              </span>
            </>
          );
        }
        return (
          <div style={{ textAlign: "center", margin: "auto" }}>{icon}</div>
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Documents",
      header: "Documents",
      accessorKey: "Documents",
      cell: (info) => {
        const { allowed_policy_docs } = info.row.original ?? {};
        return (
          <RenderCellInfo
            text={
              <Box>
                {allowed_policy_docs?.map?.((d) => (
                  <RenderDocumentIcon
                    key={d.label}
                    tooltip={d.label}
                    policy_doc_id={d.document_id}
                    info={info}
                    getPolicyDocs={getPolicyDocs}
                  />
                ))}
              </Box>
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "IP / GS / Agency",
      accessorKey: "intended_parent_state",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="IP / GS / Agency"
          headerKey="intended_parent_state"
        />
      ),
      cell: (info) => {
        const {
          intended_parent_state,
          intended_parent_country_code,
          surrogate_state,
          surrogate_country_code,
          working_with_agency,
          agency_state,
          agency_country_code,
        } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              !!intended_parent_country_code ||
              !!surrogate_country_code ||
              !!agency_country_code ? (
                <Box>
                  {`${intended_parent_state || ""},${
                    intended_parent_country_code || ""
                  }`}
                  {` / ${surrogate_state || ""},${
                    surrogate_country_code || ""
                  }`}
                  {!!working_with_agency
                    ? ` / ${agency_state || ""},${agency_country_code || ""}`
                    : ""}
                </Box>
              ) : (
                ""
              )
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Issuable State",
      accessorKey: "issuable_state_code",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Issuable State"
          headerKey="issuable_state_code"
        />
      ),
      cell: (info) => {
        const {
          intended_parent_state,
          intended_parent_state_code,
          surrogate_state,
          surrogate_state_code,
          agency_state,
          agency_state_code,
        } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              !!info?.getValue() ? (
                <Box>
                  {`${
                    info.getValue() === intended_parent_state_code
                      ? intended_parent_state
                      : info.getValue() === surrogate_state_code
                      ? surrogate_state
                      : info.getValue() === agency_state_code
                      ? agency_state
                      : ""
                  }`}
                </Box>
              ) : (
                ""
              )
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "State Filed",
      header: "State Filed",
      accessorKey: "state_filed",
      cell: (info) => {
        const data = info?.row?.original ?? {};
        const { application_number, id, state_filed } = data;
        return (
          <RenderCellInfo
            text={
              <Checkbox
                key={id || ""}
                disabled={ApplicationUtils.nonEditableApplication(data)}
                checked={info.getValue()}
                onChange={() =>
                  updateApplication(id, { state_filed: Number(!state_filed) })
                }
                inputProps={{
                  "aria-label": "State Filed for " + application_number,
                }}
              />
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Applicant Name & Email",
      accessorKey: "intended_parent1_first_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Applicant Name & Email"
          headerKey="intended_parent1_first_name"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={
            <Box>
              <Typography>
                {info?.row?.original?.intended_parent1_first_name || ""}
              </Typography>
              <Typography>
                {info?.row?.original?.intended_parent1_email || ""}
              </Typography>
            </Box>
          }
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "IP Last Name",
      accessorKey: "intended_parent1_last_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="IP Last Name"
          headerKey="intended_parent1_last_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Surrogate Last Name",
      accessorKey: "surrogate_last_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Surrogate Last Name"
          headerKey="surrogate_last_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Agency",
      accessorKey: "agency_name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Agency"
          headerKey="agency_name"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Broker",
      header: "Broker",
      accessorKey: "default_broker_code",
      cell: (info) => {
        const data = info?.row?.original ?? {};
        const { id } = data;
        return (
          <Dropdown
            size="small"
            id="broker"
            options={brokerDropdown ?? []}
            required={false}
            disabled={ApplicationUtils.nonEditableApplication(data)}
            selectLabel="name"
            selectValue="code"
            value={info.getValue()}
            onChange={(e) =>
              updateApplication(id, { broker_code: e.target.value })
            }
            margin="none"
            sx={{ backgroundColor: theme.white }}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      accessorKey: "submitted_on",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Date"
          headerKey="submitted_on"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "MSD/COP",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="MSD/COP"
          headerKey="confirmation_of_pregnancy_date"
        />
      ),
      cell: (info) => {
        const {
          is_currently_pregnant,
          medication_start_date,
          confirmation_of_pregnancy_date,
        } = info?.row?.original;
        return (
          <RenderCellInfo
            text={
              !!confirmation_of_pregnancy_date || !!medication_start_date
                ? formattedMDYDate(
                    is_currently_pregnant
                      ? confirmation_of_pregnancy_date
                      : medication_start_date
                  )
                : ""
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "invoice_payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="invoice_payment_date"
        />
      ),
      cell: (info) => {
        const paymentDate = info.getValue();
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: "Effective Date",
      accessorKey: "effective_from",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: "Expiration Date",
      accessorKey: "effective_till",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expiration Date"
          headerKey="effective_till"
        />
      ),
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableName={tableNames.sadAdmin}
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            onSearch={setSearchText}
            caption={caption}
            isColumnCustomizingRequired={true}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      {/* Modal to upload certificate for canceled applications */}
      <CommonModal
        open={fileUploadDialog}
        onCancel={() => setFileUploadDialog(false)}
        confirmButtonLabel="Upload"
        onConfirm={() => policyAction(selectedRow?.id, UPLOAD_CERTIFICATE)}
        disableConfirmButton={!fileToUpload}
        title="Upload Certificate"
      >
        <TextField
          type="file"
          onChange={(e) => setFileToUpload(e.target.files[0])}
          fullWidth
          sx={{ my: "1rem" }}
        />
      </CommonModal>

      {/* Documents modal */}
      <CommonModal
        open={showDocumentModal}
        onCancel={() => {
          setShowDocumentModal(false);
          setDocumentLink("");
          setHiddenTitle("");
          setPreviewAction(false);
        }}
        {...(!isPreviewAction && { cancelButtonLabel: "Close" })}
        {...(isPreviewAction && {
          title: `Preview Certificate (${selectedRow?.application_number})`,
        })}
        confirmButtonLabel="Confirm"
        {...(isPreviewAction && {
          onConfirm: () => {
            setShowDocumentModal(false);
            setDocumentLink("");
            setHiddenTitle("");
            setPreviewAction(false);
            policyAction(selectedRow?.id, ISSUE_REISSUE_CERTIFICATE);
          },
        })}
        maxWidth="lg"
        visuallyHiddenTitle={hiddenTitle}
        fullWidth
      >
        {!documentLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={documentLink}
            width="100%"
            height="500"
            style={{ display: !documentLink ? "none" : "block" }}
          />
        )}
      </CommonModal>

      {/* Modal for successful email of invoice, application, certificate */}
      <CommonModal
        open={!!updateModal.status}
        type={updateModal?.icon || "success"}
        onConfirm={() => setUpdateModal({ status: false, text: "" })}
        title={updateModal.text}
      />
    </>
  );
};

export { TableData };
