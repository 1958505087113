import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { AllRoutes } from "../routes";
import { Footer } from "../components/Footer.js";
import { CommonApis } from "../api/CommonApis.js";
import logoImg from "./images/new_life_agency_logo.png";
import { USER_DATA, USER_TOKEN, USER_ROLES } from "../constants";
import { LocalStorageHelper } from "../utils/HttpUtils.js";
import vectorImg from "./images/new_life_agency_vector.svg";
import { setLookups } from "../redux/slices/commonSlice.js";

const useStyles = makeStyles({
  containerLeft: {
    backgroundColor: "#C0580E",
    boxShadow: "none",
    width: "100%",
  },
  logoImage: {
    width: "16rem",
    padding: "1.4rem 0",
  },
  vectorImage: {
    maxWidth: "100%",
    height: "auto",
    padding: "0",
    margin: "0",
    objectFit: "cover",
  },
  heading: {
    color: "#FFF",
    fontSize: "2.25rem",
    textAlign: "center",
    margin: "0",
    marginTop: "1.2rem",
    marginBottom: "0.4rem",
    fontWeight: "600",
  },
  subHeading: {
    color: "#FFF",
    fontSize: "1.125rem",
    textAlign: "center",
    width: "85%",
    margin: "0",
    marginBottom: "2rem",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      light: "#C0580E",
      main: "#C0580E",
      dark: "#A34B0C",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontSize: "1.6em",
      marginBottom: "1rem",
      fontWeight: 600,
      fontFamily: '"Lato", sans-serif',
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
  },
});

export const AuthLayout = ({ children = "" }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:700px)");
  const user = LocalStorageHelper.get(USER_TOKEN);
  const myData = JSON.parse(LocalStorageHelper.get(USER_DATA));

  useEffect(() => {
    const getLookups = async () => {
      const resp = await CommonApis.lookups();
      dispatch(setLookups(resp));
    };

    if (user) {
      const { role, working_with_agency, zipcode } = myData;
      const { agency_id } = myData ?? {};
      const { clinic_id } = myData ?? {};
      const { admin, broker, agency, clinic, ip } = USER_ROLES;
      if (role === admin) {
        window.location.href = AllRoutes.IvfAdminPoliciesPage.route;
      } else if (role === broker) {
        window.location.href = AllRoutes.IVFBrokerPolicies.route;
      } else if (
        (role === agency && !agency_id) ||
        (role === clinic && (!clinic_id || working_with_agency == null)) ||
        (role === ip && !zipcode)
      ) {
        window.location.href = AllRoutes.Profile.route;
      } else {
        window.location.href = AllRoutes.NewApplicationPage.route;
      }
    }

    getLookups();
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container sx={{ height: "100vh" }}>
          {/* Left Column */}
          {!isMobile && (
            <Grid item xs={12} md={5} className={classes.containerLeft}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                height="100%"
              >
                <Grid
                  item
                  xs
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <p className={classes.heading}>Welcome to New Life Agency</p>
                  <p className={classes.subHeading}>
                    New Life Agency Third Party Reproductive Medical Insurance
                    Products.
                  </p>
                </Grid>
                <Grid item>
                  <img className={classes.vectorImage} src={vectorImg} alt="" />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* Right Column */}
          <Grid item xs={12} md={isMobile ? 12 : 7}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
            >
              <Grid
                item
                xs
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                width="75%"
                sx={{
                  // Apply 100% width on screens smaller than md
                  [theme.breakpoints.down("sm")]: {
                    width: "unset",
                  },
                }}
              >
                <img
                  className={classes.logoImage}
                  src={logoImg}
                  alt="New Life Agency, Inc. The Surrogacy Insurance Experts logo"
                />
                {children}
              </Grid>
              <Grid item>
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
