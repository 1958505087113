/*
 * User profile page
 */

import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import {
  Box,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  Badge,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  Accordion,
  AddressInput,
  AgencyDetails,
  Button,
  ClinicDetails,
  CommonModal,
  GlobalLoader,
  InstructionNote,
  MandatoryStatement,
  PhoneInput,
  TextInput,
  Tooltip,
} from "../../components";
import { AllRoutes } from "../../routes";
import { AttachmentApis } from "../../api";
import { theme } from "../../styles/theme";
import logo from "../../layouts/images/user.svg";
import { CommonApis } from "../../api/CommonApis";
import {
  UtilFunctions,
  focusFirstErrorField,
  isObjectFilled,
  testNonLetter,
} from "../../utils/CommonUtils";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { LocalStorageHelper } from "../../utils/HttpUtils";
import { USER_ROLE, USER_ROLES } from "../../constants";
import { ZohoLandingPage } from "../zoho-connect";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
    </div>
  );
};

const iconStyles = {
  width: 25,
  height: 25,
  padding: "3px",
  fontSize: "small",
  color: "white",
  cursor: "pointer",
  backgroundColor: "#C0580E",
  borderRadius: "50%",
};

const UserProfile = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isUserAdmin = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.admin;
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.agency;
  const isUserClinic = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.clinic;
  const isUserIP = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.ip;
  const applyNewApplicationButtonRef = useRef(null);
  const [isProfileUpdated, setProfileUpdated] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [myData, setMyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isWorkingWithAgency, setWorkingWithAgency] = useState(false);
  const [completePercent, setCompletePercent] = useState(80);
  const [successMessage, setSuccessMessage] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const validationSchema = useMemo(() => {
    const requiredSchema = {
      email_alt: Yup.string()
        .nullable()
        .notRequired()
        .email("Invalid email")
        .transform((value, originalValue) =>
          originalValue === "" ? null : value
        ),
      display_name: Yup.string().required("Display Name is required"),
      address_line_1: Yup.string().required("Street Address is required"),
      country_code: Yup.string().required("Country is required"),
      zipcode: Yup.string().required("Zip Code is required"),
      state_code: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      password: Yup.string()
        .nullable()
        .test(
          "password-criteria",
          "Password must have at least 8 characters including an uppercase letter, a lowercase letter, a digit and a special character(#, ?, !, @, $, %, ^, &, *, -)",
          (value) => {
            if (!value) return true;
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-]).{8,}$/.test(
              value
            );
          }
        ),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords do not match"
      ),
    };
    if (isUserAgency || (isUserClinic && isWorkingWithAgency)) {
      requiredSchema["agency_country_code"] = Yup.string().required(
        "Agency Country is required"
      );
      requiredSchema["agency_state_code"] = Yup.string().required(
        "Agency State is required"
      );
      requiredSchema["agency_name"] = Yup.string().required(
        "Agency Name is required"
      );
    }

    if (isUserClinic) {
      requiredSchema["clinic_country_code"] = Yup.string().required(
        "Clinic Country is required"
      );
      requiredSchema["clinic_state_code"] = Yup.string().required(
        "Clinic State is required"
      );
      requiredSchema["clinic_name"] = Yup.string().required(
        "Clinic Name is required"
      );
      requiredSchema["working_with_agency"] = Yup.string().required(
        "Working With Agency is required"
      );
    }

    return Yup.object(requiredSchema);
  }, [isWorkingWithAgency]);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      profile_pic_attachment_id: "",
      profile_pic_attachment_url: "",
      display_name: "",
      address_line_1: "",
      zipcode: "",
      country_code: "US",
      state_code: "",
      city: "",
      password: "",
      confirm_password: "",

      agency_name: null,
      agency_country_code: "US",
      agency_state_code: null,

      clinic_name: null,
      clinic_country_code: "US",
      clinic_state_code: null,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  const hasPicture = watch("profile_pic_attachment_url");

  useEffect(() => {
    getMyData();
  }, []);

  useEffect(() => {
    if (Object.entries?.(myData)?.length) {
      const {
        first_name,
        last_name,
        display_name,
        mobile,
        address_line_1,
        country_code,
        zipcode,
        state_code,
        city,
        working_with_agency,
      } = myData ?? {};
      const {
        agency_id,
        agency_name,
        agency_country_code,
        agency_state_code,
        clinic_id,
        clinic_name,
        clinic_country_code,
        clinic_state_code,
        ...rest
      } = myData ?? {};

      Object.keys(rest).forEach((key) => {
        if (getValues()?.hasOwnProperty(key)) {
          setValue(key, rest[key]);
        }
      });

      setValue("mobile", mobile);
      setValue("country_code", country_code || "US");
      if (!display_name) {
        setValue("display_name", `${first_name || ""} ${last_name || ""}`);
      }
      if (working_with_agency !== null) {
        setValue("working_with_agency", working_with_agency);
        setWorkingWithAgency(working_with_agency);
      }
      if (isUserAgency || (isUserClinic && watch("working_with_agency"))) {
        setValue("working_with_agency", 1);
        setValue("agency_country_code", agency_country_code || "US");
        setValue("agency_state_code", agency_state_code || null);
        setValue("agency_name", agency_name);
      }
      if (isUserClinic) {
        setValue("clinic_country_code", clinic_country_code || "US");
        setValue("clinic_state_code", clinic_state_code || null);
        setValue("clinic_name", clinic_name);
      }
      if (
        [address_line_1, country_code, zipcode, state_code, city]?.every(
          (i) => !!i
        ) &&
        (isUserIP ||
          (isUserAgency && agency_id) ||
          (isUserClinic && clinic_id && working_with_agency !== null))
      ) {
        setProfileUpdated(true);
      }
    }
  }, [myData]);

  useEffect(() => {
    const {
      email_alt,
      profile_pic_attachment_id,
      profile_pic_attachment_url,
      ...rest
    } = getValues();

    if (hasPicture && isObjectFilled(rest)) {
      setCompletePercent("100");
    } else if (hasPicture || isObjectFilled(rest)) {
      setCompletePercent("95");
    } else {
      setCompletePercent("80");
    }
  }, [isProfileUpdated, watch("email"), watch("profile_pic_attachment_id")]);

  const getMyData = async () => {
    setLoading(true);
    const resp = await CommonApis.getMyProfile();
    resp?.success && setMyData(resp?.data ?? {});
    if (
      resp &&
      resp?.success &&
      (resp?.data?.agency_id || resp?.data?.clinic_id)
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const handleChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const onSubmitForm = async () => {
    setButtonLoading(true);
    let payload = getValues();

    payload = UtilFunctions.deleteKeys(payload, [
      "profile_pic_attachment_id",
      "profile_pic_attachment_url",
      "first_name",
      "last_name",
      "email",
      "mobile",
      "clinic_country_code",
      "clinic_state_code",
      "clinic_name",
      "agency_country_code",
      "agency_state_code",
      "agency_name",
    ]);

    if (!payload.password && !payload.confirm_password) {
      payload = UtilFunctions.deleteKeys(payload, [
        "password",
        "confirm_password",
      ]);
    }

    if (isUserAgency) {
      payload = UtilFunctions.deleteKeys(payload, ["clinic_id"]);
    }

    if (isUserClinic && !isWorkingWithAgency) {
      payload = UtilFunctions.deleteKeys(payload, ["agency_id"]);
    }

    const resp = await CommonApis.updateMyProfile(payload);
    if (resp) {
      setButtonLoading(false);
    }
    if (resp?.success) {
      setProfileUpdated(true);
      setSuccessMessage(true);
      setDialogTitle("Profile Updated Successfully!");
      window?.scrollTo?.(0, 0);
      applyNewApplicationButtonRef?.current?.focus();
    } else {
      Object.keys(resp?.errors).forEach(function (key) {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  const uploadProfilePicture = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("labelled_as", "PROFILE");
    formData.append("file", file);
    const resp = await AttachmentApis.uploadAttachment({
      formData,
    });
    !resp?.success && setLoading(false);

    if (resp?.success && resp?.data) {
      const { id, url } = resp?.data ?? {};
      setValue("profile_pic_attachment_id", id);
      setValue("profile_pic_attachment_url", url);
      const updatePicResp = await CommonApis.updateMyProfile({
        profile_pic_attachment_id: id,
        profile_pic_attachment_url: url,
      });
      !!updatePicResp && setLoading(false);
      // Show the alert if the profile picture was updated successfully
      if (updatePicResp?.success) {
        setSuccessMessage(true);
        setDialogTitle("Image Updated Successfully!");
      }
    } else {
      document.getElementById("imageUpload").value = "";
    }
  };

  // Function to handle user image upload
  const handleImageUpload = (event) => {
    // Check if a file is selected
    if (event.target.files && event.target.files.length > 0) {
      const uploadedImage = event.target.files[0];
      uploadProfilePicture(uploadedImage);
    } else {
      setValue("profile_pic_attachment_url", null);
    }
  };

  const handleUploadClick = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      document.getElementById("imageUpload").click();
    }
  };

  return (
    <AdminPageLayout hideDrawer={true}>
      {loading && <GlobalLoader loading={loading} />}

      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems={{ xs: "left", sm: "left", md: "center" }}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
      >
        <Typography variant="h1">My Profile</Typography>

        <Box sx={{ flexGrow: 1, display: "none", justifyContent: "center" }}>
          <InstructionNote
            note={`${
              completePercent == "100"
                ? `Your profile is ${completePercent}% complete`
                : `Your profile is only ${completePercent}% complete. Please take a few minutes to complete your profile.`
            }`}
            width={"fit-content"}
            alignItems={isMobile ? "flex-start" : "center"}
            sx={{ marginX: "0.5rem" }}
          />
        </Box>

        <Button
          ref={applyNewApplicationButtonRef}
          variant="outlined"
          fullWidth={true}
          onClick={() =>
            navigate(
              isUserAdmin
                ? AllRoutes.IvfAdminPoliciesPage.route
                : AllRoutes.NewApplicationPage.route
            )
          }
          disabled={!isUserAdmin && (buttonLoading || !isProfileUpdated)}
          sx={{
            background: theme.white,
            borderColor: theme.primaryDark,
            borderWidth: 2,
            width: "auto",
            marginBottom: "0.5rem",
            display: isUserAdmin || completePercent > 80 ? "block" : "none",
            fontSize: "0.94rem",
          }}
          title={isUserAdmin ? "Go To Dashboard" : "Apply New Application"}
        />
      </Box>

      <Grid container sx={{ paddingTop: "0.7rem" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="Profile Details"
            >
              <Tab
                label="General"
                {...a11yProps(0)}
                sx={{ margin: "0.1rem" }}
              />
              {/* <Tab
                  label="Messages"
                  {...a11yProps(1)}
                  sx={{ margin: "0.1rem" }}
                />
                <Tab
                  label="Settings"
                  {...a11yProps(2)}
                  sx={{ margin: "0.1rem" }}
                /> */}
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel value={activeTab} index={0}>
          <Typography variant="h2" className="hidden-visually">
            General
          </Typography>
          <Grid
            item
            xl={12}
            md={12}
            lg={12}
            sm={12}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              display: "flex",
            }}
          >
            <label htmlFor="imageUpload">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <Tooltip
                    title={
                      hasPicture
                        ? "Edit Profile Picture"
                        : "Upload Profile Picture"
                    }
                  >
                    <span
                      role="button"
                      tabindex="0"
                      className="btnProfileImageUpload"
                      disabled={buttonLoading}
                      onClick={handleUploadClick}
                      onKeyDown={handleUploadClick}
                      aria-label={
                        hasPicture
                          ? "Edit Profile Picture"
                          : "Upload Profile Picture"
                      }
                    >
                      {hasPicture ? (
                        <EditOutlinedIcon sx={iconStyles} />
                      ) : (
                        <FileUploadOutlinedIcon sx={iconStyles} />
                      )}
                    </span>
                  </Tooltip>
                }
              >
                <img
                  src={hasPicture || logo}
                  alt="Profile picture"
                  height="119"
                  width="119"
                  style={{
                    borderRadius: "50%",
                    cursor: buttonLoading ? "default" : "pointer",
                    objectFit: "cover",
                    border: !hasPicture && "3px solid #919298",
                  }}
                />
              </Badge>
            </label>
            {/* Input for user to upload their image */}
            <Box sx={{ display: "none" }}>
              <input
                type="file"
                id="imageUpload"
                disabled={buttonLoading}
                onChange={handleImageUpload}
                accept="image/*"
              />
            </Box>
            <p id="msgFileUpload" role="alert" aria-live="polite"></p>
          </Grid>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Box sx={{ marginLeft: "0.55rem", marginTop: "1.3rem" }}>
              <MandatoryStatement />
            </Box>
            <Grid
              container
              sx={{ justifyContent: "center" }}
              rowSpacing={2}
              columnSpacing={3}
            >
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <TextInput
                  inputKey="first_name"
                  inputLabel="First Name"
                  register={register}
                  disabled
                />
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <TextInput
                  inputKey="last_name"
                  inputLabel="Last Name"
                  register={register}
                  disabled
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey="display_name"
                  inputLabel="Display Name"
                  disabled={buttonLoading}
                  register={register}
                  inputProps={{ maxLength: 50 }}
                  isError={errors?.display_name}
                  errorMsg={errors?.display_name?.message}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey="email"
                  inputLabel="Email 1"
                  {...(!isUserAdmin && {
                    helperText:
                      "Note: Copy of Application, Invoice and All other documents will be sent to this email address.",
                  })}
                  register={register}
                  disabled
                  endAdornment={
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <TextInput
                  inputKey="email_alt"
                  inputLabel="Email 2"
                  disabled={buttonLoading}
                  register={register}
                  isError={errors?.email_alt}
                  errorMsg={errors?.email_alt?.message}
                  required={false}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <PhoneInput
                  inputKey="mobile"
                  inputLabel="Contact No."
                  autoComplete="off"
                  register={register}
                  {...(testNonLetter(watch("mobile")) && {
                    value: watch("mobile"),
                  })}
                  disabled
                />
              </Grid>
              <AddressInput
                disabled={buttonLoading}
                setLoading={setLoading}
                register={register}
                setValue={setValue}
                getValues={getValues}
                watch={watch}
                setError={setError}
                errors={errors}
                addressKey="address_line_1"
                countryKey="country_code"
                zipcodeKey="zipcode"
                stateKey="state_code"
                cityKey="city"
              />
            </Grid>

            <Accordion
              header="Update Password"
              component="h2"
              name="update-password"
              defaultExpanded={true}
            >
              <Grid container columnSpacing={3}>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextInput
                    inputKey="password"
                    inputLabel="Password"
                    disabled={buttonLoading}
                    autoComplete="off"
                    register={register}
                    isError={errors?.password}
                    errorMsg={errors?.password?.message}
                    required={false}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <TextInput
                    inputKey="confirm_password"
                    inputLabel="Confirm Password"
                    disabled={buttonLoading}
                    autoComplete="off"
                    register={register}
                    isError={errors?.confirm_password}
                    errorMsg={errors?.confirm_password?.message}
                    required={false}
                  />
                </Grid>
              </Grid>
            </Accordion>

            {/* Clinic Details */}
            {isUserClinic && (
              <Accordion
                header="Clinic Details"
                component="h2"
                name="clinic-details"
                defaultExpanded={true}
              >
                <ClinicDetails
                  disabled={buttonLoading}
                  userData={myData}
                  register={register}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Accordion>
            )}

            {/* Agency Details */}
            {(isUserAgency || isUserClinic) && (
              <Accordion
                header="Agency Details"
                component="h2"
                name="agency-details"
                defaultExpanded={true}
              >
                <AgencyDetails
                  disabled={buttonLoading}
                  userData={myData}
                  register={register}
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  loading={loading}
                  setLoading={setLoading}
                  setWorkingWithAgency={setWorkingWithAgency}
                />
              </Accordion>
            )}

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                title="Update"
                sx={{ marginTop: "1rem" }}
                loading={buttonLoading}
              />
            </Box>
          </form>
        </CustomTabPanel>
        {/* <CustomTabPanel value={activeTab} index={1}>
            <Typography variant="h2" className="hidden-visually">
              Messages
            </Typography>
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={2}>
            <Typography variant="h2" className="hidden-visually">
              Settings
            </Typography>
          </CustomTabPanel> */}
      </Grid>

      <CommonModal
        open={successMessage}
        type="success"
        onConfirm={() => setSuccessMessage(false)}
        title={dialogTitle}
      />

      <ZohoLandingPage />
    </AdminPageLayout>
  );
};

export { UserProfile };
