/*
 * A D M I N --- M O D U L E- S I G N E D-A F F I D A V I T--- T A B L E   D A T A
 */

import React, { useState } from "react";
import { Box, Button, Checkbox } from "@mui/material";
import { AttachmentApis } from "../../../api";

import ReactTable from "../../../React Table";
import {
  GlobalLoader,
  NoRecords,
  RenderCellInfo,
  RenderTableHeader,
} from "../../../components";
import {
  downloadBlobAsFile,
  formattedMDYDate,
} from "../../../utils/CommonUtils";
import { BULK_DOWNLOAD_DOC_TYPES, strings } from "../../../constants";

const TableData = ({
  caption,
  setSortKey,
  setSortOrder,
  stateList,
  page,
  setPage,
  loading,
  setLoading,
  dataList,
  listData,
  dataLoading,
}) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectedCheckAll, setSelectedCheckAll] = useState(false);
  const [isDownloading, setDownloading] = useState({ id: 0, index: -1 });
  const [actionLoading, setActionLoading] = useState(false);

  const getStateName = (state_code) => {
    return stateList?.find((state) => state?.value === state_code)?.label || "";
  };

  const downloadDocument = async (application_id, affidavit_id) => {
    const resp = await AttachmentApis.downloadDocument({
      application_id,
      policy_doc_id: affidavit_id,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "affidavit.pdf"}`);
      setDownloading({ id: 0, index: -1 });
    } else {
      setDownloading({ id: 0, index: -1 });
      console.error("Failed to download affidavit");
    }
  };

  const onZipDownload = async () => {
    const { affidavits } = BULK_DOWNLOAD_DOC_TYPES;
    setActionLoading(true);
    const resp = await AttachmentApis.bulkDownload({
      application_ids: selectedCheckbox,
      doc_id: affidavits,
      download: 1,
    });

    if (resp?.success && resp?.data) {
      downloadBlobAsFile(resp.data, `${resp?.filename || "affidavits.zip"}`);
      setActionLoading(false);
    } else {
      setActionLoading(false);
      console.error(`Failed to download affidavits`);
    }
  };

  const handleChangeAll = (event) => {
    const newCheckedState = event.target.checked;
    if (newCheckedState) {
      setSelectedCheckbox(dataList?.map((item) => item.id));
    } else {
      setSelectedCheckbox([]);
    }
    setSelectedCheckAll(newCheckedState);
  };

  const handleChange = (id) => {
    setSelectedCheckbox((prev) => {
      const newSelection = prev?.includes(id)
        ? prev?.filter((item) => item !== id)
        : [...prev, id];

      setSelectedCheckAll(newSelection.length === dataList.length);
      return newSelection;
    });
  };

  const tableColumns = [
    {
      id: "Checkbox",
      header: ({ column }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            sx={{
              marginLeft: "0.2rem",
              backgroundColor: "transparent",
              "&.Mui-checked": { color: "white" },
              "& .MuiSvgIcon-root": { color: "white" },
            }}
            checked={selectedCheckAll ? "checked" : undefined}
            onChange={handleChangeAll}
            indeterminate={
              selectedCheckbox.length > 0 &&
              selectedCheckbox.length < dataList.length
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
      accessorKey: "checkbox",
      cell: (info) => (
        <div>
          <Checkbox
            key={info.row.original?.id || ""}
            checked={
              selectedCheckbox.includes(info.row.original?.id)
                ? "checked"
                : undefined
            }
            onChange={() => handleChange(info.row.original?.id)}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "File Name",
      header: "File Name",
      accessorKey: "affidavits",
      cell: (info) => {
        const {
          id,
          application_number,
          disability_rider,
          disability_rider_option,
          reproductive_organ_loss_rider,
          reproductive_organ_loss_rider_option,
        } = info.row.original ?? {};
        return (
          <RenderCellInfo
            text={
              <Box
                key={`row-${id}`}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                {info.getValue()?.map((item, index) => {
                  const isDataProcessing =
                    isDownloading?.id === id && isDownloading?.index === index;

                  // Construct the base filename
                  let fileName = `${application_number}-`;

                  // Concatenate disability_rider_option if disability_rider is 1
                  if (disability_rider === 1) {
                    fileName += `${disability_rider_option}-`;
                  } else if (reproductive_organ_loss_rider === 1) {
                    // Concatenate reproductive_organ_loss_rider_option if reproductive_organ_loss_rider is 1
                    fileName += `${reproductive_organ_loss_rider_option}-`;
                  }

                  // Add the rest of the filename
                  fileName += `${item.replace("AFFIDAVIT_", "")}`;

                  fileName += ".pdf";

                  return (
                    <Button
                      key={`${id}-${index}`}
                      disabled={isDataProcessing}
                      onClick={() => {
                        downloadDocument(id, item);
                        setDownloading({ id, index });
                      }}
                      sx={{
                        cursor: "pointer",
                        color: "#0A7691",
                        margin: "-0.2rem",
                      }}
                    >
                      {isDataProcessing ? "Downloading..." : fileName}
                    </Button>
                  );
                })}
              </Box>
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },

    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => {
        return (
          <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "State",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State"
          headerKey="issuable_state_code"
        />
      ),
      accessorKey: "issuable_state_code",
      cell: (info) => <RenderCellInfo text={getStateName(info.getValue())} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Created On",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Created On"
          headerKey="created_at"
        />
      ),
      accessorKey: "created_at",
      cell: (info) => (
        <RenderCellInfo text={formattedMDYDate(info.getValue())} />
      ),
      footer: (props) => props.column.id,
    },

    {
      id: "Modified On",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Modified On"
          headerKey="updated_at"
        />
      ),
      accessorKey: "updated_at",
      cell: (info) => (
        <RenderCellInfo text={formattedMDYDate(info.getValue())} />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            showFilterDropdown={false}
            page={page}
            setPage={setPage}
            listData={listData}
            onZipDownload={onZipDownload}
            disableZipDownload={selectedCheckbox?.length === 0}
            downloadFileLabel={strings.batchDownload}
            setLoading={setLoading}
            dataLoading={dataLoading}
            caption={caption}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}
    </>
  );
};

export { TableData };
