/*
 * SAD admin application header
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { RequestApis } from "../../../api";
import { GoBack } from "../../../components";
import { formattedMDYDate } from "../../../utils/CommonUtils";
import { coverageTypeLabel, REQUEST_STATUS } from "../../../constants";

const ApplicationRequestHeader = ({ header, setLoading }) => {
  const { id: change_request_id } = useParams();
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    const getRequestData = async () => {
      setLoading(true);
      const resp = await RequestApis.getReqDataById({ change_request_id });
      !!resp && setLoading(false);
      !!resp?.success && setRequestData(resp?.data);
    };
    getRequestData();
  }, []);

  const {
    status,
    application_number,
    created_at,
    is_cancel_request,
    coverage_type,
    insurance_type,
    requested_by_first_name,
    requested_by_last_name,
    requested_by_email,
    description,
  } = requestData ?? {};

  const generalInformation = [
    {
      value: status ? (status == REQUEST_STATUS.close ? "Close" : "Open") : "",
      label: "Request Status:",
    },
    { value: application_number, label: "Policy Number:" },
    {
      value: created_at ? formattedMDYDate(created_at) : "",
      label: "Created On:",
    },
    {
      value: !!application_number
        ? `${is_cancel_request === 1 ? "Cancel" : "Change"} Request`
        : "",
      label: "Type of Request:",
    },
  ];

  const submitInformation = [
    {
      value: coverageTypeLabel(insurance_type, coverage_type),
      label: "Coverage Type:",
    },
    {
      value: `${requested_by_first_name || ""} ${requested_by_last_name || ""}`,
      label: "Requested By Name:",
    },
    { value: requested_by_email, label: "Requested By Email:" },
    { value: description, label: "Description:" },
  ];

  const RenderSummaryItem = ({ options = [] }) => {
    return (
      <Grid container sx={{ paddingTop: "0.7rem", paddingX: "0.5rem" }}>
        {!!options &&
          options?.map((option) => (
            <React.Fragment key={option.label}>
              <Grid item xl={4} lg={4} md={5} sm={5} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    color: "#222222",
                    paddingBottom: { xs: "0", sm: "0", md: "0.7rem" },
                  }}
                >
                  {option.label}
                </Typography>
              </Grid>
              <Grid item xl={8} lg={8} md={7} sm={7} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    paddingBottom: "0.7rem",
                    paddingLeft: { xs: "0", sm: "0", md: "0.3rem" },
                  }}
                >
                  {option.value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          {header}
        </Typography>
      </Box>

      <Grid
        container
        columnSpacing={4}
        sx={{
          backgroundColor: "#F082331A",
          padding: "10px",
          marginY: "0.7rem",
        }}
      >
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={generalInformation} />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <RenderSummaryItem options={submitInformation} />
        </Grid>
      </Grid>
    </>
  );
};

export { ApplicationRequestHeader };
