/*
 * Admin -> Wizard -> State Table -> State Fees/Tax -> Table Data
 */

import React, { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderDescriptionCell,
  RenderTableHeader,
} from "../../../components";
import { AllRoutes } from "../../../routes";
import { strings } from "../../../constants";
import ReactTable from "../../../React Table";
import { StateFeeApis } from "../../../api/StateFeeApis";

const TableData = ({
  caption,
  dataList,
  setDataList,
  loading,
  dataLoading,
}) => {
  const { country_code, state_code } = useParams();
  const navigate = useNavigate();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedFee, setSelectedFee] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const confirmDeleteFee = async () => {
    setButtonLoading(true);
    const resp = await StateFeeApis.deleteStateFee({ fee_id: selectedFee });
    if (resp?.success) {
      setDataList((p) => p?.filter?.((i) => i?.id !== selectedFee));
      setUpdateModal(true);
    }
    setButtonLoading(false);
    setSelectedFee(null);
    setShowDeleteModal(false);
  };

  const openActionDropDown = (e, rowData) => {
    const { id } = rowData ?? {};

    setClickable([
      {
        text: strings.editFee,
        route: () => {
          navigate(
            `${AllRoutes.EditStateTax.route}/${id}/${country_code}/${state_code}`
          );
        },
      },
      {
        text: strings.deleteFee,
        route: () => {
          setShowDeleteModal(true);
          setSelectedFee(id);
        },
      },
    ]);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => (
        <RenderCellInfo
          text={
            <IconButton onClick={(e) => openActionDropDown(e, row.original)}>
              <MoreHorizIcon />
            </IconButton>
          }
        />
      ),
    },
    {
      id: "State Code",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="State Code"
          headerKey="state_code"
        />
      ),
      accessorKey: "state_code",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Fee Label",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Fee Label"
          headerKey="label"
        />
      ),
      accessorKey: "label",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Fee",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Fee"
          headerKey="fee_percent"
        />
      ),
      accessorKey: "fee_percent",
      cell: (info) => {
        const { fee_flat, fee_percent } = info?.row?.original;
        return (
          <RenderCellInfo
            text={
              fee_percent > 0
                ? `${fee_percent}%`
                : fee_flat > 0
                ? `$${fee_flat}`
                : ""
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Notes",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Notes"
          headerKey="notes"
        />
      ),
      accessorKey: "notes",
      cell: (info) => (
        <RenderDescriptionCell
          description={info.getValue() || ""}
          cellWidth="100%"
        />
      ),
      footer: (props) => props.column.id,
    },
  ];

  if (loading) return <GlobalLoader loading={loading} />;

  return (
    <>
      {dataList?.length > 0 ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal(false)}
        title={"Fee Deleted Successfully!"}
      />

      <CommonModal
        open={showDeleteModal}
        type="delete"
        onCancel={() => {
          setShowDeleteModal(false);
          setSelectedFee(null);
        }}
        confirmButtonLabel="Delete"
        disableConfirmButton={buttonLoading}
        onConfirm={confirmDeleteFee}
        title="Are You Sure?"
        subTitle="Do you want to delete this fee?"
      />
    </>
  );
};

export { TableData };
