import axios from "axios";
import { toast } from "react-hot-toast";

import { handleUserLogout } from "./CommonUtils";
import { TOAST_TIME, USER_TOKEN } from "../constants";

export const LocalStorageHelper = {
  store: (key, value) => {
    if (key && value && typeof localStorage !== "undefined") {
      localStorage.setItem(key, btoa(JSON.stringify(value)));
    }
  },
  get: (key) => {
    if (key && typeof localStorage !== "undefined") {
      let value = localStorage.getItem(key);
      try {
        return JSON.parse(atob(value));
      } catch (e) {
        return value || null;
      }
    }
  },
  delete: (key) => {
    if (key && typeof localStorage !== "undefined") {
      localStorage.removeItem(key);
    }
  },
};

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { "Content-type": "application/json" },
});

httpClient.interceptors.request.use((config) => {
  const contentType = Object.entries(config.headers)?.[1]?.[1];
  // Do something before request is sent
  const token = LocalStorageHelper.get(USER_TOKEN);
  config.headers["Content-type"] = contentType || `application/json`;
  config.headers["Authorization"] = `Bearer ${token}`;
  return config;
});

const activeToasts = new Set();
const handleErrorToast = (errorType, message) => {
  if (!activeToasts?.has?.(errorType)) {
    activeToasts?.add?.(errorType);
    toast.error(message, {
      id: errorType,
      duration: TOAST_TIME,
      onClose: () => {
        activeToasts.delete(errorType);
      },
    });
  }
};

httpClient.interceptors.response.use(
  (response) => {
    const contentDisposition = response?.headers?.["content-disposition"];
    if (contentDisposition && contentDisposition?.includes?.("attachment")) {
      const filename = contentDisposition
        ?.split?.(";")
        ?.find?.((part) => part?.trim()?.startsWith?.("filename"))
        ?.split?.("=")?.[1]
        ?.trim();
      return { data: response.data, filename, success: true };
    }

    return { data: response.data, success: true };
  },
  (error) => {
    const errorResponse = error?.response;
    switch (errorResponse?.status) {
      case 401:
        handleErrorToast("401", "Session Expired!");
        setTimeout(() => {
          handleUserLogout();
        }, TOAST_TIME);
        return Promise.resolve({ success: false, code: 401 });

      case 403:
        handleErrorToast("403", "Permission Denied!");
        return Promise.resolve({ success: false, code: 403 });

      case 404:
        handleErrorToast("404", "Record Not Found!");
        return Promise.resolve({ success: false, code: 404 });

      case 422:
        const errors = errorResponse?.data ?? {};
        return Promise.resolve({ success: false, errors: errors, code: 422 });

      case 429:
        handleErrorToast("429", "Too Many Requests!");
        return Promise.resolve({ success: false, code: 429 });

      case 500:
        handleErrorToast("500", "Something Went Wrong!");
        return Promise.resolve({
          success: false,
          code: 500,
          errors: { global: errorResponse?.data?.message || "Network Error" },
        });

      default:
        handleErrorToast("500", "Something Went Wrong!");
        return Promise.reject({ success: false, code: 500 });
    }
    // return Promise.reject(error);
  }
);

export default httpClient;
