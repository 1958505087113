/*
 * Admin -> Wizard -> Agency -> Add/Edit Agency
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";

import {
  CommonModal,
  TextInput,
  AddressInput,
  PhoneInput,
  GlobalLoader,
  GoBack,
} from "../../../components";
import { AgencyApis } from "../../../api";
import { testNonLetter } from "../../../utils/CommonUtils";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";

const validationSchema = Yup.object({
  name: Yup.string().trim().required("Agency Name is required"),
  contact_person_name: Yup.string().nullable(),
  contact_number: Yup.string(),
  email: Yup.string()
    .nullable()
    .email("Email 1 must be a valid email address")
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
  email_alt: Yup.string()
    .nullable()
    .email("Email 2 must be a valid email address")
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
  address_line_1: Yup.string().required("Street Address is required"),
  country_code: Yup.string().required("Country is required"),
  zipcode: Yup.string().trim().required("Zip Code is required"),
  state_code: Yup.string().required("State is required"),
  city: Yup.string(),
});

const initiallyValues = {
  name: "",
  contact_person_name: "",
  contact_number: "",
  email: "",
  email_alt: "",
  zoho_crm_id: "",
  zoho_contact_id: "",
  address_line_1: "",
  country_code: "US",
  zipcode: "",
  state_code: null,
  city: "",
  is_active: 1,
};

const AddEditAgency = () => {
  const navigate = useNavigate();
  const { id: agency_id } = useParams();
  const [agencyData, setAgencyData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(!!agency_id);
  const [buttonLoading, setButtonLoading] = useState(false);

  const {
    formState: { errors },
    clearErrors,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    defaultValues: initiallyValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (agency_id) {
      getAgencyData();
    }
  }, []);

  useEffect(() => {
    if (agency_id) {
      setDataInitially();
    }
  }, [agencyData]);

  const getAgencyData = async () => {
    const resp = await AgencyApis.getAgencyById({ agency_id });
    setLoading(false);
    if (resp?.success) {
      setAgencyData(resp?.data);
    } else {
      navigate(-1);
    }
  };

  const setDataInitially = () => {
    if (agencyData) {
      Object.keys(agencyData).forEach((key) => {
        if (getValues()?.hasOwnProperty(key)) {
          setValue(key, agencyData[key]);
        }
      });
    }
  };

  const handlePhoneChange = (_, value) => {
    setValue("contact_number", value);
    !!errors?.contact_number?.message && setError("contact_number", null);
  };

  const onSubmit = async (data) => {
    setButtonLoading(true);
    let resp;

    if (agency_id) {
      resp = await AgencyApis.updateAgency({ agency_id, params: data });
    } else {
      resp = await AgencyApis.createAgency(data);
    }

    setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <GoBack />
        <Typography variant="h1" sx={{ margin: "0" }}>
          {!!agency_id ? "Edit Agency" : "Add New Agency"}
        </Typography>
      </Box>

      {loading && <GlobalLoader loading={loading} />}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="name"
              inputLabel="Agency Name"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              isError={errors?.name}
              errorMsg={errors.name?.message}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="contact_person_name"
              inputLabel="Contact Person Name"
              autoComplete="off"
              required={false}
              disabled={buttonLoading}
              register={register}
              isError={errors?.contact_person_name}
              errorMsg={errors.contact_person_name?.message}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <PhoneInput
              inputKey="contact_number"
              inputLabel="Contact No."
              autoComplete="off"
              required={false}
              disabled={buttonLoading}
              {...(testNonLetter(watch("contact_number")) && {
                value: watch("contact_number"),
              })}
              register={register}
              registerChange={handlePhoneChange}
              isError={errors?.contact_number}
              errorMsg={errors.contact_number?.message}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="email"
              inputLabel="Email 1"
              autoComplete="off"
              required={false}
              disabled={buttonLoading}
              register={register}
              isError={errors?.email}
              errorMsg={errors.email?.message}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="email_alt"
              inputLabel="Email 2"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              isError={errors?.email_alt}
              errorMsg={errors.email_alt?.message}
              required={false}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="zoho_crm_id"
              inputLabel="Zoho CRM ID"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              isError={errors?.zoho_crm_id}
              errorMsg={errors.zoho_crm_id?.message}
              required={false}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <TextInput
              inputKey="zoho_contact_id"
              inputLabel="Zoho Contact ID"
              autoComplete="off"
              disabled={buttonLoading}
              register={register}
              isError={errors?.zoho_contact_id}
              errorMsg={errors.zoho_contact_id?.message}
              required={false}
            />
          </Grid>
          <AddressInput
            disabled={buttonLoading}
            register={register}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            addressKey="address_line_1"
            countryKey="country_code"
            zipcodeKey="zipcode"
            stateKey="state_code"
            cityKey="city"
            requiredCity={false}
            setLoading={setLoading}
            errors={errors}
            clearErrors={clearErrors}
            setError={setError}
          />
        </Grid>

        <SadActionButtons
          onCancel={() => reset(!!agency_id ? agencyData : initiallyValues)}
          {...(!agency_id && { updateButtonLabel: "Add Agency" })}
          buttonLoading={buttonLoading}
        />
      </form>

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => {
          setUpdateModal(false);
          navigate(-1);
        }}
        title={`Agency ${!!agency_id ? "Updated" : "Added"} Successfully!`}
      />
    </AdminPageLayout>
  );
};

export { AddEditAgency };
