/**
 * Attachment APIs
 */

import httpClient from "../utils/HttpUtils";
import { convertToBlob } from "../utils/CommonUtils";
import { PolicyDocTypes } from "../pages/sad/constants";

const { application, invoice, idCardDonor, idCardRecipient } = PolicyDocTypes;

export const AttachmentApis = {
  uploadAttachment: async (payload) => {
    const { application_id, formData, labelled_as } = payload ?? {};

    try {
      const resp = await httpClient.post("attachments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (resp?.success && resp?.data?.id && application_id) {
        await httpClient.post(`applications/${application_id}/documents`, {
          attachment_id: resp?.data?.id,
          labelled_as: labelled_as || formData?.labelled_as || application_id,
        });
      }
      return resp;
    } catch (error) {
      console.log("Error uploadAttachment", error);
    }
  },

  getAttachments: async ({ application_id }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/documents`
      );
      return resp;
    } catch (error) {
      console.log("Error getAttachments", error);
    }
  },

  deleteAttachment: async ({ application_id, document_id }) => {
    try {
      const resp = await httpClient.delete(
        `applications/${application_id}/documents/${document_id}`
      );
      return resp;
    } catch (error) {
      console.log("Error deleteAttachment", error);
    }
  },

  getAffidavit: async ({ application_id, affidavit_id }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/affidavits/${affidavit_id}`,
        { responseType: "blob" }
      );

      let link = "";
      if (resp.success) {
        link = convertToBlob(resp?.data);
      }
      return link;
    } catch (error) {
      console.log("Error getAffidavit:", error);
    }
  },

  downloadDocument: async ({ application_id, policy_doc_id, download = 0 }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/policy_docs/${policy_doc_id}?download=${download}`,
        { responseType: "blob" }
      );
      return resp;
    } catch (error) {
      console.log("Error downloadDocument:", error);
    }
  },

  getPolicyDoc: async ({
    application_id,
    policy_doc_id,
    send_email = false,
  }) => {
    let reqUrl = `applications/${application_id}/policy_docs/${policy_doc_id}`;
    const useBlob = [
      application,
      invoice,
      idCardDonor,
      idCardRecipient,
    ].includes(policy_doc_id);

    const options = useBlob ? { responseType: "blob" } : {};
    if (send_email) {
      reqUrl += `?send_email=1`;
    }
    try {
      const resp = await httpClient.get(reqUrl, options);
      if (useBlob) {
        let link = "";
        if (resp.success) {
          link = convertToBlob(resp?.data);
        }
        return link;
      } else {
        return resp?.data?.url ?? "";
      }
    } catch (error) {
      console.log("Error getPolicyDoc:", error);
    }
  },

  previewPolicyDoc: async ({ application_id, policy_doc_id }) => {
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/preview_policy_docs/${policy_doc_id}`,
        { responseType: "blob" }
      );
      let link = "";
      if (resp.success) {
        link = convertToBlob(resp?.data);
      }
      return link;
    } catch (error) {
      console.log("Error previewPolicyDoc:", error);
    }
  },

  bulkDownload: async (payload) => {
    try {
      const resp = await httpClient.post("bulk_downloads", payload, {
        responseType: "blob",
      });
      return resp;
    } catch (error) {
      console.log("Error bulkDownload", error);
    }
  },
};
