/*
 * Admin module -> IVF -> Data grid index file
 */

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { IVFTableHeader } from "./TableHeader";
import { IVFTableComponent } from "./TableData";
import { PolicyTypes } from "../../../sad/constants";
import { BrokerApis, IvfApis } from "../../../../api";
import { INSURANCE_TYPES } from "../../../../constants";
import { oneMonthDateRangeText } from "../../../../utils/CommonUtils";
import { AdminPageLayout } from "../../../../layouts/admin-layout/AdminPageLayout";

const fieldValues = [
  { value: "1", label: "Policy Number", name: "application_number" },
  { value: "2", label: "Agency", name: "agency_name" },
  { value: "3", label: "Donor Name/ID", name: "donor_name_id" },
  { value: "4", label: "Recipient Name/ID", name: "recipient_name_id" },
  { value: "5", label: "Applicant Email", name: "email" },
];

const IVFAdminPolicies = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isSuspended, setSuspended] = useState(0);
  const [isVoided, setVoided] = useState(0);
  const [buttonLoading, setButtonLoading] = useState("");
  const [searchButton, setSearchButton] = useState(false);
  const [brokerList, setBrokerList] = useState([]);
  const [brokerDropdown, setBrokerDropdown] = useState([]);
  const [broker, setBroker] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [keyValues, setKeyValues] = useState([{ key: "0", value: "" }]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [policyStatus, setPolicyStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState({});

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("submitted_on")) {
        searchParams.set("submitted_on", oneMonthDateRangeText());
      }
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    if (obj?.hasOwnProperty("q")) {
      setSearchText(obj["q"]);
    }
    if (obj?.hasOwnProperty("submitted_on")) {
      setDateRange(
        obj["submitted_on"]?.split?.(",")?.map?.((dateStr) => new Date(dateStr))
      );
    }
    if (obj?.hasOwnProperty("default_broker_code")) {
      setBroker(obj["default_broker_code"]);
    }
    if (obj?.hasOwnProperty("is_suspended")) {
      setSuspended(obj["is_suspended"]);
    }
    if (obj?.hasOwnProperty("is_voided")) {
      setVoided(obj["is_voided"]);
    }
    if (obj?.hasOwnProperty("status")) {
      setPolicyStatus(obj["status"]);
    }
    if (obj?.hasOwnProperty("invoice_payment_status")) {
      setPaymentStatus(obj["invoice_payment_status"]);
    }
    setPage(Number(obj["page"]));

    setIsFilterExpanded(
      fieldValues?.some?.((field) => obj?.hasOwnProperty?.(field?.name))
    );

    const { page, ...rest } = obj;

    setAdvancedSearch(rest);

    // Set keyValues based on query parameters
    const initialKeyValues = Object.entries(rest)?.reduce(
      (acc, [key, value]) => {
        const field = fieldValues.find((field) => field?.name === key);
        if (field) {
          acc.push({ key: field.value, value });
        }
        return acc;
      },
      []
    );
    setKeyValues(
      initialKeyValues?.length > 0
        ? initialKeyValues
        : [{ key: "0", value: "" }]
    );

    const initialSelectedFields = initialKeyValues?.map(
      (keyValue) => keyValue.key
    );
    setSelectedFields(initialSelectedFields);

    getBrokerList();
  }, []);

  useEffect(() => {
    page > 0 && ivfDataList();
  }, [page, searchButton]);

  const getBrokerList = async () => {
    const params = { sortOrder: "", sortKey: "name" };
    const res = await BrokerApis.getBrokerList(params);
    setBrokerList(
      res?.success
        ? [
            { label: "All", value: "" },
            ...res?.data?.results?.filter?.(
              (broker) => broker?.insurance_type !== INSURANCE_TYPES.sad
            ),
          ]
        : []
    );
    setBrokerDropdown(
      res?.success
        ? res?.data?.results?.filter?.(
            (broker) => broker?.insurance_type !== INSURANCE_TYPES.sad
          )
        : []
    );
  };

  // Get IVF records
  const ivfDataList = async () => {
    setDataLoading(true);
    const { submitted_on, ...rest } = advancedSearch ?? {};
    const params = {
      page,
      advancedSearch:
        advancedSearch?.status === PolicyTypes.canceled
          ? { ...advancedSearch, is_cancelled: 1 }
          : advancedSearch?.status === PolicyTypes.draft
          ? { updated_at: advancedSearch?.submitted_on, ...rest }
          : advancedSearch,
    };
    const resp = await IvfApis.getAllPolicies(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Typography variant="h1">IVF Cycle Complications Insurance</Typography>

      <IVFTableHeader
        advancedSearch={advancedSearch}
        setAdvancedSearch={setAdvancedSearch}
        brokerList={brokerList}
        broker={broker}
        setBroker={setBroker}
        dateRange={dateRange}
        setDateRange={setDateRange}
        searchText={searchText}
        setSearchText={setSearchText}
        isFilterExpanded={isFilterExpanded}
        setIsFilterExpanded={setIsFilterExpanded}
        fieldValues={fieldValues}
        keyValues={keyValues}
        setKeyValues={setKeyValues}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        isSuspended={isSuspended}
        isVoided={isVoided}
        policyStatus={policyStatus}
        paymentStatus={paymentStatus}
        setSuspended={setSuspended}
        setVoided={setVoided}
        setPolicyStatus={setPolicyStatus}
        setPaymentStatus={setPaymentStatus}
        setPage={setPage}
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        setSearchParams={setSearchParams}
      />

      <IVFTableComponent
        caption="IVF Cycle Complications Insurance"
        dataList={dataList}
        listData={listData}
        setSearchText={setSearchText}
        brokerDropdown={brokerDropdown}
        page={page}
        setPage={setPage}
        loading={loading}
        dataLoading={dataLoading}
        setDataList={setDataList}
        setSearchParams={setSearchParams}
      />
    </AdminPageLayout>
  );
};

export { IVFAdminPolicies };
