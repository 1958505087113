/*
 * Admin -> Wizard -> Brokers Panel -> Add/Edit Broker
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import { ThemeProvider } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";

import {
  CommonModal,
  GlobalLoader,
  PhoneInput,
  TextInput,
  Dropdown,
  GoBack,
} from "../../../components";
import {
  focusFirstErrorField,
  testNonLetter,
} from "../../../utils/CommonUtils";
import { BrokerApis } from "../../../api";
import { theme } from "../../../styles/theme";
import { INSURANCE_TYPES_LIST, strings } from "../../../constants";
import { SadActionButtons } from "../../sad/sad-admin/components/ActionButtons";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const formValues = {
  name: "",
  email: "",
  website: "",
  contact_number: "",
  insurance_type: "ALL",
  is_active: 1,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required").email("Invalid email"),
  website: Yup.string()
    .required("Website Link is required")
    .test(
      "trimmed-not-empty",
      "Website Link cannot be empty or only spaces",
      (value) => value && value.trim() !== ""
    ),
  contact_number: Yup.string()
    .required("Contact Number is required")
    .min(7, "Contact No. must be at least 7 digits"),
});

const AddEditBroker = () => {
  const navigate = useNavigate();
  const { id: broker_id } = useParams();
  const [brokerData, setBrokerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formValues,
  });

  useEffect(() => {
    if (broker_id) {
      getBrokerData();
    }
  }, []);

  useEffect(() => {
    if (broker_id) {
      setDataInitially();
    }
  }, [brokerData]);

  const getBrokerData = async () => {
    setLoading(true);
    const resp = await BrokerApis.getBrokerDetail({ broker_id });
    setLoading(false);
    if (resp?.success) {
      setBrokerData(resp?.data);
    }
  };

  const setDataInitially = () => {
    if (brokerData) {
      Object.keys(brokerData).forEach((key) => {
        if (getValues()?.hasOwnProperty(key)) {
          setValue(key, brokerData[key]);
        }
      });
    }
  };

  const handlePhoneChange = (name, value) => {
    setValue(name, value);
    setError(name, null);
  };

  const onSubmit = async (data) => {
    setButtonLoading(true);

    const resp = !!broker_id
      ? await BrokerApis.updateBroker({ broker_id, params: data })
      : await BrokerApis.addBroker(data);

    setButtonLoading(false);
    if (resp?.success) {
      setUpdateModal(true);
    } else {
      Object.keys(resp?.errors || {}).forEach((key) => {
        setError(key, { message: resp?.errors[key] });
      });
    }
  };

  useEffect(() => {
    const errorArray = Object.keys(errors);
    if (!errorArray?.length) return;
    focusFirstErrorField();
  }, [errors]);

  return (
    <ThemeProvider theme={theme}>
      <AdminPageLayout>
        {loading && <GlobalLoader loading={loading} />}

        <Box display="flex" alignItems="center">
          <GoBack />
          <Typography variant="h1" sx={{ margin: "0" }}>
            {!!broker_id ? strings.editBroker : "Add New Broker"}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            sx={{ marginY: "1rem" }}
          >
            <Grid item xs={12} sm={6}>
              <TextInput
                inputKey="name"
                inputLabel="Broker Name"
                register={register}
                disabled={buttonLoading}
                isError={errors?.name}
                errorMsg={errors?.name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                inputKey="email"
                inputLabel="Email"
                register={register}
                disabled={buttonLoading}
                isError={errors?.email}
                errorMsg={errors?.email?.message}
                endAdornment={
                  false && (
                    <InputAdornment position="end">
                      <CheckCircleIcon sx={{ color: "#00AD00" }} />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                inputKey="website"
                inputLabel="Website Link"
                register={register}
                disabled={buttonLoading}
                isError={errors?.website}
                errorMsg={errors?.website?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                inputKey="contact_number"
                inputLabel="Contact No."
                autoComplete="off"
                {...(testNonLetter(watch("contact_number")) && {
                  value: watch("contact_number"),
                })}
                register={register}
                disabled={buttonLoading}
                registerChange={handlePhoneChange}
                isError={errors?.contact_number}
                errorMsg={errors?.contact_number?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                label="Insurance Type"
                id="insurance_type"
                options={[
                  { label: "All", value: "ALL" },
                  ...INSURANCE_TYPES_LIST,
                ]}
                register={register}
                disabled={buttonLoading}
                value={watch("insurance_type")}
                isError={watch("insurance_type") === null}
                errorMsg={errors?.insurance_type?.message}
              />
            </Grid>
          </Grid>

          <SadActionButtons
            onCancel={() => reset(!!broker_id ? brokerData : formValues)}
            {...(!broker_id && { updateButtonLabel: "Add Broker" })}
            buttonLoading={buttonLoading}
          />
        </form>

        <CommonModal
          open={updateModal}
          type="success"
          onConfirm={() => {
            setUpdateModal(false);
            navigate(-1);
          }}
          title={`Broker ${!!broker_id ? "Updated" : "Added"} Successfully!`}
        />
      </AdminPageLayout>
    </ThemeProvider>
  );
};

export { AddEditBroker };
