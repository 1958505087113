/**
 * Agency APIs
 */

import httpClient from "../utils/HttpUtils";

export const AgencyApis = {
  // we consider limit false for dropdowns (to show all data)
  // and true for grid (to show 20 data)
  getAgenciesList: async (payload) => {
    const {
      country_code = "",
      state_code = "",
      name = null,
      limit = false,
      sortOrder,
      sortKey,
      page,
      is_active,
      only_deleted,
    } = payload ?? {};

    let reqUrl = `/agencies?limit=${
      limit ? 20 : -1
    }&filters[country_code]=${country_code}`;
    if (sortOrder || sortKey) {
      reqUrl += `&sort=${sortOrder ? "-" : ""}${sortKey}`;
    }
    if (page) {
      reqUrl += `&page=${page}`;
    }
    if (state_code) {
      reqUrl += `&filters[state_code]=${state_code}`;
    }
    if (typeof name == "string") {
      reqUrl += `&filters[name]=${name}`;
    }
    if (only_deleted) {
      reqUrl += `&filters[only_deleted]=${only_deleted}`;
    }
    if (is_active) {
      reqUrl += `&filters[is_active]=${is_active}`;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return limit ? resp : resp?.data?.results;
    } catch (error) {
      console.log("Error getAgenciesList", error);
    }
  },

  createAgency: async (payload) => {
    try {
      const resp = await httpClient.post(`agencies`, payload);
      return resp;
    } catch (error) {
      console.log("Error createAgency", error);
    }
  },

  getAgencyById: async (payload) => {
    const { agency_id } = payload;
    try {
      const resp = await httpClient.get(`agencies/${agency_id}`);
      return resp;
    } catch (error) {
      console.log("Error getAgencyById:", error);
    }
  },

  updateAgency: async (payload) => {
    const { agency_id, params } = payload;
    try {
      const resp = await httpClient.put(`agencies/${agency_id}`, params);
      return resp;
    } catch (error) {
      console.log("Error updateAgency", error);
    }
  },

  deleteAgency: async ({ agency_id, restore }) => {
    try {
      let reqUrl = `/agencies/${agency_id}`;
      if (restore) {
        reqUrl += `?restore=1`;
      }
      const resp = await httpClient.delete(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error deleteAgency", error);
    }
  },
};
