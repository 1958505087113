/*
 * FE Error Page
 */

import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";

import { Button } from "./Button";
import { theme } from "../styles/theme";
import Error404 from "../layouts/images/Error404.png";
import WarningIcon from "../layouts/images/Warning.svg";

const useStyles = makeStyles(() => ({
  imageIcon: { width: "5rem" },
}));

const ErrorPage = ({ is404 }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
            gap: "2rem",
          }}
        >
          {is404 ? (
            <>
              <img src={Error404} alt="" width={500} height={250} />
              <Typography variant="h6">
                The page you’re looking for doesn’t exist.
              </Typography>
              <Button
                variant="contained"
                title="Go Back"
                onClick={() => navigate(-1)}
                sx={{
                  textTransform: "none",
                  backgroundColor: theme.primaryDark,
                  "&:hover": { backgroundColor: theme.primaryDark },
                }}
              />
            </>
          ) : (
            <>
              <img src={WarningIcon} alt="" className={classes.imageIcon} />
              <Typography variant="h6">
                Something went wrong. Please try again later.
              </Typography>
              <Button
                variant="contained"
                title="Reload"
                sx={{
                  textTransform: "none",
                  letterSpacing: "0.1rem",
                  backgroundColor: theme.primaryDark,
                  "&:hover": { backgroundColor: theme.primaryDark },
                }}
                onClick={() => window.location.reload()}
              />
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export { ErrorPage };
