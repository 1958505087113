/*
 * Admin -> Wizard -> Coverage Type -> Table data
 */

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  coverageTypeLabel,
  INSURANCE_TYPES,
  PLANS_TYPES_VALUES,
} from "../../../constants";
import {
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderTableHeader,
} from "../../../components";
import { AllRoutes } from "../../../routes";
import ReactTable from "../../../React Table";
import { comma_separated } from "../../../utils/CommonUtils";

const { COVERAGE_ONLY, RIDERS_ONLY } = PLANS_TYPES_VALUES;

const TableData = ({
  caption,
  dataList,
  selectedInsurance,
  selectedPlan,
  loading,
  dataLoading,
}) => {
  const navigate = useNavigate();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);

  const openActionDropDown = (e, rowData) => {
    setClickable([
      {
        text: "Edit Plan",
        route: () => navigate(`${AllRoutes.EditPlan.route}/${rowData?.id}`),
      },
    ]);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Action",
      header: "Action",
      cell: ({ row }) => (
        <IconButton onClick={(e) => openActionDropDown(e, row.original)}>
          <MoreHorizIcon />
        </IconButton>
      ),
    },
    {
      id: "Name",
      accessorKey: "policy_plan",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Name"
          headerKey="policy_plan"
        />
      ),
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Term Length",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Term Length"
          headerKey="term_length"
        />
      ),
      accessorKey: "term_length",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Key Code",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Key Code"
          headerKey="key_code"
        />
      ),
      accessorKey: "key_code",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    ...(selectedPlan == RIDERS_ONLY
      ? [
          {
            id: "Limit of Indemnity",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Limit of Indemnity"
                headerKey="description"
              />
            ),
            accessorKey: "description",
            cell: (info) => <RenderCellInfo text={info.getValue()} />,
            footer: (props) => props.column.id,
          },
        ]
      : []),
    {
      id:
        selectedInsurance === INSURANCE_TYPES.ivf
          ? "Premium"
          : "Premium (Surrogate age 21-40)",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName={
            selectedInsurance === INSURANCE_TYPES.ivf
              ? "Premium"
              : "Premium (Surrogate age 21-40)"
          }
          headerKey="premium"
        />
      ),
      accessorKey: "premium",
      cell: (info) => (
        <RenderCellInfo
          text={`$${comma_separated(info.getValue())}${
            selectedInsurance === INSURANCE_TYPES.sad &&
            selectedPlan == COVERAGE_ONLY &&
            lookups?.sad_policy_benefit
              ? ` / $${comma_separated(lookups?.sad_policy_benefit)}`
              : ""
          }`}
        />
      ),
      footer: (props) => props.column.id,
    },
    ...(selectedInsurance === INSURANCE_TYPES.ivf
      ? selectedPlan == COVERAGE_ONLY
        ? [
            {
              id: "Coverage Type",
              header: ({ column }) => (
                <RenderTableHeader
                  column={column}
                  headerName="Coverage Type"
                  headerKey="coverage_type"
                />
              ),
              accessorKey: "coverage_type",
              cell: (info) => (
                <RenderCellInfo
                  text={coverageTypeLabel(INSURANCE_TYPES.ivf, info.getValue())}
                />
              ),
              footer: (props) => props.column.id,
            },
            {
              id: "Maximum Limit of Indemnity",
              accessorKey: "ivf_benefit",
              header: ({ column }) => (
                <RenderTableHeader
                  column={column}
                  headerName="Maximum Limit of Indemnity"
                  headerKey="ivf_benefit"
                />
              ),
              cell: (info) => (
                <RenderCellInfo text={`$${comma_separated(info.getValue())}`} />
              ),
              footer: (props) => props.column.id,
            },
            {
              id: "Accidental Death Limit",
              accessorKey: "ivf_accidental_death_benefit",
              header: ({ column }) => (
                <RenderTableHeader
                  column={column}
                  headerName="Accidental Death Limit"
                  headerKey="ivf_accidental_death_benefit"
                />
              ),
              cell: (info) => (
                <RenderCellInfo text={`$${comma_separated(info.getValue())}`} />
              ),
              footer: (props) => props.column.id,
            },
            {
              id: "Accidental Paralysis Limit",
              header: ({ column }) => (
                <RenderTableHeader
                  column={column}
                  headerName="Accidental Paralysis Limit"
                  headerKey="ivf_accidental_paralysis_benefit"
                />
              ),
              accessorKey: "ivf_accidental_paralysis_benefit",
              cell: (info) => (
                <RenderCellInfo text={`$${comma_separated(info.getValue())}`} />
              ),
              footer: (props) => props.column.id,
            },
          ]
        : []
      : [
          {
            id: "Premium (Surrogate age 41-45)",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Premium (Surrogate age 41-45)"
                headerKey="sad_premium_above_40"
              />
            ),
            accessorKey: "sad_premium_above_40",
            cell: (info) => (
              <RenderCellInfo
                text={`$${comma_separated(info.getValue())}${
                  selectedPlan == COVERAGE_ONLY && lookups?.sad_policy_benefit
                    ? ` / $${comma_separated(lookups?.sad_policy_benefit)}`
                    : ""
                }`}
              />
            ),
            footer: (props) => props.column.id,
          },
        ]),
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : dataList ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}
    </>
  );
};

export { TableData };
