/*
 * IVF Terms and Conditions
 */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Checkbox,
  Typography,
  FormControlLabel,
  Box,
  Button,
} from "@mui/material";

import { IVFActionButtons } from "../components/ActionButtons";
import { theme } from "../../../../styles/theme";
import DownloadIcon from "../../../../layouts/images/download.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IvfApis } from "../../../../api";
import { formattedMDYDate } from "../../../../utils/CommonUtils";

const IVFTermsAndConditions = ({ setActiveStep, parentId }) => {
  const { id: application_id } = useParams();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isChecked, setIsChecked] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const termsData = useSelector((state) => state.ivfReducer.ivfTermCondition);
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);

  const saveTermConditions = async () => {
    setButtonLoading(true);
    const result = await IvfApis.syncApplication({
      parent_id: parentId,
      params: { agreed_term_id: termsData?.id },
    });
    setButtonLoading(false);
    result?.success && setActiveStep(2);
  };

  const saveTermConditionsId = async () => {
    setButtonLoading(true);
    const result = await IvfApis.updateApplication({
      application_id: application_id,
      params: { agreed_term_id: termsData?.id },
    });
    setButtonLoading(false);
    result?.success && setActiveStep(2);
  };

  // Add line breaks in data from API
  const formatApiData = () => {
    const formattedData = termsData?.content
      ?.split("\n")
      ?.map((line, index) =>
        line?.trim() ? (
          <React.Fragment key={index}>
            <p className="mb-1">{line}</p>
          </React.Fragment>
        ) : null
      )
      .filter(Boolean);
    return <>{formattedData}</>;
  };

  const downloadFile = async (pdfFilePath) => {
    try {
      // Fetching the PDF file as a blob
      const response = await fetch(process.env.PUBLIC_URL + pdfFilePath);
      const blob = await response.blob();

      // Creating a download link and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "sample_certificate.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  // Function to render the download button with specified label and file path
  const renderDownloadButton = (label, filePath) => (
    <Button
      variant="outlined"
      color="primary"
      disableElevation
      size="large"
      sx={{
        borderRadius: "25px",
        color: theme.mistBlue,
        whiteSpace: "nowrap",
        border: `2px ${theme.grey} solid`,
        marginY: "0.5rem",
        marginX: "0.5rem",
        "&:hover": { borderWidth: "2px" },
      }}
      onClick={() => downloadFile(filePath)}
      startIcon={
        <img
          src={DownloadIcon}
          alt=""
          style={{ width: "24px", height: "24px" }}
        />
      }
    >
      {label}
    </Button>
  );

  // Set to keep track of rendered coverage types
  const renderedCoverageTypes = new Set();

  return (
    <>
      {/* Page content */}

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              fontSize: "0.8rem",
              lineHeight: "1.5rem",
              marginBottom: "0.8rem",
            }}
          >
            {formatApiData()}
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Additional Warranty
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControlLabel
            sx={{ alignItems: "flex-start" }}
            control={
              <Checkbox
                checked={isChecked.checkbox1}
                disabled={!termsData}
                sx={{ paddingY: "0.1rem" }}
                onChange={() =>
                  setIsChecked({
                    ...isChecked,
                    checkbox1: !isChecked.checkbox1,
                  })
                }
              />
            }
            label={
              <>
                <Typography component={"span"}>
                  By checking this box applicant confirms there have been no
                  complications to date:&nbsp;
                  <Typography component={"span"}>
                    <strong>{formattedMDYDate()}</strong>
                  </Typography>
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControlLabel
            sx={{ alignItems: "flex-start" }}
            control={
              <Checkbox
                checked={isChecked.checkbox2}
                disabled={!termsData}
                sx={{ paddingY: "0.1rem" }}
                onChange={() =>
                  setIsChecked({
                    ...isChecked,
                    checkbox2: !isChecked.checkbox2,
                  })
                }
              />
            }
            label={
              <>
                <Typography component={"span"}>
                  Applicant agrees to ALL stated terms, conditions and
                  warranties as of this date:&nbsp;
                  <Typography component={"span"}>
                    <strong>{formattedMDYDate()}</strong>
                  </Typography>
                </Typography>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            {summaryData?.map((item, index) => {
              // Skip rendering if the coverage type has already been rendered
              if (renderedCoverageTypes.has(item.coverage_type)) {
                return null;
              }
              // Add the coverage type to the set to track rendered types
              renderedCoverageTypes.add(item.coverage_type);

              return (
                <React.Fragment key={index}>
                  {item.coverage_type === "EDR" &&
                    renderDownloadButton(
                      "Download Donor & Recipient Certificate",
                      "/IVF_SAMPLE_DONOR_RECIPIENT.pdf"
                    )}
                  {item.coverage_type === "EDO" &&
                    renderDownloadButton(
                      "Download Donor Certificate",
                      "/IVF_SAMPLE_DONOR.pdf"
                    )}
                  {item.coverage_type === "RO" &&
                    renderDownloadButton(
                      "Download Recipient Certificate",
                      "/IVF_SAMPLE_RECIPIENT.pdf"
                    )}
                </React.Fragment>
              );
            })}
          </Box>
          <FormControlLabel
            sx={{ alignItems: "flex-start" }}
            control={
              <Checkbox
                checked={isChecked.checkbox3}
                disabled={!termsData}
                sx={{ paddingY: "0.1rem" }}
                onChange={() =>
                  setIsChecked({
                    ...isChecked,
                    checkbox3: !isChecked.checkbox3,
                  })
                }
              />
            }
            label="I acknowledge that the sample certificate(s) was available for me to download."
          />
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <IVFActionButtons
        onPressBack={() => setActiveStep(0)}
        disableBack={buttonLoading}
        // OnSaveExit={() => navigate(-1)}
        OnPressContinue={() => {
          if (application_id) {
            saveTermConditionsId();
          } else {
            saveTermConditions();
          }
        }}
        loading={buttonLoading}
        disableContinue={
          Object.values(isChecked).some((val) => val === false) || buttonLoading
        }
      />
    </>
  );
};

export { IVFTermsAndConditions };
