/*
 * IVF Clinics Table header page
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dropdown, GridActionButtons, TextInput } from "../../../components";
import { CommonApis } from "../../../api";
import { constructStateList } from "../../../utils/CommonUtils";

const TableHeader = ({
  searchText,
  setSearchText,
  setPage,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  country,
  setCountry,
  state,
  setState,
  clinicStatus,
  viewDeleted,
  setSearchParams,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    !!country && getStateList();
  }, [country]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({ country_code: country });
    const getStates = constructStateList(resp);
    setStateList(getStates ?? []);
  };

  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchParams({
      page: 1,
      country_code: country,
      state_code: state,
      name: searchText,
      is_active: clinicStatus,
      only_deleted: viewDeleted,
    });
    setSearchButton(true);
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(true);
    setSearchText(" ");
    setPage(1);
    setCountry("US");
    setState(null);
    setSearchParams({ page: 1, is_active: clinicStatus });
  };

  return (
    <form>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "0.3rem", marginBottom: "1.5rem" }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Dropdown
            size="small"
            label="Country"
            id="country"
            options={lookups?.countries_list || []}
            selectValue={country}
            required={false}
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
              setState(null);
              setSearchButton(false);
            }}
            sx={{ backgroundColor: "white" }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Dropdown
            size="small"
            label="State"
            id="state"
            options={stateList || []}
            selectValue={state}
            required={false}
            value={state}
            onChange={(e) => {
              setState(e.target.value);
              setSearchButton(false);
            }}
            sx={{ backgroundColor: "white" }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextInput
            inputKey="search"
            inputLabel="Search"
            placeholder="Search by Keywords"
            autoComplete="off"
            required={false}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSearchButton(false);
            }}
            sx={{ height: "2.5rem" }}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GridActionButtons
            onSearch={searchValues}
            onClear={onClear}
            searchButtonLoading={buttonLoading == "search"}
            clearButtonLoading={buttonLoading == "clear"}
            wrapperStyle={{ marginTop: "1rem" }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { TableHeader };
