/*
 * SAD Payment
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Divider,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
} from "@mui/material";

import { SadActionButtons } from "../components/ActionButtons";
import {
  formattedMDYDate,
  comma_separated,
} from "../../../../utils/CommonUtils";
import { CommonModal, Tooltip } from "../../../../components";
import { AllRoutes } from "../../../../routes";
import { PaymentApis } from "../../../../api";
import { PaymentTypes } from "../../constants";
import { INSURANCE_TYPES } from "../../../../constants";

const Payment = ({ setLoading }) => {
  const navigate = useNavigate();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const [payNowLater, setPayNowLater] = useState(true);
  const [paymentList, setPaymentList] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const { credit_card_charges } = lookups ?? {};
  const {
    application_number,
    indemnity_surrogates_beneficiary,
    indemnity_intended_parents,
    financial_email,
    intended_parent1_email,
    invoice_id,
    invoice_billto_name,
    invoice_billto_address,
    invoice_line_items,
    invoice_payment_credits,
    invoice_total,
  } = summaryData ?? {};

  const billingOptions = [
    {
      value: invoice_billto_name || "-",
      label: "Name",
    },
    {
      value: invoice_billto_address || "-",
      label: "Address",
    },
    {
      value: `${intended_parent1_email}`,
      label: "Email",
    },
    !!financial_email &&
      financial_email !== intended_parent1_email && {
        value: `${financial_email.replace(/;/g, ", ")}`,
        label: "Additional Email(s)",
      },
    { value: formattedMDYDate(), label: "Date of Application" },
    { value: application_number, label: "Policy Number" },
    {
      value: `$${comma_separated(
        (indemnity_surrogates_beneficiary ?? 0) +
          (indemnity_intended_parents ?? 0)
      )}`,
      label: "Total Combined Limits of Indemnity",
    },
  ];

  useEffect(() => {
    setLoading(true);
    !!invoice_line_items &&
      setPaymentList(
        payNowLater
          ? [
              ...invoice_line_items,
              {
                label: "Administrative Fee",
                description: "Non refundable fee",
                total: (
                  Number(invoice_total * credit_card_charges || 0) / 100
                )?.toFixed(2),
              },
            ]
          : invoice_line_items
      );
    !!invoice_line_items && invoice_line_items?.length > 0 && setLoading(false);
  }, [invoice_line_items, payNowLater, credit_card_charges]);

  const createPayment = async () => {
    setButtonLoading(true);
    let payload = { invoice_id, insurance_type: INSURANCE_TYPES.sad };
    if (!payNowLater) {
      payload = { ...payload, payment_mode: PaymentTypes.offline };
    }
    try {
      const resp = await PaymentApis.createPayment(payload);
      !!resp && setButtonLoading(false);
      payNowLater && !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const DividerLine = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider aria-hidden="true" />
        </Grid>
      </Grid>
    );
  };

  const BillingTo = ({ options = [] }) => {
    return (
      <Grid container>
        {!!options &&
          options?.map((option) => (
            <>
              <Grid item xl={6} lg={5} md={4} sm={5} xs={5}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "2rem",
                    paddingLeft: "25px",
                  }}
                >
                  {option?.label ? `${option?.label}:` : ""}
                </Typography>
              </Grid>
              <Grid item xl={6} lg={7} md={8} sm={7} xs={7}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    lineHeight: "2rem",
                    textAlign: "right",
                  }}
                >
                  {option.value}
                </Typography>
              </Grid>
            </>
          ))}
      </Grid>
    );
  };

  const OrderSummary = ({ options = [] }) => {
    return (
      <Grid container>
        {!!options &&
          options?.map((option) => (
            <>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6} mb="0.5rem">
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "1.5rem",
                    paddingLeft: "25px",
                  }}
                >
                  {option?.label}:
                </Typography>
                {!!option?.description && (
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      paddingLeft: "25px",
                      my: "0.5rem",
                    }}
                  >
                    {option?.description}
                  </Typography>
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    lineHeight: "2rem",
                    textAlign: "right",
                  }}
                >
                  ${option?.total}
                </Typography>
              </Grid>
            </>
          ))}
      </Grid>
    );
  };

  return (
    <>
      {/* Page content */}
      <Grid container columnSpacing={3} rowSpacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          xl={6}
          lg={6}
          // sx={{ border: "2px solid red" }}
        >
          {/* <Grid container> */}
          <Typography
            component="h2"
            sx={{
              fontSize: "1.05em",
              fontWeight: "bold",
              lineHeight: "3rem",
              bgcolor: "#E7EAEC",
              paddingLeft: "20px",
            }}
          >
            Billing To
          </Typography>
          {/* </Grid> */}
          <Grid container sx={{ paddingY: 3 }}>
            <BillingTo options={billingOptions} />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          {/* <Grid container> */}
          <Typography
            component="h2"
            sx={{
              fontSize: "1.05em",
              fontWeight: "bold",
              lineHeight: "3rem",
              bgcolor: "#E7EAEC",
              paddingLeft: "20px",
            }}
          >
            Order Summary
          </Typography>
          <Grid container sx={{ paddingY: 3 }}>
            <OrderSummary options={[...paymentList]} />
            <DividerLine />
            <DividerLine />
            <OrderSummary
              options={[
                {
                  total: payNowLater
                    ? (
                        Number(invoice_total) +
                        Number((invoice_total * credit_card_charges) / 100)
                      )?.toFixed(2) ?? "0.00"
                    : invoice_total ?? "0.00",
                  label: <b>{"Total"}</b>,
                },
              ]}
            />
            <DividerLine />
            <DividerLine />
            <OrderSummary
              options={[
                {
                  total: invoice_payment_credits || "0.00",
                  label: <b>{"Payment Made"}</b>,
                },
                {
                  total: payNowLater
                    ? (
                        Number(invoice_total) +
                        Number((invoice_total * credit_card_charges) / 100)
                      )?.toFixed(2) ?? "0.00"
                    : invoice_total ?? "0.00",
                  label: <b> {"Balance Due"}</b>,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      <DividerLine />
      <DividerLine />

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            component="h2"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginY: "1.5rem",
            }}
          >
            Payment
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <FormLabel
              id="payment-options"
              sx={{
                color: "black",
                "&.Mui-focused": {
                  color: "black",
                },
              }}
            >
              Select a Payment Options and Continue
            </FormLabel>
            <RadioGroup
              sx={{ alignItems: "center", justifyContent: "space-around" }}
              row
              aria-labelledby="payment-options"
              name="payment-options-group"
            >
              <FormControlLabel
                control={<Radio />}
                label="Pay Now"
                checked={!!payNowLater}
                value={!!payNowLater}
                onChange={() => setPayNowLater(true)}
              />
              <Tooltip
                placement="bottom"
                tooltip={`Online payments will include a ${
                  credit_card_charges || 0
                }% administrative fee`}
              />
              <FormControlLabel
                control={<Radio />}
                label="Pay Later"
                disabled={buttonLoading}
                checked={!payNowLater}
                value={!payNowLater}
                onChange={() => setPayNowLater(false)}
                sx={{ marginLeft: "1rem" }}
              />
              <Tooltip
                placement="bottom"
                tooltip="You have 45 days to complete your payment"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <SadActionButtons
        OnPressContinue={() => {
          createPayment();
          !payNowLater && setUpdateModal(true);
        }}
        buttonLoading={buttonLoading}
      />

      {/* Modal when user proceed with pay-later option */}
      <CommonModal
        open={updateModal}
        type="success"
        onCancel={() => {
          setUpdateModal(false);
          setTimeout(() => {
            document.getElementById("continue-button")?.focus();
          }, 700);
        }}
        onConfirm={() => navigate(AllRoutes.SadPoliciesPage.route)}
        title="Application Submitted!"
        subTitle="Your Application has been successfully submitted. Payment is required for your policy to be effective. Payment is due by the date on your invoice. Failure to receive prompt payment can affect your eligibility and/or effective date of your policy. If Payment is not received by the due date on your invoice, your application will be canceled and voided with or without notice given by New Life Agency, Inc. "
        subTitle2="Thank you for applying!"
      />
    </>
  );
};

export { Payment };
