/*
 * Signup page
 */

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import {
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AuthLayout } from "../../layouts/AuthLayout";
import { theme } from "../../styles/theme";
import { AllRoutes } from "../../routes";
import { AuthApis } from "../../api";
import PasswordChecklist from "react-password-checklist";
import {
  Button,
  Dropdown,
  ErrorComponent,
  MandatoryStatement,
  PhoneInput,
  Stepper,
  TextInput,
} from "../../components";
import { focusFirstErrorField } from "../../utils/CommonUtils";
import { CAPTCHA_KEY } from "../../constants";

// All constants
const steps = ["User Info", "Verify Your Email", "Create Password"];
const otpResent = "OTP Resent Successfully!";
const invalidToken = "Invalid token provided";
const checkEmail = "Please check your email for further Instructions.";

const signupInitialValues = {
  first_name: "",
  last_name: "",
  mobile: "",
  email: "",
  role: null,
};
const otpInitialValues = {
  otp: "",
};
const passwordInitialValues = {
  password: "",
  confirm_password: "",
};
const signupValidationSchema = {
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile: Yup.string()
    .min(7, "Contact No. must be at least 7 digits")
    .required("Contact number is required"),
  email: Yup.string().required("Email is required"),
  role: Yup.string().required("Role is required"),
};
const otpValidationSchema = {
  otp: Yup.string()
    .min(6, "Please enter 6 digit OTP")
    .required("OTP is required"),
};
const passwordValidationSchema = {
  password: Yup.string()
    .min(8, "Password must have at least 8 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Confirm password is required"),
};

export const SignUpPage = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const broker_code = params.get("broker_code");
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [activeStep, setActiveStep] = useState(0);
  const [isChecked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailToken, setEmailToken] = useState("");
  const [signupError, setSignupError] = useState("");
  const [isCaptchaError, setCaptchaError] = useState("");
  const [captchaKey, setCaptchaKey] = useState(new Date());
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOtpResending, setOtpResending] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [isPasswordValid, setPasswordValid] = useState(false);
  const firstInputRef = useRef(null);

  const loginPageUrl = broker_code
    ? `${AllRoutes.Login.route}?broker_code=${broker_code}`
    : AllRoutes.Login.route;

  useEffect(() => {
    // Function to check if the device is mobile
    const checkIsMobile = () => {
      const isMobileDevice = window.innerWidth <= 768; // Adjust the breakpoint as needed
      setIsMobile(isMobileDevice);
    };

    // Add event listener for window resize
    window.addEventListener("resize", checkIsMobile);

    // Check initial device type
    checkIsMobile();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const onPressBack = () => {
    setSignupError("");
    activeStep === 0 ? navigate(loginPageUrl) : setActiveStep(activeStep - 1);
    activeStep === 1 && formik.setFieldValue("captcha_response", "");
    activeStep === 2 && setPasswordValid(false);
  };

  const handleError = async () => {
    setSignupError(checkEmail);
  };

  // Get formik values and validations based on signup page
  const getFormValuesAndValidations = (type) => {
    if (type === "values") {
      if (activeStep === 0) return signupInitialValues;
      else if (activeStep === 1) return otpInitialValues;
      else if (activeStep === 2) return passwordInitialValues;
    } else if (type === "validations") {
      if (activeStep === 0) return signupValidationSchema;
      else if (activeStep === 1) return otpValidationSchema;
      else if (activeStep === 2) return passwordValidationSchema;
    }
  };

  // Formik initialization
  const formik = useFormik({
    initialValues: getFormValuesAndValidations("values"),
    validationSchema: Yup.object(getFormValuesAndValidations("validations")),
    validateOnMount: true,
    onSubmit: async () => {
      const {
        first_name,
        last_name,
        mobile,
        email,
        role,
        captcha_response,
        otp,
        password,
        confirm_password,
      } = formik.values;

      if (activeStep === 0 && !formik.values.captcha_response) {
        setCaptchaError("Please verify captcha");
        return;
      }

      setSignupError("");
      setCaptchaError("");
      setLoading(true);

      if (activeStep === 0) {
        const resp = await AuthApis.createUser({
          first_name,
          last_name,
          mobile,
          email: email?.toLowerCase(),
          role,
          captcha_response,
          verify_token: emailToken,
          broker_code,
        });
        resp && setLoading(false);
        setCaptchaKey(new Date());
        if (resp?.success) {
          setActiveStep(1);
          setEmailToken(resp?.data?.verify_token);
        } else {
          setSignupError(resp?.errors?.captcha_response ?? resp?.errors?.email);
          firstInputRef.current && firstInputRef.current.focus(); // Set focus on first input
        }
      } else if (activeStep === 1) {
        const resp = await AuthApis.verifyEmail({
          token: emailToken,
          params: { otp },
        });
        resp && setLoading(false);
        resp?.success
          ? setActiveStep(2)
          : setSignupError(resp?.errors?.otp ?? resp?.errors?.message);
        firstInputRef.current && firstInputRef.current.focus(); // Set focus on first input
      } else if (activeStep === 2) {
        const resp = await AuthApis.verifyEmail({
          token: emailToken,
          params: { otp, password, confirm_password },
        });
        resp && setLoading(false);
        if (resp?.success) {
          window.location.href = AllRoutes.Profile.route;
          setActiveStep(2);
        } else {
          setSignupError(
            resp?.errors?.otp ?? resp?.errors?.password ?? resp?.errors?.message
          );
          firstInputRef.current && firstInputRef.current.focus(); // Set focus on first input
        }
      }
    },
  });

  const resendOtp = async () => {
    setOtpResending(true);
    const resp = await AuthApis.verifyEmail({
      token: emailToken,
      params: { notify: true },
    });
    !!resp && setOtpResending(false);

    if (resp?.success) {
      setSignupError(otpResent);
    } else {
      resp?.errors?.verify_token === invalidToken
        ? handleError()
        : setSignupError(resp?.errors?.global);
    }
  };

  // Update mobile number format
  const handlePhoneChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    focusFirstErrorField(formik);
    formik.handleSubmit(e);
  };

  return (
    <AuthLayout>
      {signupError !== checkEmail ? (
        <>
          <div className="stepper-container mb-2">
            <Typography
              component="h1"
              variant="h1"
              sx={{
                textAlign: "center",
                marginBottom: "2.5rem",
                marginTop: "0.5rem",
              }}
            >
              Create User Account
            </Typography>

            {/* Step indicator */}
            <Stepper
              activeStep={activeStep}
              steps={steps}
              stepperWidth={`${isMobile ? "100%" : "52vw"}`}
            />
          </div>

          {!!signupError && signupError !== checkEmail && (
            <ErrorComponent
              success={signupError == otpResent}
              message={signupError}
              className={isMobile ? "w-90" : "w-100"}
            />
          )}

          <Formik>
            <Form
              onSubmit={(e) => handleSubmitForm(e)}
              className={`mt-1 ${isMobile ? "w-90" : "w-100"}`}
            >
              <MandatoryStatement singleInput={activeStep == 1} />
              {/* First page content */}
              {activeStep === 0 && (
                <>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextInput
                        inputKey="first_name"
                        inputLabel="First Name"
                        autoComplete="given-name"
                        disabled={loading}
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 50 }}
                        isError={
                          formik.touched.first_name && formik.errors.first_name
                        }
                        errorMsg={formik.errors.first_name}
                        sx={{
                          marginRight: { xs: 0, sm: 0, md: "1rem", lg: "1rem" },
                        }}
                        isAuthPage={true}
                        inputRef={firstInputRef}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextInput
                        inputKey="last_name"
                        inputLabel="Last Name"
                        autoComplete="family-name"
                        disabled={loading}
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: 50 }}
                        isError={
                          formik.touched.last_name && formik.errors.last_name
                        }
                        errorMsg={formik.errors.last_name}
                        isAuthPage={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <PhoneInput
                        inputKey="mobile"
                        inputLabel="Contact No."
                        autoComplete="tel-national"
                        disabled={loading}
                        value={formik.values.mobile}
                        onBlur={formik.handleBlur}
                        registerChange={handlePhoneChange}
                        isError={formik.touched.mobile && formik.errors.mobile}
                        errorMsg={formik.errors.mobile}
                        sx={{
                          marginRight: { xs: 0, sm: 0, md: "1rem", lg: "1rem" },
                        }}
                        isAuthPage={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextInput
                        inputKey="email"
                        inputLabel="Email"
                        autoComplete="email"
                        disabled={loading}
                        value={formik.values.email}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setSignupError("");
                        }}
                        onBlur={formik.handleBlur}
                        isError={formik.touched.email && formik.errors.email}
                        errorMsg={formik.errors.email}
                        isAuthPage={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Dropdown
                        label="Your role in the Submission process?"
                        id="role"
                        disabled={loading}
                        options={lookups?.user_types_list ?? []}
                        selectValue="id"
                        selectLabel="name"
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isError={formik.touched.role && formik.errors.role}
                        errorMsg={formik.errors.role}
                        helperText="Register as an Agency or IVF Clinic if you are an organization, or as the Intended Parent if you are not an organization."
                        sx={{
                          marginRight: { xs: 0, sm: 0, md: "1rem", lg: "1rem" },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      sx={{
                        marginTop: { xs: 0, sm: 0, md: "1rem", lg: "1rem" },
                      }}
                    >
                      <ReCAPTCHA
                        key={captchaKey}
                        sitekey={CAPTCHA_KEY}
                        onChange={(v) => {
                          formik.setFieldValue("captcha_response", v);
                          setCaptchaError("");
                        }}
                      />
                      {!!isCaptchaError && (
                        <FormHelperText
                          error
                          sx={{
                            marginLeft: 0,
                            height: { xs: "auto", sm: 19.92 },
                          }}
                          id={"error-captcha_response"}
                          role="alert"
                        >
                          {isCaptchaError}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Second page content */}
              {activeStep === 1 && (
                <>
                  <div>
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: theme.grey,
                      }}
                    >
                      Please enter 6 digit OTP sent to
                      <Typography
                        sx={{
                          marginLeft: "0.4rem",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          color: "#000",
                        }}
                        component="span"
                      >
                        {formik.values.email}
                      </Typography>
                    </Typography>
                  </div>
                  <TextInput
                    formControlStyle={{
                      marginTop: "1.5rem",
                      marginBottom: "0.5rem",
                    }}
                    inputKey="otp"
                    inputLabel="OTP"
                    maxLength={6}
                    disabled={loading || isOtpResending}
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      formik.values.otp?.toString()?.length === 6 && (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <CheckCircleIcon sx={{ color: theme.green }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    isError={formik.errors.otp}
                    errorMsg={formik.errors.otp}
                    isAuthPage={true}
                    inputRef={firstInputRef}
                    inputProps={{
                      style: { textTransform: "uppercase" },
                      maxLength: 6,
                    }}
                  />
                  <div className="d-flex align-center">
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: theme.grey,
                      }}
                    >
                      <span>Didn’t receive the OTP?&nbsp;</span>
                    </Typography>
                    <Button
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        padding: 0,
                        borderRadius: "0px",
                        minHeight: null,
                        minWidth: null,
                        cursor: loading ? "default" : "pointer",
                        "&.Mui-disabled": { color: theme.primaryFade },
                      }}
                      title="Resend OTP"
                      disabled={isOtpResending || loading}
                      onClick={resendOtp}
                    />
                  </div>
                </>
              )}

              {/* Third page content */}
              {activeStep === 2 && (
                <>
                  <TextInput
                    inputKey="password"
                    inputLabel="Password"
                    type={showPassword ? "text" : "password"}
                    disabled={loading}
                    value={formik.values.password}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setPassword(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          disabled={loading}
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    isError={formik.errors.password}
                    errorMsg={formik.errors.password}
                    isAuthPage={true}
                    helperText="Note: Password must be having at least 1 uppercase, 1 lowercase, 1 digit, 1 special (#,?,!,@,$,%,^,&,*,-) and minimum 8 characters."
                    inputRef={firstInputRef}
                  />
                  <TextInput
                    inputKey="confirm_password"
                    inputLabel="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    disabled={loading}
                    value={formik.values.confirm_password}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setPasswordAgain(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          disabled={loading}
                          onClick={handleShowConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    isError={formik.errors.confirm_password}
                    errorMsg={formik.errors.confirm_password}
                    isAuthPage={true}
                  />
                  <Box
                    sx={{
                      marginBottom: "0.6rem",
                      "& ul": { fontSize: "0.8rem" },
                    }}
                    aria-hidden={true}
                  >
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                      ]}
                      minLength={8}
                      value={password}
                      valueAgain={passwordAgain}
                      onChange={(isValid) => setPasswordValid(isValid)}
                      validTextColor="#157347"
                      validColor="#157347"
                      invalidTextColor="#d32f2f"
                      invalidColor="#d32f2f"
                      iconSize="12.8"
                    />
                  </Box>
                  <div className="d-flex align-center">
                    <Checkbox
                      disabled={loading}
                      defaultChecked={isChecked}
                      value={isChecked}
                      onChange={() => setChecked(!isChecked)}
                      inputProps={{ "aria-labelledby": "chkAgreeStatement" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                        color: theme.grey,
                      }}
                      id="chkAgreeStatement"
                    >
                      <span className="fontRegular">I agree to the </span>
                      <Link
                        to={!loading && "/privacy-policy"}
                        target="_blank"
                        className="text-primary"
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                  </div>
                </>
              )}

              {/* Common action buttons for all 3 pages */}
              <div className="d-flex">
                <Button
                  variant="outlined"
                  fullWidth={true}
                  size="large"
                  title="Back"
                  disabled={loading || isOtpResending}
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "1.5rem",
                    marginRight: "2rem",
                    background: theme.white,
                    border: `2px solid ${theme.primaryDark}`,
                    borderColor: theme.primary,
                    borderWidth: 2,
                  }}
                  onClick={onPressBack}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth={true}
                  disabled={
                    loading ||
                    isOtpResending ||
                    (activeStep == 2 && (!isChecked || !isPasswordValid))
                  }
                  type="submit"
                  size="large"
                  title={activeStep === 2 ? "Register" : "Continue"}
                  loading={loading}
                  sx={{
                    marginBottom: "1rem",
                    marginTop: "1.5rem",
                  }}
                  onClick={() => {
                    if (activeStep == 0 && formik.values.otp)
                      formik.values.otp = "";
                    if (activeStep == 1 && formik.values.password)
                      formik.values.password = "";
                    if (activeStep == 1 && formik.values.confirm_password)
                      formik.values.confirm_password = "";
                  }}
                />
              </div>
            </Form>
          </Formik>
        </>
      ) : (
        <>
          <Typography
            variant="body1"
            color="initial"
            sx={{ textAlign: "center", marginY: "1rem" }}
          >
            {`Your registration session has expired. You can now set or reset your
            password by clicking  `}
            <Link to="/forgot-password" className="text-primary">
              here
            </Link>
            {`. Enter your registered email, and we will send you an email
            containing a link to reset your password. Thank you.`}
          </Typography>
        </>
      )}
    </AuthLayout>
  );
};
