/*
 * Common component to add agency details in any form
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

import { Dropdown } from "./Dropdown";
import { AutoComplete } from "./AutoComplete";
import { AgencyApis, CommonApis } from "../api";
import { AddNewAgency } from "../pages/sad/components/AddNewAgency";
import { USER_ROLE, USER_ROLES } from "../constants";
import { LocalStorageHelper } from "../utils/HttpUtils";
import { CommonModal } from "./CommonModal";
import { constructStateList } from "../utils/CommonUtils";

const { agency, clinic } = USER_ROLES;

const AgencyDetails = ({
  disabled = false,
  userData = {},
  register,
  setValue,
  watch,
  getValues,
  loading,
  setLoading,
  errors,
  setError,
  isRoleAgency = false,
  isRoleClinic = false,
  setWorkingWithAgency,
}) => {
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) === agency;
  const isUserClinic = LocalStorageHelper.get(USER_ROLE) === clinic;
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [agencyList, setAgencyList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isBlackListedAgency, setBlackListedAgency] = useState(false);
  const [showAgencyChangePopup, setShowAgencyChangePopup] = useState(false);
  const [repeatAgencyChangePopup, setRepeatAgencyChangePopup] = useState(true);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isNewAgencyAdded, setNewAgencyAdded] = useState(false);
  const [showAddNewAgency, setShowAddNewAgency] = useState(false);
  const { yes_no_list, countries_list } = lookups ?? {};
  const isOrgAdmin = userData?.is_agency_admin;

  // Get agency list based on state
  useEffect(() => {
    !!watch("agency_state_code") && getAgencyList();
  }, [watch("agency_state_code"), isNewAgencyAdded]);

  // Get state list based on country for agencies
  useEffect(() => {
    !!watch("agency_country_code") && getStateList();
  }, [watch("agency_country_code")]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({
      country_code: watch("agency_country_code"),
    });
    const getStates = constructStateList(resp);
    setStateList(getStates);
  };

  const getAgencyList = async () => {
    setLoading(true);
    const resp = await AgencyApis.getAgenciesList({
      country_code: watch("agency_country_code"),
      state_code: watch("agency_state_code"),
    });
    !!resp && setLoading(false);
    setAgencyList(resp);
  };

  return (
    <>
      <FormControl sx={{ marginBottom: "1rem" }}>
        <FormLabel
          sx={{
            fontSize: "0.9rem",
            color: "black",
            "&.Mui-focused": {
              color: "black",
            },
          }}
        >
          {isUserAgency || isRoleAgency
            ? "Which agency you are associated with?"
            : isUserClinic || isRoleClinic
            ? "Are you working with an Agency?"
            : ""}
          <span className="text-danger" style={{ marginLeft: "0.2rem" }}>
            *&nbsp;
          </span>
        </FormLabel>
        {!isUserAgency && !isRoleAgency && (
          <>
            <RadioGroup row={true} value={watch("working_with_agency")}>
              {yes_no_list?.map((item) => (
                <FormControlLabel
                  key={item.value}
                  sx={{ marginRight: "4rem", fontSize: "2" }}
                  componentsProps={{ typography: { variant: "body2" } }}
                  control={<Radio size="small" />}
                  disabled={disabled}
                  value={item.value}
                  label={item.label}
                  checked={watch("working_with_agency") == item.value}
                  onChange={() => {
                    setValue("working_with_agency", item.value);
                    setError("working_with_agency", null);
                    setError("agency_country_code", null);
                    setError("agency_state_code", null);
                    setError("agency_name", null);
                    setWorkingWithAgency(item.value);
                  }}
                />
              ))}
            </RadioGroup>
            {errors?.working_with_agency && (
              <FormHelperText
                error
                sx={{
                  whiteSpace: "nowrap",
                  marginLeft: 0,
                  fontSize: "0.77rem",
                  letterSpacing: 0,
                  position: "absolute",
                  marginTop: 7,
                }}
              >
                {errors?.working_with_agency?.message}
              </FormHelperText>
            )}
          </>
        )}
      </FormControl>
      <Grid
        container
        columnSpacing={3}
        rowSpacing={2}
        sx={{
          display: !watch("working_with_agency") && "none",
        }}
      >
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Dropdown
            label="Country"
            id="agency_country_code"
            options={countries_list}
            disabled={disabled}
            register={register}
            value={watch("agency_country_code")}
            registerChange={(e) => {
              setValue("agency_state_code", null);
              setValue("agency_name", null);
            }}
            isError={errors?.agency_country_code}
            errorMsg={errors?.agency_country_code?.message}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Dropdown
            label="State"
            id="agency_state_code"
            disabled={disabled || !watch("agency_country_code")}
            options={stateList}
            value={watch("agency_state_code")}
            register={register}
            registerChange={(e) => {
              setValue("agency_name", null);
            }}
            isError={errors?.agency_state_code}
            errorMsg={errors?.agency_state_code?.message}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <AutoComplete
            {...register("agency_name")}
            label="Agency Name"
            id="agency_name"
            placeholder="Start typing agency name..."
            disabled={disabled || !watch("agency_state_code")}
            options={agencyList ?? []}
            optionLabel={(option) =>
              option?.name || getValues("agency_name") || ""
            }
            setShowAddNewAgency={setShowAddNewAgency}
            buttonText="Add New Agency"
            onInputChange={(_, value) => setValue(`agency_name`, value)}
            loading={loading}
            value={getValues("agency_name")}
            onChange={(data) => {
              if (isOrgAdmin && repeatAgencyChangePopup) {
                setShowAgencyChangePopup(true);
                setSelectedAgency(data);
              }
              if (!isOrgAdmin || !repeatAgencyChangePopup) {
                if (data?.is_active === -1) {
                  setBlackListedAgency(true);
                  setValue("agency_id", null);
                  setValue("agency_name", null);
                } else {
                  setValue("agency_id", data?.id);
                  setValue("agency_name", data?.name);
                  setError("agency_name", null);
                }
              }
            }}
            isError={errors?.agency_name}
            errorMsg={errors?.agency_name?.message}
          />
        </Grid>
      </Grid>

      {showAddNewAgency && (
        <AddNewAgency
          open={showAddNewAgency}
          onClose={() => setShowAddNewAgency(false)}
          setLoading={setLoading}
          newAgencyData={({ id, country_code, state_code, name }) => {
            setNewAgencyAdded(!isNewAgencyAdded);
            setValue("agency_id", id);
            setValue("agency_country_code", country_code);
            setValue("agency_state_code", state_code);
            setValue("agency_name", name);
            setError("agency_name", null);
          }}
        />
      )}

      {/* Warning modal if BLACKlISTED agency is selected */}
      <CommonModal
        open={isBlackListedAgency}
        type="warning"
        onConfirm={() => setBlackListedAgency(false)}
        title="Please Note"
        subTitle={`We are sorry!<br />At this time, we are not accepting applications for coverage, from this agency.<br/>If you have any questions, please contact your broker.`}
      />

      {/* Warning modal if Agency admin updates his agency */}
      {!!isOrgAdmin && (
        <CommonModal
          open={showAgencyChangePopup}
          type="warning"
          onCancel={() => {
            setValue("agency_id", userData?.agency_id);
            setValue("agency_country_code", userData?.agency_country_code);
            setValue("agency_state_code", userData?.agency_state_code);
            setValue("agency_name", userData?.agency_name);
            setError("agency_name", null);
            setRepeatAgencyChangePopup(false);
            setShowAgencyChangePopup(false);
          }}
          onConfirm={() => {
            setRepeatAgencyChangePopup(false);
            setShowAgencyChangePopup(false);
            if (selectedAgency?.is_active === -1) {
              setBlackListedAgency(true);
              setValue("agency_id", null);
              setValue("agency_name", null);
            } else {
              setValue("agency_id", selectedAgency?.id);
              setValue("agency_name", selectedAgency?.name);
              setError("agency_name", null);
            }
          }}
          title="Please Note"
          subTitle="Changing agency will revoke agency admin status. Do you want to proceed?"
        />
      )}
    </>
  );
};

export { AgencyDetails };
