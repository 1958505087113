/*
 * Common MUI Accordion
 */

import React, { useLayoutEffect, useRef, useState } from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";

import { theme } from "../styles/theme";

const useStyles = makeStyles((theme) => ({
  focused: {
    outline: `2px solid ${theme.primaryDark} !important`,
    boxShadow: "inset 0 0 0 1px #fff !important",
  },
}));

const Accordion = ({
  defaultExpanded = false,
  header,
  component = "h2",
  name = "panel",
  children,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    defaultExpanded ? name + "-header" : false
  );
  const expandedAccordionRef = useRef(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useLayoutEffect(() => {
    if (!defaultExpanded && expandedAccordionRef.current) {
      const rect = expandedAccordionRef.current.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      const top = rect.top + scrollTop - 75; // Adjust the offset as needed
      setTimeout(() => {
        window.scrollTo({ top, behavior: "smooth" });
      }, 500);
    }
  }, [expanded, defaultExpanded]);

  return (
    <MuiAccordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={0}
      sx={{ marginBottom: "1rem" }}
      ref={expanded === name + "-header" ? expandedAccordionRef : null}
      expanded={expanded === name + "-header"}
      onChange={handleChange(name + "-header")}
    >
      <AccordionSummary
        expandIcon={<ExpandLessIcon />}
        aria-controls={name + "-content"}
        id={name + "-header"}
        sx={{ background: theme.lightGrey, fontWeight: "700" }}
        focusVisibleClassName={classes.focused}
      >
        <Typography
          variant={component}
          component={component}
          sx={{ fontSize: "1.05em", fontWeight: "700" }}
        >
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

const HeadAccordion = ({
  defaultExpanded = false,
  header,
  children,
  total,
  subHeading,
  handleDelete,
  showDelete = true,
  showAccordion = true,
  value = "",
  component = "h2",
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleOnClick = (id) => {
    setExpanded((prev) => !prev);
    const rect = document.getElementById(id).getBoundingClientRect();
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const top = rect.top + scrollTop - 75; // Adjust the offset as needed
    window.scrollTo({ top, behavior: "smooth" });
  };

  return (
    <MuiAccordion
      defaultExpanded={defaultExpanded}
      disableGutters
      elevation={0}
      sx={{ marginBottom: "1rem" }}
    >
      {showAccordion && (
        <AccordionSummary
          onClick={() => handleOnClick(header + "-header")}
          aria-controls={header + "-content"}
          id={header + "-header"}
          sx={{ fontWeight: "700" }}
          focusVisibleClassName={classes.focused}
        >
          {/* <div style={{display:'flex' , justifyContent:"space-between",width:"100%"}} onClick={() => setExpanded(!expanded)}> */}
          {/* {header} */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "0.5rem",
            }}
          >
            <Typography
              variant={component}
              component={component}
              sx={{ fontSize: "1.05em", fontWeight: "700" }}
            >
              {header}
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "800",
                marginLeft: "auto",
              }}
            >
              {value}
            </div>
            {subHeading}&nbsp;&nbsp;
          </div>
          {/* <br />
          {subHeading} */}
          <Box sx={{ marginLeft: "auto" }}>{total}</Box>
          {expanded ? (
            <AddIcon sx={{ marginLeft: "auto", marginTop: "0.5rem" }} />
          ) : (
            <RemoveIcon sx={{ marginLeft: "auto", marginTop: "0.5rem" }} />
          )}
          {/* </div> */}
          {showDelete === true ? (
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          ) : null}
        </AccordionSummary>
      )}
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export { Accordion, HeadAccordion };
