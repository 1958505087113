/*
 * Signed Affidavit Documents  Table header page
 */

import React, { useState, useEffect } from "react";
import { theme } from "../../../styles/theme";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/styles";
import { Grid, Box } from "@mui/material";
import { BrokerApis, CommonApis } from "../../../api";
import {
  DateRangePicker,
  Dropdown,
  GridActionButtons,
} from "../../../components";
import {
  oneMonthDateRangeString,
  convertDateRangeToString,
  constructStateList,
} from "../../../utils/CommonUtils";

const TableHeader = ({
  setAdvancedSearch,
  setPage,
  searchButton,
  setSearchButton,
  buttonLoading,
  setButtonLoading,
  country,
  setCountry,
  state,
  setState,
  stateList,
  setStateList,
  broker,
  setBroker,
}) => {
  const [brokerList, setBrokerList] = useState([]);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    !!country && getStateList();
  }, [country]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({ country_code: country });
    const getStates = constructStateList(resp);
    setStateList([{ label: "All", value: "" }, ...(getStates ?? [])]);
    setState("");
  };

  const setDefaultDate = () => {
    const curDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, curDate]);
    return [oneMonthAgo, curDate];
  };

  useEffect(() => {
    setDefaultDate();
    getBrokerList();
  }, []);

  const getBrokerList = async () => {
    const params = {
      sortOrder: "",
      sortKey: "name",
    };
    const res = await BrokerApis.getBrokerList(params);
    setBrokerList(
      res?.success ? [{ label: "All", value: "" }, ...res?.data?.results] : []
    );
  };

  const setSearchObject = (key, value) => {
    setAdvancedSearch((p) => ({ ...p, [key]: value }));
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    setSearchObject("created_at", convertDateRangeToString(newDateRange));
  };
  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchButton(!searchButton);
  };

  const onClear = () => {
    setButtonLoading("clear");
    setSearchButton(!searchButton);
    setBroker("");
    setPage(1);
    setDefaultDate();
    setCountry("US");
    setState("");
    setAdvancedSearch(oneMonthDateRangeString());
  };

  return (
    <ThemeProvider theme={theme}>
      <form>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            sx={{ padding: "10px" }}
            rowSpacing={2}
            columnSpacing={3}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropdown
                size="small"
                label="Broker"
                id="broker"
                sx={{ backgroundColor: theme.white }}
                options={brokerList ?? []}
                selectLabel="name"
                selectValue="code"
                required={false}
                value={broker}
                onChange={(e) => {
                  setBroker(e.target.value);
                  setSearchObject("default_broker_code", e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} sx={{ marginTop: "1rem" }}>
              <DateRangePicker
                dateRange={dateRange}
                handleDateChange={handleDateChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropdown
                size="small"
                label="Country"
                id="country"
                options={lookups?.countries_list || []}
                selectValue={country}
                required={false}
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setSearchObject("country_code", e.target.value);
                }}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropdown
                size="small"
                label="State"
                id="state"
                options={stateList || []}
                selectValue={state}
                required={false}
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  setSearchObject("state_code", e.target.value);
                }}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
          </Grid>

          <GridActionButtons
            onSearch={searchValues}
            onClear={onClear}
            searchButtonLoading={buttonLoading == "search"}
            clearButtonLoading={buttonLoading == "clear"}
            wrapperStyle={{ marginTop: "1rem" }}
          />
        </Box>
      </form>
    </ThemeProvider>
  );
};

export { TableHeader };
