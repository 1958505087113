/*
 * Common React Table
 */

import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Menu,
  ListItemIcon,
  MenuItem,
  Button,
  Switch,
  InputAdornment,
  Typography,
  Table,
  Checkbox,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDebounce } from "../hooks";
import { DEBOUNCE_DELAY, tableNames } from "../constants";
import { Dropdown, TextInput } from "../components";
import { theme } from "../styles/theme";
import { CommonApis } from "../api";

const TableHeader = ({ headerGroups, padding }) => (
  <thead style={{ backgroundColor: "#C0580E", color: "white" }}>
    {headerGroups?.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers?.map((header) => (
          <th
            key={header.id}
            colSpan={header.colSpan}
            style={{
              padding: padding ? padding : "2px 8px",
              textAlign: "left",
              borderBottom: "1px solid white",
              fontSize: "1rem",
              fontWeight: "100",
              minWidth: "auto",
              whiteSpace: "nowrap",
            }}
            aria-sort={
              header.column.getIsSorted()
                ? header.column.getIsSorted() === "desc"
                  ? "descending"
                  : "ascending"
                : undefined
            }
          >
            {header.isPlaceholder
              ? null
              : flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

const TableRow = ({ rowModel }) => (
  <tbody>
    {rowModel.rows?.map((row, index) => (
      <React.Fragment key={row.id}>
        <tr>
          {row.getVisibleCells()?.map((cell) => (
            <td
              key={cell.id}
              style={{
                padding: "10px",
                borderBottom: "1px solid #ddd",
                fontSize: "1rem",
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
        {/* {index < rowModel.rows.length - 1 && <tr style={{ height: "10px" }} />} */}
      </React.Fragment>
    ))}
  </tbody>
);

const ReactTable = ({
  tableName = "",
  tableData,
  tableColumns,
  isColumnCustomizingRequired,
  showFilterDropdown = false,
  isUserModule = false,
  leftCheckBoxTitle = "",
  isCheckboxSelected,
  handleChangeCheckBox,
  onZipDownload,
  disableZipDownload = false,
  downloadFileLabel = "",
  page,
  setPage,
  searchText = "",
  setSearchText = () => {},
  onSearch,
  listData,
  caption,
  padding,
  filterOptions,
  secondFilterOptions,
  dataLoading,
  label,
  secondLabel,
  firstFilterValue,
  handleFirstFilterChange,
  secondFilterValue,
  handleSecondfilterChange,
  setDataFetching,
  inputTooltip,
}) => {
  const [userData, setUserData] = useState({});
  const [data, setData] = useState([...tableData]);
  const [columns, setColumns] = useState(() => [...tableColumns]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const { total = 1, total_pages = 1 } = listData ?? {};
  const [sorting, setSorting] = useState([]);
  const { ivfAdmin, sadAdmin } = tableNames;

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    setColumns(tableColumns);
  }, [tableColumns]);

  const debounceOnSearch = useDebounce(async (value) => {
    onSearch(value);
  }, DEBOUNCE_DELAY);

  const [columnOrder, setColumnOrder] = useState(() =>
    tableColumns?.map((column) => column.id)
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: isColumnCustomizingRequired
        ? columnVisibility
        : undefined,
      columnOrder: isColumnCustomizingRequired ? columnOrder : undefined,
      sorting,
    },
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    pageSize: data?.length,
  });

  useEffect(() => {
    table.setPageSize(data?.length);
  }, [table, data?.length]);

  useEffect(() => {
    if (isColumnCustomizingRequired) {
      getVisibleColumns();
    }
  }, []);

  const getVisibleColumns = async () => {
    const resp = await CommonApis.getMyProfile();
    let preferences = resp?.data?.preferences?.[tableName] ?? [];
    // If preferences are empty, set all columns as visible
    if (!resp?.data?.preferences || !resp?.data?.preferences?.[tableName]) {
      preferences = tableColumns?.map?.((col) => col.id);
    }
    const visibility = tableColumns?.reduce?.((acc, col) => {
      acc[col.id] = preferences?.includes?.(col?.id);
      return acc;
    }, {});
    setColumnVisibility(visibility);

    const colOrderPreferences =
      resp?.data?.preferences?.[`${tableName}-order`] ?? [];
    if (colOrderPreferences?.length) {
      setColumnOrder(colOrderPreferences);
    }

    setUserData(resp?.data);
  };

  const saveVisibleColumns = async () => {
    const resp = await CommonApis.getMyProfile();
    const existingPreferences = resp?.data?.preferences || {};

    const visibleColumns = table
      .getAllLeafColumns()
      ?.filter((col) => col.getIsVisible())
      ?.map((col) => col?.id);

    const updatedPreferences = {
      ...existingPreferences,
      [tableName]: visibleColumns,
      [`${tableName}-order`]: columnOrder,
    };

    const payload = { preferences: updatedPreferences };
    await CommonApis.updateMyProfile(payload);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = async () => {
    setAnchorEl(null);
    await saveVisibleColumns();
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const newColumnOrder = [...columnOrder];
    newColumnOrder.splice(sourceIndex, 1);
    newColumnOrder.splice(destinationIndex, 0, result.draggableId);
    //store new order of column for future
    setColumnOrder(newColumnOrder);
  };

  const DataLoader = () => {
    return (
      <CircularProgress
        size={28}
        sx={{
          textAlign: "left",
          mt: "3rem",
          position: "absolute",
          left: { xs: "33%", sm: "44%", md: "55%" },
        }}
      />
    );
  };

  return (
    <Box>
      <Box
        mt={1.5}
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {isColumnCustomizingRequired && (
          <Box sx={{ marginRight: "1rem" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Button
                endIcon={<SettingsIcon />}
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                type="submit"
                sx={{ borderRadius: "25px", marginY: "1rem" }}
                onClick={handleMenuOpen}
              >
                Customize Columns
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <Droppable droppableId="columns">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {table.getAllLeafColumns()?.map((column, index) => (
                        <Draggable
                          key={column.id}
                          draggableId={column.id}
                          index={index}
                        >
                          {(provided) => (
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={column.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box>
                                <Switch
                                  checked={column.getIsVisible()}
                                  onChange={column.getToggleVisibilityHandler()}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                                {column.id}
                              </Box>
                              <ListItemIcon>
                                <DragIndicatorIcon fontSize="small" />
                              </ListItemIcon>
                            </MenuItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Menu>
            </DragDropContext>
          </Box>
        )}

        {isUserModule && (
          <Box role="search">
            <TextInput
              inputKey="search"
              inputLabel="Search"
              {...(inputTooltip && { tooltip: inputTooltip })}
              autoComplete="off"
              margin="none"
              required={false}
              value={searchText}
              onChange={(e) => {
                debounceOnSearch(e.target.value);
                setSearchText(e.target.value);
              }}
              sx={{ height: "2.5rem", borderRadius: "100px" }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
        )}

        {!!leftCheckBoxTitle && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox
              checked={isCheckboxSelected}
              onChange={handleChangeCheckBox}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography sx={{ ml: "0.5rem" }}>{leftCheckBoxTitle}</Typography>
          </Box>
        )}

        {!!onZipDownload && (
          <Box role="download">
            <Button
              disabled={disableZipDownload}
              onClick={onZipDownload}
              sx={{
                fontSize: "0.8rem",
                backgroundColor: theme.primaryDark,
                color: "white",
                paddingX: "0.8rem",
                "&:hover": {
                  backgroundColor: theme.primaryDark,
                  color: "white",
                },
                "&.Mui-disabled": {
                  background: theme.primaryFade,
                  color: theme.white,
                },
              }}
            >
              <FileDownloadOutlinedIcon
                label="Download"
                variant="outlined"
                sx={{
                  color: "white",
                  marginRight: "0.4rem",
                  fontSize: "1.3rem",
                }}
              />
              {downloadFileLabel}
            </Button>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="end"
          sx={{
            marginLeft: "auto",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {showFilterDropdown && (
            <Box sx={{ display: "flex" }}>
              <Dropdown
                size="small"
                label={label ?? "Policy Types"}
                options={filterOptions}
                value={firstFilterValue}
                onChange={handleFirstFilterChange}
                margin="none"
                sx={{ width: "9rem" }}
              />
              {!!secondFilterOptions && (
                <Dropdown
                  size="small"
                  label={secondLabel ?? "Filter Types"}
                  options={secondFilterOptions}
                  value={secondFilterValue}
                  onChange={handleSecondfilterChange}
                  margin="none"
                  sx={{ width: "11rem" }}
                />
              )}
            </Box>
          )}

          {listData && (
            <Box>
              <Box mx={1} sx={{ marginLeft: "2rem", display: "inline-block" }}>
                {`${listData?.from || 0} - ${listData?.to || 0}`}
                &nbsp;of {tableData.length === 0 ? "0" : total}
              </Box>
              <Button
                disabled={dataLoading || page === 1}
                onClick={() => {
                  page > 1 && setPage((prev) => prev - 1);
                  !!setDataFetching && setDataFetching(true);
                }}
                style={{ minWidth: 0 }}
                aria-label="Previous"
              >
                {"<"}
              </Button>
              <Button
                disabled={dataLoading || page === total_pages}
                onClick={() => {
                  page <= total_pages && setPage((prev) => prev + 1);
                  !!setDataFetching && setDataFetching(true);
                }}
                style={{ minWidth: 0 }}
                aria-label="Next"
              >
                {">"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        mt={1.5}
        className="table-responsive"
        sx={{
          // width: { xs: "87vw", sm: "87vw", md: "87vw", lg: "76vw", xl: "80vw" },
          // maxWidth: "100%",
          minHeight: "25rem",
          maxHeight: ![ivfAdmin, sadAdmin].includes?.(tableName)
            ? "21rem"
            : undefined,
          border: "solid 1px #eee",
        }}
      >
        {![ivfAdmin, sadAdmin].includes?.(tableName) || !!userData?.id ? (
          <Table>
            {!!caption && (
              <caption className="hidden-visually">{caption}</caption>
            )}
            <TableHeader
              headerGroups={table.getHeaderGroups()}
              padding={padding}
            />

            {dataLoading ? (
              <tbody
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <tr>
                  <td colSpan={columns.length}>
                    <DataLoader />
                  </td>
                </tr>
              </tbody>
            ) : data?.length ? (
              <TableRow rowModel={table.getRowModel()} />
            ) : (
              <tbody>
                <tr>
                  <td colSpan={table.getHeaderGroups()[0].headers.length ?? 0}>
                    <Typography
                      variant="body1"
                      color="initial"
                      sx={{
                        textAlign: "left",
                        marginY: "0.4rem",
                        position: "absolute",
                        left: { xs: "33%", sm: "44%", md: "55%" },
                      }}
                      role="alert"
                    >
                      No records to display
                    </Typography>
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        ) : (
          <DataLoader />
        )}
      </Box>
    </Box>
  );
};

export default ReactTable;
