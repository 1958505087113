/*
 * Get Statement page
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { INSURANCE_TYPES } from "../../constants";
import { ApplicationApis, UsersApis } from "../../api";
import { oneMonthDateRangeText } from "../../utils/CommonUtils";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";

const GetStatement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const myData = useSelector((state) => state.commonReducer.meData);
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [searchButton, setSearchButton] = useState(true);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [buttonLoading, setButtonLoading] = useState("");
  const [insuranceType, setInsuranceType] = useState(INSURANCE_TYPES.ivf);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [submittedBy, setSubmittedBy] = useState(null);
  const [orgUsers, setOrgUsers] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [isOrgAdmin, setOrgAdmin] = useState(null);

  const propertyMapping = {
    insurance_type: setInsuranceType,
    submitted_on: (value) =>
      setDateRange(value?.split?.(",")?.map?.((dateStr) => new Date(dateStr))),
    invoice_payment_status: setPaymentStatus,
    created_by: setSubmittedBy,
  };

  useEffect(() => {
    if (myData) {
      setOrgAdmin(Boolean(myData?.is_agency_admin || myData?.is_clinic_admin));
    }
  }, [myData]);

  useEffect(() => {
    if (myData && isOrgAdmin) {
      setSubmittedBy(myData?.id);
      getUsersList();
    }
  }, [isOrgAdmin]);

  useEffect(() => {
    if (isOrgAdmin !== null && myData) {
      setSearchParams((searchParams) => {
        if (!searchParams.has("insurance_type")) {
          searchParams.set("insurance_type", INSURANCE_TYPES.ivf);
        }
        if (!searchParams.has("submitted_on")) {
          searchParams.set("submitted_on", oneMonthDateRangeText());
        }
        if (!searchParams.has("page")) {
          searchParams.set("page", 1);
        }
        if (isOrgAdmin && myData?.id && !searchParams.has("created_by")) {
          searchParams.set("created_by", myData?.id);
        }
        return searchParams;
      });

      const params = [];
      searchParams.forEach((value, key) => {
        params.push([key, value]);
      });
      const obj = params.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      Object.keys(propertyMapping)?.forEach((key) => {
        if (obj?.hasOwnProperty(key)) {
          propertyMapping?.[key]?.(obj?.[key]);
        }
      });

      setPage(Number(obj["page"]));
      const { page, ...rest } = obj;
      setAdvancedSearch(rest);
    }
  }, [myData, isOrgAdmin]);

  const getUsersList = async () => {
    const params = { sortOrder: "", sortKey: "first_name", limit: false };
    const res = await UsersApis.getAllUsers(params);
    const formatList = !!res?.length
      ? res?.map?.((user) => {
          const { first_name, last_name, id } = user || {};
          return { label: `${first_name || ""} ${last_name || ""}`, value: id };
        })
      : [];
    setOrgUsers(formatList);
  };

  useEffect(() => {
    if (page > 0 && isOrgAdmin !== null) {
      getAllPoliciesList();
    }
  }, [page, searchButton, isOrgAdmin]);

  // Get all policies
  const getAllPoliciesList = async () => {
    setDataLoading(true);
    if (!isOrgAdmin || submittedBy == myData?.id) {
      delete advancedSearch["created_by"];
    }

    const params = {
      sortOrder: "",
      sortKey: "",
      page,
      advancedSearch: {
        ...advancedSearch,
        ...(isOrgAdmin &&
          submittedBy != myData?.id && { organization_policies: 1 }),
        exclude_draft: 1,
        exclude_under_reviewed: 1,
      },
    };
    const resp = await ApplicationApis.getAllPolicies(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h1">Get Statement</Typography>
      </Grid>

      <TableHeader
        advancedSearch={advancedSearch}
        setAdvancedSearch={setAdvancedSearch}
        insuranceType={insuranceType}
        setInsuranceType={setInsuranceType}
        dateRange={dateRange}
        setDateRange={setDateRange}
        paymentStatus={paymentStatus}
        setPaymentStatus={setPaymentStatus}
        isOrgAdmin={isOrgAdmin}
        orgUsers={orgUsers}
        submittedBy={submittedBy}
        setSubmittedBy={setSubmittedBy}
        myData={myData}
        setPage={setPage}
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        setButtonLoading={setButtonLoading}
        setSearchParams={setSearchParams}
      />

      <TableData
        caption="Get Statement"
        dataList={dataList}
        listData={listData}
        page={page}
        setPage={setPage}
        insuranceType={insuranceType}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        loading={loading}
        dataLoading={dataLoading}
      />
    </AdminPageLayout>
  );
};

export { GetStatement };
