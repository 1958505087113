/*
 * Admin|Broker -> User accounts
 */

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Typography, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TableData } from "./TableData";
import { TableHeader } from "./TableHeader";
import { AllRoutes } from "../../../routes";
import { Button } from "../../../components";
import { BrokerApis, UsersApis } from "../../../api";
import { LocalStorageHelper } from "../../../utils/HttpUtils";
import { USER_DATA, USER_ROLE, USER_ROLES } from "../../../constants";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const UserAccounts = () => {
  const navigate = useNavigate();
  const isAdmin = LocalStorageHelper.get(USER_ROLE) === USER_ROLES.admin;
  const brokerData = JSON.parse(LocalStorageHelper.get(USER_DATA));
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataList, setDataList] = useState([]);
  const [listData, setListData] = useState();
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [buttonLoading, setButtonLoading] = useState("");
  const [searchButton, setSearchButton] = useState(true);
  const [brokerList, setBrokerList] = useState([]);
  const [broker, setBroker] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState({});
  const [viewDeleted, setViewDeleted] = useState(false);

  useEffect(() => {
    setSearchParams((searchParams) => {
      if (!searchParams.has("page")) {
        searchParams.set("page", 1);
      }
      if (isAdmin && !searchParams.has("only_deleted")) {
        searchParams.set("only_deleted", 0);
      }
      if (!isAdmin) {
        searchParams.set(
          "default_broker_code",
          brokerData?.default_broker_code_group
        );
      }
      return searchParams;
    });

    const params = [];
    searchParams.forEach((value, key) => {
      params.push([key, value]);
    });
    const obj = params.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    if (obj?.hasOwnProperty("q")) {
      setSearchText(obj["q"]);
    }
    if (obj?.hasOwnProperty("default_broker_code")) {
      setBroker(obj["default_broker_code"]);
    }
    setPage(Number(obj["page"]));
    setViewDeleted(Number(obj["only_deleted"]));

    const { page, ...rest } = obj;

    setAdvancedSearch(rest);

    !!isAdmin && getBrokerList();
  }, []);

  const getBrokerList = async () => {
    const params = { sortOrder: "", sortKey: "name" };
    const res = await BrokerApis.getBrokerList(params);
    setBrokerList(
      res?.success ? [{ label: "All", value: "" }, ...res?.data?.results] : []
    );
  };

  useEffect(() => {
    page > 0 && allUsersList();
  }, [page, searchButton]);

  // Get all change/cancel records
  const allUsersList = async () => {
    setDataLoading(true);
    let params = { page, advancedSearch };
    const resp = await UsersApis.getAllUsers(params);
    if (resp) {
      setButtonLoading("");
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data?.results ?? []);
    setListData(resp?.data);
  };

  return (
    <AdminPageLayout>
      <Grid container>
        <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
          <Typography variant="h1">User Accounts</Typography>
        </Grid>
        {!!isAdmin && (
          <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
            <Button
              title="Add New User"
              variant="contained"
              sx={{ width: "100%", minHeight: "2rem" }}
              startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
              onClick={() => navigate(`${AllRoutes.AddNewUser.route}`)}
            />
          </Grid>
        )}
      </Grid>

      <TableHeader
        brokerList={brokerList}
        broker={broker}
        setBroker={setBroker}
        advancedSearch={advancedSearch}
        setAdvancedSearch={setAdvancedSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        setPage={setPage}
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        buttonLoading={buttonLoading}
        viewDeleted={viewDeleted}
        setButtonLoading={setButtonLoading}
        setSearchParams={setSearchParams}
        isAdmin={isAdmin}
      />

      <TableData
        dataList={dataList}
        setDataList={setDataList}
        listData={listData}
        page={page}
        setPage={setPage}
        loading={loading}
        dataLoading={dataLoading}
        viewDeleted={viewDeleted}
        setViewDeleted={setViewDeleted}
        setAdvancedSearch={setAdvancedSearch}
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        setSearchParams={setSearchParams}
        isAdmin={isAdmin}
      />
    </AdminPageLayout>
  );
};

export { UserAccounts };
