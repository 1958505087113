/**
 * IVF Application APIs
 */

import httpClient from "../utils/HttpUtils";

export const IvfApis = {
  createApplicationId: async (payload) => {
    try {
      const resp = await httpClient.post("applications", payload);
      return resp;
    } catch (error) {
      console.log("Error createApplicationId:", error);
    }
  },

  getApplicationData: async (payload) => {
    const { application_id } = payload;
    try {
      const resp = await httpClient.get(`applications/${application_id}`);
      return resp;
    } catch (error) {
      console.log("Error IVF getApplicationData:", error);
    }
  },

  getSummaryData: async (payload) => {
    const { parent_id } = payload;
    try {
      const resp = await httpClient.get(`application_groups/${parent_id}`);
      return resp;
    } catch (error) {
      console.log("Error IVF getApplicationData:", error);
    }
  },

  updateApplication: async (payload) => {
    const { application_id, params } = payload;
    try {
      const resp = await httpClient.put(
        `applications/${application_id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error IVF updateApplication:", error);
    }
  },

  syncApplication: async (payload) => {
    const { parent_id, params } = payload;
    try {
      const resp = await httpClient.put(
        `/application_groups/${parent_id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log(error, "error");
    }
  },

  deleteApplication: async (payload) => {
    const { parent_id, application_id } = payload;
    try {
      const resp = await httpClient.delete(
        `/application_groups/${parent_id}?application_id=${application_id}`
      );
      return resp;
    } catch (error) {
      console.log(error, "error");
    }
  },

  getTermsAgreements: async (payload) => {
    const { application_id } = payload;
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/terms_agreements`
      );
      return resp;
    } catch (error) {
      console.log("Error IVF getTermsAgreements:", error);
    }
  },

  getAllPolicies: async (payload) => {
    const {
      sortOrder = "-",
      sortKey = "application_number_sequence",
      page,
      advancedSearch,
    } = payload ?? {};
    let reqUrl = `/applications?filters[insurance_type]=IVF`;
    if (payload) {
      reqUrl += `&sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;
    }
    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error IVF getAllPolicies:", error);
    }
  },
};
