/**
 * App string constant
 * All static text should be import here
 * @format
 */

export const strings = {
  batchDownload: "Batch Download",
  editUser: "Edit User",
  editAgency: "Edit Agency",
  deleteAgency: "Delete Agency",
  restoreAgency: "Restore Agency",
  restoreClinic: "Restore Clinic",
  restoreUser: "Restore User",
  editBroker: "Edit Broker",
  editClinic: "Edit Clinic",
  deleteClinic: "Delete Clinic",
  exportExcel: "Export Excel",
  editFee: "Edit Fee",
  deleteFee: "Delete Fee",
  manageFee: "Manage Fee",
  suspended: "Suspended",
  voidedCertificates: "Voided Certificates",
  loginAsThisUser: "Login as this user",
  deleteUser: "Delete User",
  enableFeeRounding: "Enable Fee Rounding",
  disableFeeRounding: "Disable Fee Rounding",
};
