/*
 * IVF Summary
 */

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import {
  coverageTypeLabel,
  INSURANCE_TYPES,
  USER_ROLE,
  USER_ROLES,
} from "../../../../constants";
import {
  Accordion,
  Button,
  CommonModal,
  HeadAccordion,
} from "../../../../components";
import { AllRoutes } from "../../../../routes";
import { theme } from "../../../../styles/theme";
import { AttachmentApis, IvfApis } from "../../../../api";
import { IVFActionButtons } from "../components/ActionButtons";
import { LocalStorageHelper } from "../../../../utils/HttpUtils";
import { usePlansWithoutRiders, usePlansWithRiders } from "../../../../hooks";
import { setIvfSummary } from "../../../../redux/slices/globalSlice";

const IVFSummary = ({ setActiveStep, parentId, applicationsLength }) => {
  const { id: application_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.agency;
  const summaryData = useSelector((state) => state.ivfReducer.ivfSummary);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [applications, setApplications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [alertsModal, setAlertsModal] = useState(false);
  const [affidavitLink, setAffidavitLink] = useState("");
  const [showESignedModal, setShowESignedModal] = useState(false);
  const [showAffidavitModal, setShowAffidavitModal] = useState(false);
  const { ivf_contractual_liable_options } = lookups;
  const { getIndemnityLabelByValue } = usePlansWithoutRiders(
    INSURANCE_TYPES.ivf
  );
  const { getRiderLabelByValue } = usePlansWithRiders(INSURANCE_TYPES.ivf);

  useEffect(() => {
    if (summaryData?.length) {
      setApplications(summaryData);
    }
  }, [summaryData]);

  const getAffidavit = async (affidavit_id, application_id) => {
    const resp = await AttachmentApis.getPolicyDoc({
      application_id,
      policy_doc_id: affidavit_id,
    });
    !!resp ? setAffidavitLink(resp) : setShowESignedModal(false);
  };

  const submitApplication = async () => {
    setButtonLoading(true);

    let resp = [];
    if (application_id) {
      const response = await IvfApis.updateApplication({
        application_id,
        params: { submit: true },
      });
      const updatedResponse = { ...response, data: [response?.data] || [] };
      resp = updatedResponse;
    } else if (parentId) {
      resp = await IvfApis.syncApplication({
        parent_id: parentId,
        params: { submit: true },
      });
    }

    !!resp?.data?.length && setButtonLoading(false);

    if (resp?.success) {
      dispatch(setIvfSummary(resp?.data));
      let showAffidavitModal = false;
      let alertsModal = false;
      resp?.data?.forEach((application) => {
        const {
          affidavits,
          alerts,
          contractually_liable_party_country_code,
          contractually_liable_party_state_code,
        } = application ?? {};

        if (
          contractually_liable_party_country_code === "US" &&
          contractually_liable_party_state_code === "MA" &&
          affidavits?.length > 0
        ) {
          showAffidavitModal = true;
        } else if (alerts?.length > 0) {
          alertsModal = true;
        }
      });

      // After processing all applications, decide UI actions
      if (showAffidavitModal) {
        setShowAffidavitModal(true);
      } else if (alertsModal) {
        setAlertsModal(true);
      } else {
        setActiveStep(4);
      }
    }
  };

  const GridItem = ({ keyName, valueName }) => {
    return (
      <Grid container>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              lineHeight: "2rem",
            }}
          >
            {keyName.endsWith("?") ? keyName : `${keyName}:`}
          </Typography>
        </Grid>
        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              lineHeight: "2rem",
            }}
          >
            {valueName}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {/* Page content */}
      {applications?.map((application, index) => (
        <HeadAccordion
          showDelete={false}
          defaultExpanded={true}
          header={`Application-${index + 1}`}
          showAccordion={applicationsLength > 1 ? true : false}
        >
          <Accordion
            defaultExpanded={true}
            header="General Information"
            name={`general-information-${index}`}
            component={applications.length > 1 ? "h3" : "h2"}
          >
            <GridItem
              keyName="Coverage Type"
              valueName={
                application?.coverage_type === "EDO"
                  ? "Donor Only"
                  : application?.coverage_type === "RO"
                  ? "Recipient Only"
                  : application?.coverage_type === "EDR"
                  ? "Donor and Recipient"
                  : null
              }
            />

            <GridItem
              keyName="Term Length"
              valueName={
                application?.term_length === 4 ? "4 Months" : "6 Months"
              }
            />
            <GridItem
              keyName="Limit of Indemnity"
              valueName={getIndemnityLabelByValue(
                application?.coverage_type,
                application?.term_length,
                application?.idemnity_limit
              )}
            />
            <GridItem
              keyName="Reproductive Organ Loss Rider"
              valueName={
                application?.reproductive_organ_loss_rider === 0 ? "No" : "Yes"
              }
            />

            {application?.reproductive_organ_loss_rider_option ? (
              <GridItem
                keyName="If Yes Select Coverage Limit"
                valueName={getRiderLabelByValue(
                  application?.term_length,
                  application?.reproductive_organ_loss_rider_option
                )}
              />
            ) : (
              ""
            )}

            {application?.coverage_type === "EDR" &&
              application?.reproductive_organ_loss_rider !== undefined &&
              application?.reproductive_organ_loss_rider !== null &&
              application?.reproductive_organ_loss_rider !== 0 && (
                <GridItem
                  keyName="Optional Coverage Intended For"
                  valueName={coverageTypeLabel(
                    application?.insurance_type,
                    application?.optional_rider_intended_for
                  )}
                />
              )}

            {/* Donor */}
            {application?.coverage_type &&
              application?.coverage_type !== "RO" && (
                <>
                  <GridItem
                    keyName="Name/ID of Donor"
                    valueName={application?.donor_name_id}
                  />
                  <GridItem
                    keyName="Medication Start Date of Donor"
                    valueName={dayjs(
                      application?.donor_medication_start_date
                    ).format("MM/DD/YYYY")}
                  />
                  <GridItem
                    keyName="DOB of Donor"
                    valueName={dayjs(application?.donor_dob).format(
                      "MM/DD/YYYY"
                    )}
                  />
                  <GridItem
                    keyName="Has Donor Cycled Before?"
                    valueName={
                      application?.donor_has_cycled_before === 0 ? "No" : "Yes"
                    }
                  />
                  {application?.donor_has_cycled_before === 1 && (
                    <GridItem
                      keyName="Number of Previous Cycles"
                      valueName={application?.donor_num_previous_cycles}
                    />
                  )}
                  {application?.donor_has_cycled_before !== 0 && (
                    <>
                      <GridItem
                        keyName="Has there been Previous Cycle Related
                              Complications for Donor?"
                        valueName={
                          application?.donor_has_cycled_before_complications ===
                          0
                            ? "No"
                            : "Yes"
                        }
                      />
                      {application?.donor_has_cycled_before_complications !==
                        0 && (
                        <GridItem
                          keyName="Description of Previous Cycle Related Complications for Donor"
                          valueName={
                            application?.donor_cycle_complications_description
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )}

            {/* Recipient */}
            {application?.coverage_type &&
              application?.coverage_type !== "EDO" && (
                <>
                  <GridItem
                    keyName="Name/ID of Recipient"
                    valueName={application?.recipient_name_id}
                  />
                  <GridItem
                    keyName="Medication Start Date of Recipient"
                    valueName={dayjs(
                      application?.recipient_medication_start_date
                    ).format("MM/DD/YYYY")}
                  />
                  <GridItem
                    keyName="DOB of Recipient"
                    valueName={dayjs(application?.recipient_dob).format(
                      "MM/DD/YYYY"
                    )}
                  />
                  <GridItem
                    keyName="Has Recipient Cycled Before?"
                    valueName={
                      application?.recipient_has_cycled_before === 0
                        ? "No"
                        : "Yes"
                    }
                  />
                  {application?.recipient_has_cycled_before === 1 && (
                    <GridItem
                      keyName="Number of Previous Cycles"
                      valueName={application?.recipient_num_previous_cycles}
                    />
                  )}
                  {application?.recipient_has_cycled_before !== 0 && (
                    <>
                      <GridItem
                        keyName="Has there been Previous Cycle Related Complications
                            for Recipient?"
                        valueName={
                          application?.recipient_has_cycled_before_complications ===
                          0
                            ? "No"
                            : "Yes"
                        }
                      />
                      {application?.recipient_has_cycled_before_complications !==
                        0 && (
                        <GridItem
                          keyName="Description of Previous Cycle Related Complications for Recipient"
                          valueName={
                            application?.recipient_cycle_complications_description
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )}
            <GridItem
              keyName="Who is Contractually Liable for this Policy?"
              valueName={
                ivf_contractual_liable_options?.find?.(
                  (user) =>
                    user?.value === application?.contractually_liable_party
                )?.label || ""
              }
            />
            <GridItem
              keyName="Full Name of Contractually Liable Party"
              valueName={application?.contractually_liable_party_first_name}
            />
            <GridItem
              keyName="Street Address"
              valueName={application?.contractually_liable_party_address_line_1}
            />
            <GridItem
              keyName="Country"
              valueName={application?.contractually_liable_party_country}
            />
            <GridItem
              keyName="ZIP Code"
              valueName={application?.contractually_liable_party_zipcode}
            />
            <GridItem
              keyName="State"
              valueName={application?.contractually_liable_party_state}
            />
            <GridItem
              keyName="City"
              valueName={application?.contractually_liable_party_city}
            />
            {application?.financial_email ? (
              <GridItem
                keyName="Additional Email Address(s)"
                valueName={application?.financial_email
                  ?.split(";")
                  ?.map((email) => `${email}`)
                  ?.join(", ")}
              />
            ) : (
              ""
            )}
          </Accordion>

          <Accordion
            defaultExpanded={false}
            header="IVF Clinic Details"
            name={`ivf-clinic-details-${index}`}
            component={applications.length > 1 ? "h3" : "h2"}
          >
            <GridItem
              keyName="Country"
              valueName={application?.clinic_country}
            />
            <GridItem keyName="State" valueName={application?.clinic_state} />
            <GridItem
              keyName="Clinic Name"
              valueName={application?.clinic_name}
            />
            {application?.clinic_doctor_name ? (
              <GridItem
                keyName="Doctor Name"
                valueName={application?.clinic_doctor_name}
              />
            ) : (
              ""
            )}
          </Accordion>

          <Accordion
            defaultExpanded={false}
            header="Agency Details"
            name={`agency-details-${index}`}
            component={applications.length > 1 ? "h3" : "h2"}
          >
            <GridItem
              keyName={
                isUserAgency
                  ? "Which agency you are associated with?"
                  : "Are you working with an Agency?"
              }
              valueName={
                isUserAgency
                  ? ""
                  : application?.working_with_agency === 1
                  ? "Yes"
                  : "No"
              }
            />

            {application?.working_with_agency === 1 && (
              <>
                <GridItem
                  keyName="Country"
                  valueName={application?.agency_country}
                />
                <GridItem
                  keyName="State"
                  valueName={application?.agency_state}
                />
                <GridItem
                  keyName="Agency Name"
                  valueName={application?.agency_name}
                />
                {application?.agency_contact_person_name ? (
                  <GridItem
                    keyName="Contact Person Name"
                    valueName={application?.agency_contact_person_name}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </Accordion>
        </HeadAccordion>
      ))}

      {applications && applications.length > 0 ? (
        <>
          <Grid container columnSpacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  lineHeight: "1.5rem",
                  fontStyle: "italic",
                }}
              >
                NOTICE: Please verify the information captured on this form and
                continue.
              </Typography>
            </Grid>
          </Grid>

          <Divider />

          {/* Action Buttons */}
          <IVFActionButtons
            onPressBack={() => setActiveStep(2)}
            disableBack={buttonLoading}
            disableContinue={buttonLoading}
            OnPressContinue={submitApplication}
            loading={buttonLoading}
          />
        </>
      ) : null}

      <CommonModal
        open={alertsModal}
        type="warning"
        onConfirm={() => navigate(AllRoutes.IvfPoliciesPage.route)}
        title={
          `Thank you for submission. Your application is Under Review.  <br/>`

          //--------- N O T E
          // Below code is too show alert labels if any of an application has alerts

          // <small> Alert: ${
          //   applications && applications?.length > 0
          //     ? applications
          //         ?.map((app, index) => {
          //           if (app?.alerts?.length) {
          //             const alertLabels = app?.alerts?.join(", ");
          //             const label =
          //               applications?.length > 1
          //                 ? `Application-${index + 1} - `
          //                 : "";
          //             return `${label}${alertLabels}`;
          //           }
          //         })
          //         ?.filter((alert) => alert !== undefined)
          //         ?.join("<br/>")
          //     : ""
          // } </small>
        }
        subTitle={`Thank you for submitting this application with New Life Agency, LLC.
         Reference Policy Number: ${
           applications?.map((app) => app?.application_number) ?? ""
         }.
        You will hear back from your agent or representative at New Life Agency within 2 business days.
         Please note, no coverage has been given.`}
      />
      <CommonModal
        open={showAffidavitModal}
        type="info"
        onConfirm={() => {
          setShowAffidavitModal(false);

          if (applications?.length > 0) {
            let alertsModal = false;
            applications?.forEach((application) => {
              const { alerts } = application ?? {};
              if (alerts?.length > 0) {
                alertsModal = true;
              }
            });

            if (alertsModal) {
              setAlertsModal(true);
            } else {
              setActiveStep(4);
            }
          }
        }}
        title="Information!"
        subTitle="The state in which this policy is being written in, requires a surplus lines affidavit form to be introduced/signed. The IVF Cycle Complications Insurance policy is a unique policy offered through a surplus lines licensed broker. Such a policy does not currently exist or is offered by major medical insurance carriers in the state the policy is being written in."
      >
        {applications?.map((application, index) =>
          application?.contractually_liable_party_state_code === "MA" &&
          application?.affidavits?.length > 0 ? (
            <Button
              key={index}
              variant="text"
              size="medium"
              title={`Click To View E-Signed Affidavit ${
                applications?.length > 1 ? ` - Application ${index + 1}` : ""
              }`}
              sx={{
                fontWeight: "bold",
                fontSize: "0.9rem",
                marginBottom: "0.1rem",
                color: theme.mistBlue,
                borderRadius: "5px",
              }}
              onClick={() => {
                getAffidavit(application?.affidavits?.[0], application?.id);
                setShowESignedModal(true);
              }}
            />
          ) : null
        )}
      </CommonModal>

      <CommonModal
        open={showESignedModal}
        onCancel={() => {
          setShowESignedModal(false);
          setAffidavitLink("");
        }}
        cancelButtonLabel="Close"
        maxWidth="lg"
        fullWidth
      >
        {!affidavitLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={affidavitLink}
            width="100%"
            height="500"
            style={{ display: !affidavitLink ? "none" : "block" }}
          />
        )}
      </CommonModal>
    </>
  );
};

export { IVFSummary };
