/*
 * Admin -> Wizard -> State Table -> State Fees/Tax
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { TableData } from "./TableData";
import { AllRoutes } from "../../../routes";
import { Button, GoBack } from "../../../components";
import { StateFeeApis } from "../../../api/StateFeeApis";
import { AdminPageLayout } from "../../../layouts/admin-layout/AdminPageLayout";

const StateTax = () => {
  const navigate = useNavigate();
  const { country_code, state_code } = useParams();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    if (country_code && state_code) {
      getStateFeeList();
    } else {
      setLoading(false);
    }
  }, []);

  const getStateFeeList = async () => {
    setDataLoading(true);
    let params = { advancedSearch: { country_code, state_code } };
    const resp = await StateFeeApis.getStateFeeList(params);
    if (resp) {
      setDataLoading(false);
      setLoading(false);
    }
    setDataList(resp?.data ?? []);
  };

  return (
    <AdminPageLayout>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={7}
            md={8}
            lg={9}
            xl={10}
            sx={{ marginBottom: "1rem" }}
          >
            <Box display="flex" alignItems="center">
              <GoBack />
              <Typography variant="h1" sx={{ margin: "0" }}>
                State Fee
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            sx={{ textAlign: "right", marginBottom: "0.5rem" }}
          >
            <Button
              title="Add New Fee"
              variant="contained"
              sx={{ width: "100%", minHeight: "2rem" }}
              startIcon={<AddIcon sx={{ color: "#FFFFFF" }} />}
              onClick={() =>
                navigate(
                  `${AllRoutes.AddNewStateTax.route}/${country_code}/${state_code}`
                )
              }
              disabled={!country_code || !state_code}
            />
          </Grid>
        </Grid>
      </Box>

      <TableData
        caption="State Tax"
        dataList={dataList}
        setDataList={setDataList}
        loading={loading}
        dataLoading={dataLoading}
      />
    </AdminPageLayout>
  );
};

export { StateTax };
