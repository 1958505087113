/**
 * SAD Application APIs
 */

import httpClient from "../utils/HttpUtils";

export const SadApis = {
  createApplicationId: async (payload) => {
    try {
      const resp = await httpClient.post("applications", payload);
      return resp;
    } catch (error) {
      console.log("Error createApplicationId:", error);
    }
  },

  getTermsAgreements: async (payload) => {
    const { application_id } = payload;
    try {
      const resp = await httpClient.get(
        `applications/${application_id}/terms_agreements`
      );
      return resp;
    } catch (error) {
      console.log("Error SAD getTermsAgreements:", error);
    }
  },

  getApplicationData: async (payload) => {
    const { application_id } = payload;
    try {
      const resp = await httpClient.get(`applications/${application_id}`);
      return resp;
    } catch (error) {
      console.log("Error SAD getApplicationData:", error);
    }
  },

  updateApplication: async (payload) => {
    const { application_id, params } = payload;
    try {
      const resp = await httpClient.put(
        `applications/${application_id}`,
        params
      );
      return resp;
    } catch (error) {
      console.log("Error SAD updateApplication:", error);
    }
  },

  getAllPolicies: async (payload) => {
    const {
      sortOrder = "-",
      sortKey = "application_number_sequence",
      page,
      advancedSearch,
    } = payload ?? {};
    let reqUrl = `/applications?filters[insurance_type]=SAD`;
    if (payload) {
      reqUrl += `&sort=${sortOrder ? "-" : ""}${sortKey}&page=${page}`;
    }

    if (advancedSearch && Object?.keys?.(advancedSearch)?.length > 0) {
      // reqUrl += `&filters[application_number]=${searchText}&filters[coverage_type]=${searchText}&filters[available_term_month]=${searchText}&filters[indemnity_surrogates_beneficiary]=${searchText}&filters[indemnity_intended_parents]=${searchText}&compare=or`;
      let filtersPart = "";
      for (const [key, value] of Object.entries(advancedSearch)) {
        filtersPart += `&filters[${key}]=${value}`;
      }
      reqUrl += filtersPart;
    }
    try {
      const resp = await httpClient.get(reqUrl);
      return resp;
    } catch (error) {
      console.log("Error SAD getAllPolicies:", error);
    }
  },
};
