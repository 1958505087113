/*
 *Admin -> Sales Report -> Table Data
 */

import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import {
  GlobalLoader,
  RenderCellInfo,
  RenderChip,
  RenderTableHeader,
} from "../../components";
import {
  comma_separated,
  downloadExcel,
  formattedMDYDate,
} from "../../utils/CommonUtils";
import ReactTable from "../../React Table";
import { PaymentTypes, PolicyStatusValue, PolicyTypes } from "../sad/constants";
import { CHARGE_GROUPS, INSURANCE_TYPES, strings } from "../../constants";
import { usePlansWithoutRiders } from "../../hooks";

const { underReview } = PolicyTypes;
const { BASE, DISABILITY_RIDER, REPRODUCTIVE_LOSS_RIDER } = CHARGE_GROUPS;

// Utility function to get value based on column configuration
const getColumnValue = (
  column,
  row,
  getIndemnityLabelByValue,
  application_status_types_list
) => {
  const columnFormatters = {
    "Application Date": (row) =>
      row.submitted_on ? formattedMDYDate(row.submitted_on) : "",

    "Policy Status": (row) =>
      row.status === underReview
        ? "Submitted"
        : PolicyStatusValue(application_status_types_list, row.status),

    "Payment Date": (row) =>
      row?.invoice_payment_date
        ? formattedMDYDate(row?.invoice_payment_date)
        : "",

    "Effective Date": (row) =>
      row.effective_from ? formattedMDYDate(row.effective_from) : "",

    "Expiration Date": (row) =>
      row.effective_till ? formattedMDYDate(row.effective_till) : "",

    "Certificate Issue Date": (row) =>
      row.certificate_issued_at
        ? formattedMDYDate(row.certificate_issued_at)
        : "",

    "Insured Name": (row) =>
      row.insurance_type === INSURANCE_TYPES.ivf
        ? row.contractually_liable_party_first_name
        : `${row.intended_parent1_first_name} ${row.intended_parent1_last_name}`,

    "Insured Address": (row) => {
      if (row.insurance_type === INSURANCE_TYPES.ivf) {
        return `${row.contractually_liable_party_address_line_1}${
          row.contractually_liable_party_city
            ? `${row.contractually_liable_party_address_line_1 ? "," : ""} ${
                row.contractually_liable_party_city
              }`
            : ""
        }${
          row.contractually_liable_party_country_code
            ? `, ${row.contractually_liable_party_country_code}`
            : ""
        }${
          row.contractually_liable_party_state_code
            ? `, ${row.contractually_liable_party_state_code}`
            : ""
        }${
          row.contractually_liable_party_zipcode
            ? `, ${row.contractually_liable_party_zipcode}`
            : ""
        }`;
      } else {
        return `${row.intended_parent_address_line_1}${
          row.intended_parent_city
            ? `${row.intended_parent_address_line_1 ? "," : ""} ${
                row.intended_parent_city
              }`
            : ""
        }${
          row.intended_parent_country_code
            ? `, ${row.intended_parent_country_code}`
            : ""
        }${
          row.intended_parent_state_code
            ? `, ${row.intended_parent_state_code}`
            : ""
        }${
          row.intended_parent_zipcode ? `, ${row.intended_parent_zipcode}` : ""
        }`;
      }
    },

    "Total Limit of Indemnity": (row) =>
      `${
        (row?.indemnity_surrogates_beneficiary || 0) +
        (row?.indemnity_intended_parents || 0)
      }` || "",

    "Limit of Indemnity": (row) =>
      row.idemnity_limit
        ? getIndemnityLabelByValue(
            row.coverage_type,
            row.term_length,
            row.idemnity_limit
          )
        : "",

    "Total Due": (row) => {
      const canPayment = row?.invoice_id && !row?.alerts?.length;
      return canPayment ? row?.invoice_total : "";
    },
  };

  if (columnFormatters[column.id]) {
    return columnFormatters[column.id](row);
  }

  return column.accessorKey ? row[column.accessorKey] || "" : "";
};

// Utility function to prepare data for Excel export
const prepareExcelData = (
  dataList,
  tableColumns,
  getIndemnityLabelByValue,
  application_status_types_list
) => {
  return dataList?.map?.((row) => {
    const formattedRow = {};

    tableColumns?.forEach?.((column) => {
      formattedRow[column.id] = getColumnValue(
        column,
        row,
        getIndemnityLabelByValue,
        application_status_types_list
      );
    });

    return formattedRow;
  });
};

const TableData = ({
  caption,
  dataList,
  listData,
  setSortKey,
  setSortOrder,
  page,
  setPage,
  insuranceType,
  searchParams,
  setSearchParams,
  loading,
  dataLoading,
}) => {
  const { getIndemnityLabelByValue } = usePlansWithoutRiders(insuranceType);
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const { application_status_types_list } = lookups ?? {};

  const handlePageChange = (p) => {
    setPage((prev) => {
      setSearchParams((searchParams) => {
        searchParams.set("page", String(p(prev)));
        return searchParams;
      });
      return p(prev);
    });
  };

  const tableColumns = [
    {
      id: "Policy Number",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Number"
          headerKey="application_number"
        />
      ),
      accessorKey: "application_number",
      cell: (info) => (
        <RenderCellInfo text={info.getValue()} sx={{ color: "#0A7691" }} />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Application Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Application Date"
          headerKey="submitted_on"
        />
      ),
      accessorKey: "submitted_on",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Policy Status",
      accessorKey: "status",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Policy Status"
          headerKey="status"
        />
      ),
      cell: (info) => {
        const status = info.getValue();
        return (
          <RenderChip
            label={status === underReview ? "Submitted" : undefined}
            status={status}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Payment Date",
      accessorKey: "invoice_payment_date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Payment Date"
          headerKey="invoice_payment_date"
        />
      ),
      cell: (info) => {
        const paymentDate = info.getValue();
        return (
          <RenderCellInfo
            text={paymentDate ? formattedMDYDate(paymentDate) : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Effective Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Effective Date"
          headerKey="effective_from"
        />
      ),
      accessorKey: "effective_from",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Expiration Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Expiration Date"
          headerKey="effective_till"
        />
      ),
      accessorKey: "effective_till",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Certificate Issue Date",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Certificate Issue Date"
          headerKey="certificate_issued_at"
        />
      ),
      accessorKey: "certificate_issued_at",
      cell: (info) => (
        <RenderCellInfo
          text={info.getValue() ? formattedMDYDate(info.getValue()) : ""}
        />
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "Insured Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Insured Name"
          headerKey="intended_parent1_first_name"
        />
      ),
      accessorKey: "intended_parent1_first_name",
      cell: (info) => {
        const {
          insurance_type,
          intended_parent1_first_name,
          intended_parent1_last_name,
          contractually_liable_party_first_name,
        } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={
              insurance_type === INSURANCE_TYPES.ivf
                ? contractually_liable_party_first_name
                : `${intended_parent1_first_name} ${intended_parent1_last_name}`
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Insured Address",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Insured Address"
          headerKey="intended_parent_address_line_1"
        />
      ),
      accessorKey: "intended_parent_address_line_1",
      cell: (info) => {
        const {
          insurance_type,
          intended_parent_address_line_1,
          intended_parent_city,
          intended_parent_country_code,
          intended_parent_state_code,
          intended_parent_zipcode,
          contractually_liable_party_address_line_1,
          contractually_liable_party_city,
          contractually_liable_party_country_code,
          contractually_liable_party_state_code,
          contractually_liable_party_zipcode,
        } = info?.row?.original ?? {};

        return (
          <RenderCellInfo
            text={
              insurance_type === INSURANCE_TYPES.ivf
                ? `${contractually_liable_party_address_line_1}${
                    contractually_liable_party_city
                      ? `${
                          contractually_liable_party_address_line_1 ? "," : ""
                        } ${contractually_liable_party_city}`
                      : ""
                  }${
                    contractually_liable_party_country_code
                      ? `, ${contractually_liable_party_country_code}`
                      : ""
                  }${
                    contractually_liable_party_state_code
                      ? `, ${contractually_liable_party_state_code}`
                      : ""
                  }${
                    contractually_liable_party_zipcode
                      ? `, ${contractually_liable_party_zipcode}`
                      : ""
                  }`
                : `${intended_parent_address_line_1}${
                    intended_parent_city
                      ? `${
                          intended_parent_address_line_1 ? "," : ""
                        } ${intended_parent_city}`
                      : ""
                  }${
                    intended_parent_country_code
                      ? `, ${intended_parent_country_code}`
                      : ""
                  }${
                    intended_parent_state_code
                      ? `, ${intended_parent_state_code}`
                      : ""
                  }${
                    intended_parent_zipcode
                      ? `, ${intended_parent_zipcode}`
                      : ""
                  }`
            }
            sx={{ whiteSpace: "wrap", wordWrap: "break-word", width: "18rem" }}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    ...(searchParams?.get?.("insurance_type") === INSURANCE_TYPES.ivf
      ? [
          {
            id: "Donor Name/ID",
            accessorKey: "donor_name_id",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Donor Name/ID"
                headerKey="donor_name_id"
              />
            ),
            cell: (info) => <RenderCellInfo text={info.getValue()} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Recipient Name/ID",
            accessorKey: "recipient_name_id",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Recipient Name/ID"
                headerKey="recipient_name_id"
              />
            ),
            cell: (info) => <RenderCellInfo text={info.getValue()} />,
            footer: (props) => props.column.id,
          },
          {
            id: "Limit of Indemnity",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Limit of Indemnity"
                headerKey="idemnity_limit"
              />
            ),
            accessorKey: "idemnity_limit",
            cell: (info) => {
              const { coverage_type, term_length } = info.row.original || {};
              return (
                <RenderCellInfo
                  text={
                    info?.getValue()
                      ? getIndemnityLabelByValue(
                          coverage_type,
                          term_length,
                          info.getValue()
                        )
                      : ""
                  }
                />
              );
            },
            footer: (props) => props.column.id,
          },
        ]
      : [
          {
            id: "Total Limit of Indemnity",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Total Limit of Indemnity"
                headerKey="indemnity_surrogates_beneficiary"
              />
            ),
            accessorKey: "indemnity_surrogates_beneficiary",
            cell: (info) => {
              const { indemnity_intended_parents } = info.row.original || {};
              return (
                <RenderCellInfo
                  text={
                    `$${comma_separated(
                      (info.getValue() || 0) + (indemnity_intended_parents || 0)
                    )}` || ""
                  }
                />
              );
            },
            footer: (props) => props.column.id,
          },
        ]),
    {
      id: "Premium",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Premium"
          headerKey="total"
        />
      ),
      accessorKey: "total",
      cell: (info) => {
        const { invoice_line_items } = info?.row?.original || {};
        const { total } =
          invoice_line_items?.filter?.((i) => i.type === BASE)?.[0] || {};
        return <RenderCellInfo text={`$${comma_separated(total)}` || ""} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Reproductive Organ Loss Rider Level",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Reproductive Organ Loss Rider Level"
          headerKey="key_code"
        />
      ),
      accessorKey: "key_code",
      cell: (info) => {
        const { invoice_line_items } = info?.row?.original || {};
        const { key_code } =
          invoice_line_items?.filter?.(
            (i) => i.type === REPRODUCTIVE_LOSS_RIDER
          )?.[0] || {};
        return <RenderCellInfo text={key_code || ""} />;
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Reproductive Organ Loss Rider Premium",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Reproductive Organ Loss Rider Premium"
          headerKey="total"
        />
      ),
      accessorKey: "total",
      cell: (info) => {
        const { invoice_line_items } = info?.row?.original || {};
        const { total } =
          invoice_line_items?.filter?.(
            (i) => i.type === REPRODUCTIVE_LOSS_RIDER
          )?.[0] || {};
        return (
          <RenderCellInfo
            text={total ? `$${comma_separated(total)}` || "" : ""}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    ...(searchParams?.get?.("insurance_type") === INSURANCE_TYPES.sad
      ? [
          {
            id: "Total Disability Rider Level",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Total Disability Rider Level"
                headerKey="key_code"
              />
            ),
            accessorKey: "key_code",
            cell: (info) => {
              const { invoice_line_items } = info?.row?.original || {};
              const { key_code } =
                invoice_line_items?.filter?.(
                  (i) => i.type === DISABILITY_RIDER
                )?.[0] || {};
              return <RenderCellInfo text={key_code || ""} />;
            },
            footer: (props) => props.column.id,
          },
          {
            id: "Total Disability Rider Premium",
            header: ({ column }) => (
              <RenderTableHeader
                column={column}
                headerName="Total Disability Rider Premium"
                headerKey="total"
              />
            ),
            accessorKey: "total",
            cell: (info) => {
              const { invoice_line_items } = info?.row?.original || {};
              const { total } =
                invoice_line_items?.filter?.(
                  (i) => i.type === DISABILITY_RIDER
                )?.[0] || {};
              return (
                <RenderCellInfo
                  text={total ? `$${comma_separated(total)}` || "" : ""}
                />
              );
            },
            footer: (props) => props.column.id,
          },
        ]
      : []),
    {
      id: "Total Due",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Total Due"
          headerKey="invoice_total"
        />
      ),
      accessorKey: "invoice_total",
      cell: (info) => {
        const { invoice_payment_status } = info.row.original ?? {};
        const isPaid = Boolean(invoice_payment_status === PaymentTypes.paid);
        return (
          <RenderCellInfo text={`$${isPaid ? "0" : info.getValue() || "0"}`} />
        );
      },
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            page={page}
            setPage={handlePageChange}
            listData={listData}
            disableZipDownload={!dataList?.length}
            onZipDownload={() => {
              const filteredData = prepareExcelData(
                dataList,
                tableColumns,
                getIndemnityLabelByValue,
                application_status_types_list
              );
              downloadExcel(filteredData, "Sales-Report");
            }}
            downloadFileLabel={strings.exportExcel}
            caption={caption}
            dataLoading={dataLoading}
          />
        </Box>
      )}
    </>
  );
};

export { TableData };
