/*
 * Common Button Component
 */

import React from "react";
import { Button as MuiButton, CircularProgress } from "@mui/material";
import { theme } from "../styles/theme";

const Button = React.forwardRef(({
  disabled = false,
  disableElevation = true,
  loading,
  onClick,
  sx,
  title,
  ...rest
}, ref) => {
  return (
    <MuiButton
      ref={ref}
      disableElevation={disableElevation}
      sx={{
        borderRadius: "25px",
        minHeight: "3rem",
        minWidth: "7rem",
        borderWidth: 2,
        "&:hover": { borderWidth: 2 },
        "&.Mui-disabled": {
          background: theme.primaryFade,
          color: theme.white,
        },
        ...sx,
      }}
      disableFocusRipple
      disabled={disabled || loading}
      onClick={onClick}
      {...rest}
    >
      {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : title}
    </MuiButton>
  );
});

export { Button };
