/*
 * ADMIN USERS-ACCOUNTS GRID HEADER
 */

import "react-calendar/dist/Calendar.css";
import React from "react";
import { InputAdornment, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dropdown, GridActionButtons, TextInput } from "../../../components";
import { theme } from "../../../styles/theme";

const TableHeader = ({
  brokerList,
  broker,
  setBroker,
  advancedSearch,
  setAdvancedSearch,
  searchText,
  setSearchText,
  setPage,
  searchButton,
  setSearchButton,
  buttonLoading,
  viewDeleted,
  setButtonLoading,
  setSearchParams,
  isAdmin,
}) => {
  const setSearchObject = (key, value) => {
    setAdvancedSearch((p) => ({ ...p, [key]: value }));
  };

  const searchValues = () => {
    setButtonLoading("search");
    setPage(1);
    setSearchButton(!searchButton);
    setSearchParams((searchParams) => {
      searchParams.set("page", 1);
      Object.entries(advancedSearch).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
      return searchParams;
    });
  };

  const onClear = () => {
    setButtonLoading("clear");
    setAdvancedSearch({ only_deleted: Number(viewDeleted) });
    setSearchText(" ");
    setBroker("");
    setPage(1);
    setSearchButton(!searchButton);
    setSearchParams({ page: 1, only_deleted: Number(viewDeleted) });
  };

  return (
    <form>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginY: "1rem",
          paddingY: "1rem",
        }}
      >
        <Grid container spacing={2}>
          {!!isAdmin && (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Dropdown
                size="small"
                label="Broker"
                id="broker"
                options={brokerList ?? []}
                required={false}
                selectLabel="name"
                selectValue="code"
                value={broker}
                onChange={(e) => {
                  setBroker(e.target.value);
                  setSearchObject("default_broker_code", e.target.value);
                }}
                margin="none"
                sx={{ backgroundColor: theme.white }}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <TextInput
              inputKey="search"
              inputLabel="Search"
              placeholder="Search by Keywords"
              autoComplete="off"
              margin="none"
              required={false}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setSearchObject("q", e.target.value);
              }}
              sx={{ height: "2.5rem" }}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <GridActionButtons
              onSearch={searchValues}
              onClear={onClear}
              searchButtonLoading={buttonLoading == "search"}
              clearButtonLoading={buttonLoading == "clear"}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export { TableHeader };
