/**
 * Auth section APIs
 */

import { USER_DATA, USER_ROLE, USER_TOKEN } from "../constants";
import { handleUserLogin, handleUserLogout } from "../utils/CommonUtils";
import httpClient, { LocalStorageHelper } from "../utils/HttpUtils";

export const AuthApis = {
  createUser: async (payload) => {
    try {
      const resp = await httpClient.post("signup", payload);
      return resp;
    } catch (error) {
      console.log("Error createUser:-", error);
    }
  },

  verifyEmail: async (payload) => {
    const { token, params } = payload;
    try {
      const resp = await httpClient.put(`signup/${token}`, params);
      if (resp?.data) {
        LocalStorageHelper.store(USER_TOKEN, resp?.data?.token);
        LocalStorageHelper.store(USER_DATA, JSON.stringify(resp?.data));
        LocalStorageHelper.store(USER_ROLE, resp?.data?.role ?? "");
      }
      return resp;
    } catch (error) {
      console.log("Error verifyEmail:-", error);
    }
  },

  login: async (payload) => {
    try {
      const resp = await httpClient.post("login", payload);
      if (resp?.success) {
        const route = handleUserLogin(resp?.data);
        window.location.href = route;
      }
      return resp;
    } catch (error) {
      console.log("Error login:-", error);
    }
  },

  forgotPassword: async (payload) => {
    try {
      const resp = await httpClient.post("reset_password", payload);
      return resp;
    } catch (error) {
      console.log("Error forgotPassword:-", error);
    }
  },

  resetPassword: async (payload) => {
    const { token, params } = payload;
    try {
      const resp = await httpClient.put(`reset_password/${token}`, params);
      return resp;
    } catch (error) {
      console.log("Error resetPassword:-", error);
    }
  },

  logout: async () => {
    try {
      await httpClient.post("logout");
      handleUserLogout();
    } catch (error) {
      console.log("Error logout:-", error);
    }
  },
};
