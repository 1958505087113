/*
 * Payment landing page
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { AllRoutes } from "../../routes";
import { CommonModal } from "../../components";
import { AdminPageLayout } from "../../layouts/admin-layout/AdminPageLayout";
import { PaymentApis } from "../../api";
import { INSURANCE_TYPES } from "../../constants";

const PaymentLandingPage = () => {
  const { insurance_type, id: invoice_id } = useParams();
  const params = new URLSearchParams(location.search);
  const status = params.get("status");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [popupData, setPopupData] = useState({
    type: "success",
    title: "",
    subTitle: "",
    subTitle2: "Thankyou for applying!",
  });

  useEffect(() => {
    if (status == "cancel") {
      setPopupData({
        title: "Oh, something went wrong",
        type: "warning",
        subTitle: {
          text: "The payment failed due to a technical error. It happens, just try again now, or after a couple of minutes. If the error persists, please write to ",
          link: "https://www.sales@newlifeagency.com",
          text2: "our support",
          text3: ", and we will help.",
          cancelButtonLabel: "Close",
          confirmButtonLabel: "Try Again",
        },
      });
    } else if (status == "pending") {
      setPopupData({
        type: "warning",
        title: "Payment on hold!",
        // subTitle: "Your application is currently under processing.",
      });
    } else {
      setPopupData({
        type: "success",
        title: "Payment Completed Successfully!",
        // subTitle: "Your Application has been successfully submitted.",
      });
    }
  }, [location.search]);

  const createPayment = async () => {
    setButtonLoading(true);
    try {
      const resp = await PaymentApis.createPayment({
        invoice_id,
        insurance_type,
      });
      !!resp && setButtonLoading(false);
      !!resp?.success && (window.location = resp?.data?.url);
    } catch (error) {
      console.log("createPayment", error);
    }
  };

  const onPressOkay = () => {
    if (status == "cancel") {
      createPayment();
    } else {
      navigate(
        insurance_type == INSURANCE_TYPES.ivf
          ? AllRoutes.IvfPoliciesPage.route
          : AllRoutes.SadPoliciesPage.route,
        { replace: true }
      );
      setShowModal(false);
    }
  };

  return (
    <AdminPageLayout>
      <CommonModal
        open={showModal}
        type={popupData.type}
        onConfirm={onPressOkay}
        onCancel={
          status === "cancel"
            ? () => {
                navigate(
                  insurance_type == INSURANCE_TYPES.ivf
                    ? AllRoutes.IvfPoliciesPage.route
                    : AllRoutes.SadPoliciesPage.route,
                  { replace: true }
                );
                setShowModal(false);
              }
            : undefined
        }
        confirmButtonLoading={buttonLoading}
        title={popupData?.title || ""}
        subTitle={popupData.subTitle || ""}
        subTitle2={popupData.subTitle2 || ""}
      />
    </AdminPageLayout>
  );
};

export { PaymentLandingPage };
