/*
 * Login page
 */

import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { AuthLayout } from "../../layouts/AuthLayout";
import { AllRoutes } from "../../routes";
import { theme } from "../../styles/theme";
import { Button, ErrorComponent, TextInput } from "../../components";
import { AuthApis } from "../../api";
import { focusFirstErrorField } from "../../utils/CommonUtils";

const validationSchema = {
  email: Yup.string().required("Email is required"),
  password: Yup.string()
    .min(8, "Password must have at least 8 characters")
    .required("Password is required"),
};

export const LoginPage = () => {
  const params = new URLSearchParams(location.search);
  const broker_code = params.get("broker_code");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const firstInputRef = useRef(null);

  const signupPageUrl = broker_code
    ? `${AllRoutes.SignUp.route}?broker_code=${broker_code}`
    : AllRoutes.SignUp.route;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Formik initialization
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object(validationSchema),
    onSubmit: async () => {
      const { email, password } = formik.values;
      setLoginError("");
      setLoading(true);
      const resp = await AuthApis.login({
        email: email?.toLowerCase(),
        password,
      });
      resp && setLoading(false);
      if (!resp?.success && resp?.errors) {
        setLoginError(
          resp?.errors?.global || resp?.errors?.email || resp?.errors?.password
        );
        firstInputRef.current && firstInputRef.current.focus(); // Set focus on first input
      }
    },
  });

  const handleSubmitForm = (e) => {
    e.preventDefault();
    focusFirstErrorField(formik);
    formik.handleSubmit(e);
  };

  return (
    <AuthLayout>
      <Typography
        component="h1"
        variant="h1"
        sx={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}
      >
        Login
      </Typography>

      {!!loginError && <ErrorComponent message={loginError} />}

      <Formik>
        <Form onSubmit={(e) => handleSubmitForm(e)} className="w-75">
          <TextInput
            inputRef={firstInputRef}
            inputKey="email"
            inputLabel="Email"
            disabled={loading}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="email"
            isError={formik.touched.email && formik.errors.email}
            errorMsg={formik.errors.email}
            required={false}
            isAuthPage={true}
          />
          <TextInput
            inputKey="password"
            inputLabel="Password"
            type={showPassword ? "text" : "password"}
            disabled={loading}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            isError={formik.touched.password && formik.errors.password}
            errorMsg={formik.errors.password}
            required={false}
            isAuthPage={true}
          />
          <Typography
            variant="body2"
            sx={{
              textAlign: "right",
              paddingBottom: "1rem",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            <Link
              to={!loading && AllRoutes.ForgotPassword.route}
              className={loading ? "disabled text-primary" : "text-primary"}
            >
              Forgot Password?
            </Link>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth={true}
            size="large"
            type="submit"
            title="Login"
            disabled={loading}
            loading={loading}
          />
          <div>
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                color: theme.grey,
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              <span className="fontRegular">Don’t have a User Account? </span>
              <Link
                to={!loading && signupPageUrl}
                className={loading ? "disabled text-primary" : "text-primary"}
              >
                Register
              </Link>
            </Typography>
          </div>
        </Form>
      </Formik>
    </AuthLayout>
  );
};
