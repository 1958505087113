/*
 * Common MUI Date-Range Picker
 */

import React, { useEffect } from "react";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { FormControl, InputLabel } from "@mui/material";
import { DateRangePicker as MuiDateRangePicker } from "@wojtekmaj/react-daterange-picker";
import { makeStyles } from "@mui/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { theme } from "../styles/theme";

const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    backgroundColor: theme.white,
    borderRadius: "5px",
    border: "1px solid #C4C4C4",
    "&:hover": {
      border: `1px solid ${theme.black}`,
    },
    "&:focus-within": {
      border: `2px solid ${theme.primaryDark}`,
    },
    "& .react-daterange-picker__calendar, & .react-daterange-picker__inputGroup__input":
      {
        height: "36px",
        fontSize: "0.8em",
      },
    "& .react-daterange-picker__inputGroup__leadingZero": {
      fontSize: "0.8em",
    },
    "& .react-daterange-picker__inputGroup": {
      minWidth: "auto",
      textAlign: "center",
    },
    "& .react-daterange-picker__inputGroup__input[name=day]": {
      width: "19px",
    },
    "& .react-daterange-picker__inputGroup__input[name=month]": {
      width: "19px",
    },
    "& .react-daterange-picker__inputGroup__input[name=year]": {
      width: "27px",
    },
    "& .react-daterange-picker__calendar-day--selected": {
      border: "none",
    },
    "& input:focus-visible": {
      outline: "none",
    },
    "& .react-daterange-picker__wrapper": {
      borderRadius: "3px",
      backgroundColor: theme.white,
      border: `1px solid ${theme.fadeGrey}`,
    },
  },
}));

const DateRangePicker = ({
  dateRange,
  handleDateChange,
  label = "Application",
  ...rest
}) => {
  const classes = useStyles();
  const today = new Date();
  const minDate = new Date("1950-01-01");

  useEffect(() => {
    const wrapperElement = document.querySelector(
      ".react-daterange-picker__wrapper"
    );
    if (wrapperElement) {
      wrapperElement.setAttribute("role", "group");
      wrapperElement.setAttribute("aria-labelledby", "daterange");
      const inputGroups = document.querySelectorAll(
        ".react-daterange-picker__inputGroup"
      );
      if (inputGroups.length === 2) {
        inputGroups[0].setAttribute("role", "group");
        inputGroups[0].setAttribute("aria-label", "from date");
        inputGroups[1].setAttribute("role", "group");
        inputGroups[1].setAttribute("aria-label", "to date");
      }
    }
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel
        id="daterange"
        shrink
        sx={{
          backgroundColor: theme.white,
          borderRadius: "5px",
          paddingX: "0.2rem",
          color: "black",
        }}
      >
        {`${label} Date`}
      </InputLabel>
      <MuiDateRangePicker
        className={classes.dateRangePicker}
        onChange={handleDateChange}
        value={dateRange}
        label={`${label} Date`}
        format="MM/dd/yyyy"
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        locale="en-GB"
        clearIcon={null}
        calendarIcon={<CalendarMonthIcon fontSize="small" />}
        maxDate={today}
        minDate={minDate}
        openCalendarOnFocus={false}
        calendarAriaLabel={"Toggle calendar"}
        dayAriaLabel="Day"
        monthAriaLabel="Month"
        yearAriaLabel="Year"
        {...rest}
      />
    </FormControl>
  );
};

export { DateRangePicker };
