/*
 * SAD Summary
 */

import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import {
  CHARGE_GROUPS,
  INSURANCE_TYPES,
  USER_ROLE,
  USER_ROLES,
} from "../../../../constants";
import {
  formattedMDYDate,
  comma_separated,
} from "../../../../utils/CommonUtils";
import { AllRoutes } from "../../../../routes";
import { theme } from "../../../../styles/theme";
import { usePlansWithRiders } from "../../../../hooks";
import { AttachmentApis, SadApis } from "../../../../api";
import { SadActionButtons } from "../components/ActionButtons";
import { LocalStorageHelper } from "../../../../utils/HttpUtils";
import { Accordion, Button, CommonModal } from "../../../../components";
import { sadApplicationSummary } from "../../../../redux/slices/globalSlice";

const Summary = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserAgency = LocalStorageHelper.get(USER_ROLE) == USER_ROLES.agency;
  const { id: application_id } = useParams();
  const summaryData = useSelector((state) => state.sadReducer.sadSummary);
  const [alertsModal, setAlertsModal] = useState(false);
  const [showAffidavitModal, setShowAffidavitModal] = useState(false);
  const [showESignedModal, setShowESignedModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [affidavitLink, setAffidavitLink] = useState("");
  const beneficiariesData = useSelector(
    (state) => state.sadReducer.beneficiaries
  );
  const { getRiderLabelByValue } = usePlansWithRiders(INSURANCE_TYPES.sad);

  const {
    application_number,
    alerts,
    is_currently_pregnant,
    medication_start_date,
    confirmation_of_pregnancy_date,
    term_length,
    indemnity_surrogates_beneficiary,
    indemnity_intended_parents,
    financial_email,
    reproductive_organ_loss_rider,
    reproductive_organ_loss_rider_option,
    disability_rider,
    disability_rider_option,
    intended_parent_address_line_1,
    intended_parent_country,
    intended_parent_zipcode,
    intended_parent_state,
    intended_parent_city,
    intended_parent1_first_name,
    intended_parent1_last_name,
    intended_parent1_phone,
    intended_parent1_email,
    intended_parent2_first_name,
    intended_parent2_last_name,
    intended_parent2_phone,
    intended_parent2_email,
    surrogate_first_name,
    surrogate_last_name,
    surrogate_phone,
    surrogate_email,
    surrogate_dob,
    surrogate_address_line_1,
    surrogate_country,
    surrogate_zipcode,
    surrogate_state,
    surrogate_city,
    working_with_agency,
    agency_country,
    agency_state,
    agency_name,
    agency_contact_person_name,
  } = summaryData ?? {};

  useEffect(() => {
    if (beneficiariesData?.length > 0) {
      setBeneficiaries(beneficiariesData);
    }
  }, []);

  const generalInformation = [
    {
      value: is_currently_pregnant ? "Yes" : "No",
      label: "Is Surrogate Currently Pregnant?",
    },
    {
      value: dayjs(
        is_currently_pregnant
          ? confirmation_of_pregnancy_date
          : medication_start_date
      ).format("MM-DD-YYYY"),
      label: is_currently_pregnant
        ? "Confirmation of Pregnancy Date"
        : "Medication Start Date",
    },
    {
      value: term_length ? `${term_length} Months` : "-",
      label: "Term Length",
    },
    {
      value: `USD $${comma_separated(indemnity_surrogates_beneficiary)}`,
      label: "Limit of Indemnity for Surrogate's Beneficiary",
    },
    {
      value: `USD $${comma_separated(indemnity_intended_parents)}`,
      label: "Limit of Indemnity for Intended Parents",
    },
    !!financial_email && {
      value: financial_email,
      label: "Additional Email Address(s)",
    },
  ];

  const optionalRiderCoverages = [
    {
      value: reproductive_organ_loss_rider ? "Yes" : "No",
      label: "Reproductive Organ Loss Rider",
    },

    !!reproductive_organ_loss_rider && {
      value: `USD ${
        getRiderLabelByValue(
          term_length,
          reproductive_organ_loss_rider_option,
          CHARGE_GROUPS.REPRODUCTIVE_LOSS_RIDER
        ) ?? 0
      }`,
      label: "If Yes Select Coverage Limit",
    },

    { value: disability_rider ? "Yes" : "No", label: "Total Disability Rider" },

    !!disability_rider && {
      value: `USD ${
        getRiderLabelByValue(
          term_length,
          disability_rider_option,
          CHARGE_GROUPS.DISABILITY_RIDER
        ) ?? 0
      }`,
      label: "If Yes Select Coverage Limit",
    },
  ];
  const intendedParentsDetails = [
    { value: intended_parent_address_line_1, label: "Street Address" },
    { value: intended_parent_country, label: "Country" },
    { value: intended_parent_zipcode, label: "ZIP Code" },
    { value: intended_parent_state, label: "State" },
    { value: intended_parent_city, label: "City" },
  ];

  const intendedParent1 = [
    { value: intended_parent1_first_name, label: "First Name" },
    { value: intended_parent1_last_name, label: "Last Name" },
    { value: intended_parent1_phone, label: "Contact Number" },
    { value: intended_parent1_email, label: "Email" },
  ];

  const intendedParent2 = [
    { value: intended_parent2_first_name, label: "First Name" },
    { value: intended_parent2_last_name, label: "Last Name" },
    {
      value: intended_parent2_phone,
      label: "Contact Number",
    },
    { value: intended_parent2_email, label: "Email" },
  ];

  const surrogateInformation = [
    { value: surrogate_first_name, label: "First Name" },
    { value: surrogate_last_name, label: "Last Name" },
    { value: surrogate_phone, label: "Contact Number" },
    { value: surrogate_email, label: "Email" },
    { value: formattedMDYDate(surrogate_dob), label: "DOB" },
    { value: surrogate_address_line_1, label: "Street Address" },
    { value: surrogate_country, label: "Country" },
    { value: surrogate_zipcode, label: "ZIP Code" },
    { value: surrogate_state, label: "State" },
    { value: surrogate_city, label: "City" },
  ];

  const AgencyDetails = [
    {
      value: agency_country,
      label: "Country",
    },
    {
      value: agency_state,
      label: "State",
    },
    {
      value: agency_name,
      label: "Agency Name",
    },
    agency_contact_person_name && {
      value: agency_contact_person_name,
      label: "Contact Person Name",
    },
  ];

  const getAffidavit = async (affidavit_id) => {
    const resp = await AttachmentApis.getPolicyDoc({
      application_id,
      policy_doc_id: affidavit_id,
    });
    !!resp ? setAffidavitLink(resp) : setShowESignedModal(false);
  };

  const submitApplication = async () => {
    setButtonLoading(true);
    const resp = await SadApis.updateApplication({
      application_id,
      params: { submit: true },
    });
    !!resp && setButtonLoading(false);
    if (resp?.success) {
      const { affidavits, alerts, issuable_state_code } = resp?.data ?? {};
      if (affidavits?.length > 0 && issuable_state_code === "MA") {
        setShowAffidavitModal(true);
        getAffidavit(affidavits?.[0]);
      } else if (alerts?.length > 0) {
        setAlertsModal(true);
      } else {
        setActiveStep(4);
      }
    }
    dispatch(sadApplicationSummary({ application_id }));
  };

  const RenderSummaryItem = ({ options = [] }) => {
    return (
      <Grid container>
        {!!options &&
          options?.map((option) => (
            <React.Fragment key={option?.label}>
              <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    lineHeight: "2rem",
                  }}
                >
                  {option?.label ? `${option?.label}:` : ""}
                </Typography>
              </Grid>
              <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    lineHeight: "2rem",
                  }}
                >
                  {option?.value}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    );
  };

  return (
    <>
      {/* General Info */}
      <Accordion
        defaultExpanded={true}
        header="General Information"
        component="h2"
        name="general-info"
      >
        <RenderSummaryItem options={generalInformation} />
      </Accordion>

      {/* Optional Rider Coverages */}
      <Accordion
        header="Optional Rider Coverage"
        component="h2"
        name="rider-coverages"
      >
        <RenderSummaryItem options={optionalRiderCoverages} />
      </Accordion>

      {/* Intended Parents */}
      <Accordion
        header="Intended Parent/s Details"
        component="h2"
        name="parent-details"
      >
        <RenderSummaryItem options={intendedParentsDetails} />
        <Typography
          sx={{ fontWeight: "bold", fontSize: "14px", marginTop: "0.7rem" }}
        >
          Intended Parent-1
        </Typography>
        <RenderSummaryItem options={intendedParent1} />
        {!!intended_parent2_first_name && (
          <>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "14px", marginTop: "0.7rem" }}
            >
              Intended Parent-2
            </Typography>
            <RenderSummaryItem options={intendedParent2} />
          </>
        )}
      </Accordion>

      {/* Surrogate */}
      <Accordion
        header="Surrogate Information"
        component="h2"
        name="surrogate-info"
      >
        <RenderSummaryItem options={surrogateInformation} />
      </Accordion>

      {/* Surrogate Benefeciaries */}
      <Accordion
        header="Surrogate's Designated Beneficiary/Beneficiaries"
        component="h2"
        name="surrogate-beneficiary"
      >
        {beneficiaries?.map((beneficiary, index) => (
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Beneficiary-{index + 1}
              </Typography>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  lineHeight: "2rem",
                }}
              >
                First Name:
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "2rem",
                  color: "#222222",
                }}
              >
                {beneficiary.first_name}
              </Typography>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  lineHeight: "2rem",
                }}
              >
                Last Name:
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "2rem",
                  color: "#222222",
                }}
              >
                {beneficiary.last_name}
              </Typography>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  lineHeight: "2rem",
                }}
              >
                Relationship:
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "2rem",
                  color: "#222222",
                }}
              >
                {beneficiary.relationship}
              </Typography>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  lineHeight: "2rem",
                }}
              >
                Email:
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "2rem",
                  color: "#222222",
                }}
              >
                {beneficiary.email}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Accordion>

      {/* Agency Details */}
      <Accordion header="Agency Details" component="h2" name="agency-details">
        <Grid container>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
            <Typography sx={{ fontSize: "0.9rem", lineHeight: "2rem" }}>
              {isUserAgency
                ? "Which agency you are associated with?"
                : "Are you working with an Agency?"}
            </Typography>
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            <Typography
              sx={{
                fontSize: "0.9rem",
                fontWeight: "bold",
                lineHeight: "2rem",
              }}
            >
              {isUserAgency ? "" : working_with_agency ? "Yes" : "No"}{" "}
            </Typography>
          </Grid>
        </Grid>

        {working_with_agency ? (
          <RenderSummaryItem options={AgencyDetails} />
        ) : (
          ""
        )}
      </Accordion>
      <Grid container columnSpacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: "0.9rem",
              lineHeight: "1.5rem",
              fontStyle: "italic",
            }}
          >
            NOTICE: Please verify the information captured on this form and
            continue.
          </Typography>
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <SadActionButtons
        onPressBack={() => setActiveStep(2)}
        OnSaveExit={() => navigate(AllRoutes.SadPoliciesPage.route)}
        OnPressContinue={submitApplication}
        buttonLoading={buttonLoading}
      />

      {/* Modal when apllication is under review process */}
      <CommonModal
        open={alertsModal}
        type="warning"
        onConfirm={() => {
          navigate(AllRoutes.SadPoliciesPage.route);
          setAlertsModal(false);
        }}
        title={
          `Thank you for submission. Your application is Under Review.  <br/>`

          //--------- N O T E
          // Below code is too show alert labels if any of an application has alerts

          // <small> Alert: ${
          //   !!alerts && alerts?.length > 0
          //     ? alerts.map((label) => `${label}`).join(", ")
          //     : ""
          // } </small>
        }
        subTitle={`Thank you for submitting this application with New Life Agency, LLC. Reference Policy Number: ${
          application_number ?? ""
        } You will hear back from your agent or representative at New Life Agency within 2 business days. Please note, no coverage has been given.`}
      />

      {/* Information modal */}
      <CommonModal
        open={showAffidavitModal}
        type="info"
        onConfirm={() => {
          setShowAffidavitModal(false);
          summaryData?.alerts?.length > 0
            ? setAlertsModal(true)
            : setActiveStep(4);
        }}
        title="Information!"
        subTitle="The state in which this policy is being written in, requires a surplus lines affidavit form to be introduced/signed. The IVF Cycle Complications Insurance policy is a unique policy offered through a surplus lines licensed broker. Such a policy does not currently exist or is offered by major medical insurance carriers in the state the policy is being written in."
      >
        <Button
          variant="text"
          size="medium"
          title="Click To View E-Signed Affidavit"
          sx={{
            fontWeight: "bold",
            fontSize: "0.9rem",
            marginBottom: "0.1rem",
            color: theme.mistBlue,
            borderRadius: "5px",
          }}
          onClick={() => setShowESignedModal(true)}
        />
      </CommonModal>

      {/* E-Signed Affidavit */}
      <CommonModal
        open={showESignedModal}
        onCancel={() => setShowESignedModal(false)}
        cancelButtonLabel="Close"
        maxWidth="lg"
        fullWidth
      >
        {!affidavitLink ? (
          <Box
            sx={{
              width: "100%",
              height: "500px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={affidavitLink}
            width="100%"
            height="500"
            style={{ display: !affidavitLink ? "none" : "block" }}
          />
        )}
      </CommonModal>
    </>
  );
};

export { Summary };
