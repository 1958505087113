import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { CommonModal, DatePicker, Tooltip } from "../../../../../components";
import { useSelector } from "react-redux";
import DropdownIVF from "../common/Dropdown";
import { AttachmentApis } from "../../../../../api";
import TextInputIvf from "../common/TextInput";
import { FilePickerRecipient } from "../../components/FilePickerRecipient";
import { useDispatch } from "react-redux";
import { setIvfDocs } from "../../../../../redux/slices/globalSlice";
import { natureOfComplicationsTooltip } from "../../../../../constants";

const Recipient = ({
  id,
  index,
  disabled = false,
  register,
  coverageTypeSelected,
  setValue,
  watch,
  errors,
  setError,
  application_id,
}) => {
  const dispatch = useDispatch();
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const docs = useSelector((state) => state.ivfReducer.ivfDocs);
  const [complicationCycleModal, setComplicationCycleModal] = useState(false);
  const [recipientAgeModal, setRecipientAgeModal] = useState(false);
  const [hasCycledBefore, setHasCycledBefore] = useState("");
  const [hasCycledComplicationBefore, setHasCycledComplicationBefore] =
    useState("");

  const [files2, setFiles2] = useState([]);
  const [isValidFile2, setIsValidFile2] = useState(true);
  const [fileToBeDeleted2, setFileToBeDeleted2] = useState();
  const [showDeleteModal2, setShowDeleteModal2] = useState(false);
  const [isDocUploading2, setDocUploading2] = useState(false);
  const [buttonLoading2, setButtonLoading2] = useState("");
  const [docRequired2, setDocRequired2] = useState("");

  const openPdfInNewTab2 = (file) => {
    !!file?.url && window.open(file?.url, "_blank");
  };

  const removePdf2 = async () => {
    setButtonLoading2("delete");
    setIsValidFile2(true);
    const resp = await AttachmentApis.deleteAttachment({
      application_id: id,
      document_id: fileToBeDeleted2,
    });
    !resp?.success && setButtonLoading2("");
    !!resp?.success && getAttachments2();
    setShowDeleteModal2(false);
    setFileToBeDeleted2();
  };
  const docId = application_id ? application_id : id;

  const getAttachments2 = async () => {
    const resp = await AttachmentApis.getAttachments({ application_id: docId });
    if (resp) {
      setDocUploading2(false);
      setButtonLoading2("");
    }
    if (resp?.success && resp?.data?.length) {
      const formattedFiles = resp.data?.map((file) => ({
        name: file.original_name,
        type: "",
        size: 0,
        id: file.id,
        url: file.url,
        labelled_as: file.labelled_as,
      }));
      setFiles2(formattedFiles);
      dispatch(setIvfDocs(formattedFiles, `recipient_${id}`));
    } else {
      setFiles2([]);
      dispatch(setIvfDocs([], docId));
    }
  };

  const handleFileUpload2 = async (newFile) => {
    setDocUploading2(true);
    const formData = new FormData();
    formData.append("group", "documents");
    formData.append("labelled_as", `DOC_recipient_${id}`);
    formData.append("file", newFile?.[0]);
    const resp = await AttachmentApis.uploadAttachment({
      application_id: id,
      formData,
      labelled_as: `DOC_recipient_${id}`,
    });
    !resp?.success && setDocUploading2(false);
    if (resp?.success) {
      setDocRequired2("");
      getAttachments2();
    }
  };

  const getDocs = () => {
    if (coverageTypeSelected == "RO") {
      getAttachments2();
    }
  };

  useEffect(() => {
    if (application_id || docs.length > 0) {
      getDocs();
    }
  }, [application_id]);

  useEffect(() => {
    setHasCycledBefore(
      watch(`applications.${index}.recipient_has_cycled_before`)
    );
    setHasCycledComplicationBefore(
      watch(`applications.${index}.recipient_has_cycled_before_complications`)
    );
  }, [
    watch(`applications.${index}.recipient_has_cycled_before_complications`),
    watch(`applications.${index}.recipient_has_cycled_before`),
  ]);

  return (
    <>
      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <TextInputIvf
          register={register}
          disabled={disabled}
          setError={setError}
          isError={
            errors?.applications?.[index]?.[`recipient_name_id`]?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`recipient_name_id`]?.message
          }
          id={`applications.${index}.recipient_name_id`}
          name={`applications.${index}.recipient_name_id`}
          type="text"
          label="Name/ID of Recipient"
          InputLabelProps={{ shrink: true }}
          sx={{ marginTop: "16px", width: "100%" }}
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label="Medication Start Date of Recipient"
          id={`applications.${index}.recipient_medication_start_date`}
          name={`applications.${index}.recipient_medication_start_date`}
          disabled={disabled}
          onChange={(date) => {
            setError(
              `applications.${index}.recipient_medication_start_date`,
              null
            );
            setValue(
              `applications.${index}.recipient_medication_start_date`,
              date
            );
          }}
          isError={
            errors?.applications?.[index]?.[`recipient_medication_start_date`]
              ?.message
          }
          errorMsg={
            errors?.applications?.[index]?.[`recipient_medication_start_date`]
              ?.message
          }
          minDate={dayjs()}
          value={
            watch(`applications.${index}.recipient_medication_start_date`)
              ? dayjs(
                  watch(`applications.${index}.recipient_medication_start_date`)
                )
              : undefined
          }
          maxDate={dayjs().add(60, "day")}
          tooltip={
            "The MSD can be selected no more than 60 days in the future of the submission date."
          }
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DatePicker
          label="DOB of Recipient"
          id={`applications.${index}.recipient_dob`}
          name={`applications.${index}.recipient_dob`}
          disabled={disabled}
          minDate={dayjs().subtract(45, "years")}
          maxDate={dayjs().subtract(21, "years")}
          value={
            watch(`applications.${index}.recipient_dob`)
              ? dayjs(watch(`applications.${index}.recipient_dob`))
              : undefined
          }
          onChange={(date) => {
            setError(`applications.${index}.recipient_dob`, null);
            setValue(`applications.${index}.recipient_dob`, date);
            if (
              dayjs?.(date)?.isAfter?.(dayjs()?.subtract?.(21, "years")) ||
              dayjs?.(date)?.isBefore?.(
                dayjs?.()?.subtract?.(45, "years")?.subtract?.(1, "day")
              )
            ) {
              setRecipientAgeModal(true);
            }
          }}
          defaultCalendarMonth={dayjs().subtract(21, "years")}
          isError={errors?.applications?.[index]?.[`recipient_dob`]?.message}
          errorMsg={errors?.applications?.[index]?.[`recipient_dob`]?.message}
          disableFuture
          tooltip="Minimum age should be 21 years and maximum age should be 45 years."
        />
      </Grid>

      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
        <DropdownIVF
          label="Has Recipient cycled before?"
          id={`applications.${index}.recipient_has_cycled_before`}
          name={`applications.${index}.recipient_has_cycled_before`}
          disabled={disabled}
          options={lookups?.yes_no_list}
          value={watch(`applications.${index}.recipient_has_cycled_before`)}
          {...register(`applications.${index}.recipient_has_cycled_before`, {
            onChange: (e) => {
              setHasCycledBefore(e.target.value);
              setValue(
                `applications.${index}.recipient_has_cycled_before`,
                e.target.value
              );
              setError(
                `applications.${index}.recipient_has_cycled_before`,
                null
              );
              setValue(
                `applications.${index}.recipient_num_previous_cycles`,
                ""
              );
              setValue(
                `applications.${index}.recipient_has_cycled_before_complications`,
                null
              );
              setValue(
                `applications.${index}.recipient_cycle_complications_description`,
                ""
              );
            },
          })}
          isError={
            coverageTypeSelected === "RO"
              ? errors?.applications?.[index]?.[`recipient_has_cycled_before`]
                  ?.message
              : null
          }
          errorMsg={
            errors?.applications?.[index]?.[`recipient_has_cycled_before`]
              ?.message
          }
        />
      </Grid>

      {hasCycledBefore === 1 && (
        <>
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
            <TextInputIvf
              register={register}
              disabled={disabled}
              setError={setError}
              isError={
                errors?.applications?.[index]?.[`recipient_num_previous_cycles`]
                  ?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[`recipient_num_previous_cycles`]
                  ?.message
              }
              id={`applications.${index}.recipient_num_previous_cycles`}
              name={`applications.${index}.recipient_num_previous_cycles`}
              type="number"
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              inputProps={{
                min: 1,
                onInput: (e) => {
                  if (e.target.value < 1) {
                    e.target.value = "";
                  }
                },
              }}
              label="Number of Previous Cycles?"
              InputLabelProps={{ shrink: true }}
              sx={{ marginTop: "16px", width: "100%" }}
            />
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <DropdownIVF
              label="Has the Recipient had Previous Cycle Related Complications?"
              id={`applications.${index}.recipient_has_cycled_before_complications`}
              name={`applications.${index}.recipient_has_cycled_before_complications`}
              disabled={disabled}
              options={lookups?.yes_no_list}
              value={watch(
                `applications.${index}.recipient_has_cycled_before_complications`
              )}
              {...register(
                `applications.${index}.recipient_has_cycled_before_complications`,
                {
                  onChange: (e) => {
                    setHasCycledComplicationBefore(e.target.value);
                    e.target.value === 1 && setComplicationCycleModal(true);
                    setValue(
                      `applications.${index}.recipient_has_cycled_before_complications`,
                      e.target.value
                    );
                    setError(
                      `applications.${index}.recipient_has_cycled_before_complications`,
                      null
                    );
                    setValue(
                      `applications.${index}.recipient_cycle_complications_description`,
                      ""
                    );
                  },
                }
              )}
              isError={
                errors?.applications?.[index]?.[
                  `recipient_has_cycled_before_complications`
                ]?.message
              }
              errorMsg={
                errors?.applications?.[index]?.[
                  `recipient_has_cycled_before_complications`
                ]?.message
              }
            />
          </Grid>

          {hasCycledComplicationBefore === 1 && (
            <>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                paddingTop={"18px"}
              >
                <TextInputIvf
                  register={register}
                  name={`applications.${index}.recipient_cycle_complications_description`}
                  id={`applications.${index}.recipient_cycle_complications_description`}
                  disabled={disabled}
                  label="Please Describe Nature of Complications"
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                  setError={setError}
                  isError={
                    errors?.applications?.[index]?.[
                      `recipient_cycle_complications_description`
                    ]?.message
                  }
                  errorMsg={
                    errors?.applications?.[index]?.[
                      `recipient_cycle_complications_description`
                    ]?.message
                  }
                  tooltip={natureOfComplicationsTooltip}
                />
              </Grid>

              <Box
                sx={{
                  display: hasCycledComplicationBefore == 1 ? "block" : "none",
                }}
                alignItems="center"
                width="100%"
                marginY="2rem"
                mt={3}
                px={3}
              >
                <Typography
                  marginBottom="1rem"
                  id={`lbl-upload-file-recipient_${application_id}`}
                  fontSize="14px"
                >
                  Please upload the supporting documents.
                  <Tooltip tooltip="File can only be type of pdf / word" />
                </Typography>
                <FilePickerRecipient
                  disabled={disabled}
                  files={files2}
                  setFiles={setFiles2}
                  setIsValidFile={setIsValidFile2}
                  openPdfInNewTab={openPdfInNewTab2}
                  setFileToBeDeleted={setFileToBeDeleted2}
                  setShowDeleteModal={setShowDeleteModal2}
                  handleFileUpload={(newFile) => handleFileUpload2(newFile)}
                  isDocUploading={isDocUploading2}
                  id={`recipient_${id}`}
                  labelled_as_id={id}
                />
                <FormHelperText
                  sx={{
                    marginLeft: 0,
                    fontStyle: "italic",
                    fontWeight: 600,
                    letterSpacing: 0,
                    marginTop: "1rem",
                  }}
                  id="helper-text-upload-file"
                >
                  Note:{" "}
                  {isValidFile2
                    ? "Two Files Max"
                    : "Only pdf and word file allowed"}
                </FormHelperText>
                <FormHelperText error sx={{ marginLeft: 0 }} id={"error-doc"}>
                  {docRequired2}
                </FormHelperText>
              </Box>
            </>
          )}
        </>
      )}

      <CommonModal
        open={recipientAgeModal}
        type="warning"
        onConfirm={() => {
          setValue(`applications.${index}.recipient_dob`, null);
          setRecipientAgeModal(false);
          setTimeout(() => {
            document
              .getElementById(`applications.${index}.recipient_dob`)
              ?.focus();
          }, 100);
        }}
        title="Please Note"
        subTitle="The age of this person is either below the minimum age requirement which is 21 years or maximum age allowed by this automated system.
        Due to the Recipient Age, we cannot approve coverage at this time.
        If you have any questions, please contact your broker."
      />

      <CommonModal
        open={complicationCycleModal}
        type="warning"
        onConfirm={() => setComplicationCycleModal(false)}
        title="Please Note"
        subTitle="Please be aware!        
        Due to the complications cycles this application will need to be reviewed.        
        If you have any questions, please contact your broker."
      />

      <CommonModal
        open={showDeleteModal2}
        type="delete"
        onCancel={() => setShowDeleteModal2(false)}
        confirmButtonLabel="Delete"
        onConfirm={removePdf2}
        confirmButtonLoading={buttonLoading2 == "delete"}
        title="Are You Sure?"
        subTitle="Do you want to delete this file?"
      />
    </>
  );
};

export default Recipient;
