import "@fontsource/lato/300.css"; // Lato Light
import "@fontsource/lato/400.css"; // Lato Regular
import "@fontsource/lato/700.css"; // Lato Bold
import "./index.css";

import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  createBrowserRouter,
  isRouteErrorResponse,
  RouterProvider,
  useRouteError,
} from "react-router-dom";

import { ErrorPage } from "./components/ErrorPage.js";
import { getUserRoutes } from "./routes";
import { store } from "./redux/store";
import { LocalStorageHelper } from "./utils/HttpUtils.js";
import { USER_DATA, USER_TOKEN } from "./constants/storageKeys.js";
import { TOAST_TIME } from "./constants/constantData.js";

const ErrorBoundary = () => {
  const error = useRouteError();
  return <ErrorPage is404={isRouteErrorResponse(error)} />;
};

const AllRoutes = getUserRoutes(JSON.parse(LocalStorageHelper.get(USER_DATA)));

const router = createBrowserRouter(
  Object.keys(AllRoutes)?.map((r) => {
    const user = {};
    const Component = AllRoutes[r]?.component
      ? AllRoutes[r]?.component
      : () => {};
    const props = AllRoutes[r]?.props || {};
    const route = `${AllRoutes[r]?.route || "404"}`;
    const children = (AllRoutes[r]?.children || [])?.map((child) => {
      const Component = child?.component ? child?.component : () => {};
      const route = `${child?.route || "404"}`;
      return {
        ...child,
        element: <Component {...{ ...props, user }} />,
        errorElement: <ErrorBoundary />,
        path: route,
      };
    });
    return {
      ...AllRoutes[r],
      errorElement: <ErrorBoundary />,
      path: route,
      element: <Component {...{ ...props, user }} />,
      children,
    };
  })
);

const App = () => {
  const AUTH_ROUTES = [
    AllRoutes.SignUp.route,
    AllRoutes.PrivacyPolicy.route,
    AllRoutes.ForgotPassword.route,
    AllRoutes.CreateNewPassword.route,
  ];

  // Navigate to login if there is no token
  useEffect(() => {
    const user = LocalStorageHelper.get(USER_TOKEN);
    const currentRoute = window.location.pathname;
    if (
      !user &&
      currentRoute !== AllRoutes.Login.route &&
      AUTH_ROUTES?.every((x) => !currentRoute?.includes(x))
    ) {
      window.location.href = AllRoutes.Login.route;
    }
  }, []);

  return (
    <Provider store={store}>
      <Toaster
        toastOptions={{
          duration: TOAST_TIME,
          style: { background: "#dc3545", color: "#fff" },
          iconTheme: { primary: "#fff", secondary: "#dc3545" },
        }}
      />
      <RouterProvider router={router} />
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
