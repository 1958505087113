/*
 * Search, clear and advance filter buttons for all admin grids
 */

import React, { useRef, useEffect } from "react";
import { Button } from "./Button";
import FilterListIcon from "@mui/icons-material/FilterList";

import { theme } from "../styles/theme";
import { Box } from "@mui/material";

const GridActionButtons = ({
  onClear,
  onClickAdvSearch,
  onSearch,
  wrapperStyle,
  searchButtonLoading,
  clearButtonLoading,
}) => {
  const clearButtonRef = useRef(null); // Create a ref for the clear button
  
  const handleClear = () => {
    onClear();
    if (clearButtonRef.current) {
      setTimeout(() => {
        clearButtonRef.current.focus(); // Set focus on the clear button
      }, 2000);
    }
  };

  const baseButtonStyle = {
    minWidth: "80px",
    marginRight: "7px",
    height: "40px",
    minHeight: "40px",
  };

  const buttonHeight = "40px";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        height: buttonHeight,
        width: "auto",
        ...wrapperStyle,
      }}
    >
      <Button
        variant="contained"
        disableElevation={false}
        type="submit"
        sx={{
          ...baseButtonStyle,
          height: buttonHeight,
          flex: "none",
        }}
        onClick={onSearch}
        loading={searchButtonLoading}
        disabled={searchButtonLoading || clearButtonLoading}
        title="Search"
        size="medium"
      />
      <Button
        variant="outlined"
        ref={clearButtonRef} // Attach the ref to the clear button
        sx={{
          ...baseButtonStyle,
          borderColor: theme.grey,
          color: theme.grey,
          backgroundColor: theme.white,
          height: buttonHeight,
          flex: "none",
        }}
        onClick={handleClear} // Use the new handleClear function
        loading={clearButtonLoading}
        disabled={searchButtonLoading || clearButtonLoading}
        title="Clear"
        size="medium"
      />

      {!!onClickAdvSearch && (
        <Button
          onClick={onClickAdvSearch}
          variant="outlined"
          sx={{
            backgroundColor: theme.white,
            borderRadius: "50%",
            borderColor: theme.grey,
            width: buttonHeight,
            height: buttonHeight,
            minWidth: "unset",
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          title={
            <FilterListIcon sx={{ color: theme.grey, fontSize: "1.5rem" }} />
          }
          size="medium"
        />
      )}
    </Box>
  );
};

export { GridActionButtons };
