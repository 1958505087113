/**
 * Custom hook to fetch my profile data
 */

import { useState, useEffect, useCallback } from "react";

import { CommonApis } from "../api";

export const useMyProfile = () => {
  const [myData, setMyData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (!myData) {
        const resp = await CommonApis.getMyProfile();
        setMyData(resp?.data || {});
      }
    };

    fetchProfileData();
  }, [myData]);

  const getProfileField = useCallback(
    (field) => {
      return myData ? myData[field] : null;
    },
    [myData]
  );

  return { myData, getProfileField };
};
