/*
 * Common MUI DatePicker
 */

import React, { useState, useRef } from "react";
import dayjs from "dayjs";
import { FormHelperText, IconButton } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker as MuiDatePicker } from "@mui/x-date-pickers";

import { Tooltip } from "./Tooltip";
import { useDebounce } from "../hooks";
import { DEBOUNCE_DELAY } from "../constants";

const DatePicker = ({
  id,
  label,
  disabled = false,
  value,
  onChange,
  required = true,
  tooltip,
  isError,
  errorMsg,
  helperText,
  maxDate,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);

  const handleDateChange = useDebounce((e) => {
    const newValue = e.target.value;
    if (newValue?.length === 10) {
      // Check if the input is complete
      const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (dateRegex.test(formattedDate)) {
        onChange(formattedDate);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 100);
      }
    }
  }, DEBOUNCE_DELAY);

  const handleAcceptDate = (date) => {
    onChange(dayjs(date).format("YYYY-MM-DD"));
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const getInputAdornment = () => {
    return (
      <Tooltip tooltip="Open the calendar">
        <span>
          <IconButton
            disabled={disabled}
            onClick={() => setOpen(true)}
            sx={{ padding: 0, marginRight: "-0.3rem" }}
            aria-label="Open the calendar"
          >
            <CalendarMonthOutlinedIcon sx={{ color: "#767676" }} />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <>
      <LocalizationProvider key={`${id}-${value}`} dateAdapter={AdapterDayjs}>
        <MuiDatePicker
          open={open}
          onClose={() => setOpen(false)}
          slots={{ inputAdornment: getInputAdornment }}
          label={
            required ? (
              <>
                {label}
                <span className="text-danger"> *&nbsp;</span>
                {!!tooltip && <Tooltip tooltip={tooltip} disabled={disabled} />}
              </>
            ) : (
              label
            )
          }
          disabled={disabled}
          value={value}
          // onChange={handleDateChange}
          onAccept={handleAcceptDate}
          slotProps={{
            textField: {
              fullWidth: true,
              error: false,
              InputLabelProps: { shrink: true, style: { color: "black" } },
              id: id,
              inputRef: inputRef,
              inputProps: {
                "aria-describedby":
                  (isError ? "error-" + id : "") +
                  (isError && helperText ? " " : "") +
                  (helperText ? "helper-text-" + id : ""),
                onInput: handleDateChange,
              },
              // onClick: () => !disabled && setOpen(true),
            },
          }}
          PopperProps={{
            placement: "bottom", // Add this line
          }}
          sx={{ marginTop: "1rem" }}
          required={required}
          minDate={dayjs("1950-01-01")} // Set the minimum date
          maxDate={maxDate || dayjs().add(1, "year")} // Set the maximum date
          {...rest}
        />
      </LocalizationProvider>
      {isError && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
          id={"error-" + id}
        >
          {errorMsg}
        </FormHelperText>
      )}
      {!!helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
          }}
          id={"helper-text-" + id}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export { DatePicker };
