/*
 * Admin -> Wizard -> IVF Clinics -> Clinic's users list -> Table data
 */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { UsersApis } from "../../../../../api";
import { AllRoutes } from "../../../../../routes";
import ReactTable from "../../../../../React Table";
import {
  CommonModal,
  GlobalLoader,
  NoRecords,
  RenderActionMenu,
  RenderCellInfo,
  RenderTableHeader,
} from "../../../../../components";
import { strings } from "../../../../../constants";

const TableData = ({
  caption,
  clinicData,
  hasRecords,
  page,
  setPage,
  dataList,
  setDataList,
  listData,
  loading,
  dataLoading,
  setAdvancedSearch,
}) => {
  const navigate = useNavigate();
  const { clinic_id } = useParams();
  const [clickable, setClickable] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState("");

  const updateUser = async (rowData, sendInvite) => {
    setActionLoading(true);
    const resp = await UsersApis.updateUser({
      user_id: rowData?.id,
      params: sendInvite
        ? { invite: 1 }
        : { is_clinic_admin: Number(!rowData?.is_clinic_admin) },
    });
    setActionLoading(false);
    if (resp?.success) {
      setUpdateModal(
        `${sendInvite ? "Email Sent" : "User Updated"} Successfully!`
      );

      if (!sendInvite) {
        setDataList((prev) =>
          prev?.map((item) =>
            item?.id == rowData?.id
              ? {
                  ...item,
                  is_clinic_admin: Number(resp?.data?.is_clinic_admin),
                }
              : item
          )
        );
      }
    }
  };

  const openActionDropDown = (e, rowData) => {
    const { id } = rowData ?? {};
    setClickable([
      {
        text: strings.editUser,
        route: () =>
          navigate(`${AllRoutes.EditClinicUser.route}/${clinic_id}/${id}`),
      },
      {
        text: "Resend Invite Email",
        route: () => updateUser(rowData, true),
      },
    ]);
    setSelectedRow(rowData);
    setActionAnchorEl(e.currentTarget);
  };

  const closeActionDropDown = () => {
    setSelectedRow(null);
    setActionAnchorEl(null);
    setClickable([]);
  };

  const tableColumns = [
    {
      id: "Actions",
      header: "Action",
      cell: ({ row }) => (
        <RenderCellInfo
          text={
            <IconButton
              onClick={(e) => openActionDropDown(e, row.original)}
              aria-label={"More actions of " + row.original.application_number}
              disabled={clinicData?.is_active <= 0}
            >
              <MoreHorizIcon />
            </IconButton>
          }
        />
      ),
    },
    {
      id: "is_clinic_admin",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Admin User"
          headerKey="is_clinic_admin"
        />
      ),
      accessorKey: "is_clinic_admin",
      cell: (info) => {
        const rowData = info?.row?.original || {};
        const data = info?.getValue();

        return (
          <RenderCellInfo
            text={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  key={data}
                  checked={data}
                  onChange={() => updateUser(rowData)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography sx={{ ml: "0.5rem" }}>
                  {data ? "Yes" : "No"}
                </Typography>
              </Box>
            }
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Name",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Name"
          headerKey="first_name"
        />
      ),
      accessorKey: "first_name",
      cell: (info) => {
        const { last_name } = info?.row?.original ?? {};
        return (
          <RenderCellInfo
            text={`${info.getValue() || ""} ${last_name || ""}`}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      id: "Email",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Email"
          headerKey="email"
        />
      ),
      accessorKey: "email",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
    {
      id: "Contact No.",
      header: ({ column }) => (
        <RenderTableHeader
          column={column}
          headerName="Contact No."
          headerKey="mobile"
        />
      ),
      accessorKey: "mobile",
      cell: (info) => <RenderCellInfo text={info.getValue()} />,
      footer: (props) => props.column.id,
    },
  ];

  return (
    <>
      {loading ? (
        <GlobalLoader loading={loading} />
      ) : hasRecords ? (
        <Box>
          <ReactTable
            tableColumns={tableColumns}
            tableData={dataList}
            isUserModule={true}
            page={page}
            setPage={setPage}
            listData={listData}
            onSearch={(e) => {
              setAdvancedSearch((p) => ({ ...p, ["q"]: e }));
              setPage(1);
            }}
            caption={caption}
            dataLoading={dataLoading}
          />
          <RenderActionMenu
            anchorEl={actionAnchorEl}
            handleClose={closeActionDropDown}
            actions={clickable}
          />
        </Box>
      ) : (
        <NoRecords />
      )}

      {actionLoading && <GlobalLoader loading={actionLoading} />}

      <CommonModal
        open={updateModal}
        type="success"
        onConfirm={() => setUpdateModal("")}
        title={updateModal}
      />
    </>
  );
};

export { TableData };
