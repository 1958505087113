/*
 * Admin -> Wizard -> Coverage Type -> Table header
 */

import React from "react";
import { Grid } from "@mui/material";
import { Dropdown } from "../../../components";
import { INSURANCE_TYPES_LIST, PLANS_TYPES_LIST } from "../../../constants";

const TableHeader = ({
  selectedInsurance,
  setSelectedInsurance,
  selectedPlan,
  setSelectedPlan,
  setSearchParams,
}) => {
  return (
    <form>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: "0.3rem", marginBottom: "1.5rem" }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Dropdown
            size="small"
            label="Type of Insurance"
            id="selectedInsurance"
            options={INSURANCE_TYPES_LIST || []}
            required={false}
            value={selectedInsurance}
            onChange={(e) => {
              setSelectedInsurance(e.target.value);
              setSearchParams((searchParams) => {
                searchParams.set("insurance_type", e.target.value);
                return searchParams;
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Dropdown
            size="small"
            label="Plan Type"
            id="selectedPlan"
            options={PLANS_TYPES_LIST}
            selectValue={selectedPlan}
            required={false}
            value={selectedPlan}
            onChange={(e) => {
              setSelectedPlan(e.target.value);
              setSearchParams((searchParams) => {
                searchParams.set("riders_only", e.target.value);
                return searchParams;
              });
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export { TableHeader };
