/*
 * Common Component based on react-hook-form for address, country, zip-code, state and city
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { TextInput } from "./TextInput";
import { Dropdown } from "./Dropdown";
import { CommonApis } from "../api";
import { useDebounce } from "../hooks";
import { DEBOUNCE_DELAY } from "../constants";
import { constructStateList } from "../utils/CommonUtils";

const AddressInput = ({
  disabled = false,
  register,
  setValue,
  watch,
  requiredAddress = true,
  requiredCity = true,
  disableCountry = false,
  disableZip = false,
  disableState = false,
  addressKey,
  countryKey,
  zipcodeKey,
  stateKey,
  cityKey,
  getValues,
  onAddressChange,
  onCountryChange,
  onZipcodeChange,
  onStateChange,
  onCityChange,
  addressError,
  countryError,
  zipcodeError,
  stateError,
  cityError,
  setLoading,
  errors,
  setError,
}) => {
  const lookups = useSelector((state) => state.commonReducer.lookups);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    !!watch(countryKey) && getStateList();
  }, [watch(countryKey)]);

  const getStateList = async () => {
    const resp = await CommonApis.getZipData({
      country_code: watch(countryKey),
    });

    const getStates = constructStateList(resp);
    setStateList(getStates ?? []);
  };

  const handleAddressChange = (event) => {
    if (onAddressChange) {
      onAddressChange(event.target.value);
    }
    if (setError && (errors?.address_line_1?.message || addressError)) {
      setError(addressKey, false);
    }
  };

  const handleCountryChange = (event) => {
    setValue(zipcodeKey, "");
    setValue(stateKey, null);
    setValue(cityKey, "");
    if (onCountryChange) {
      onCountryChange(event.target.value);
    }
  };

  const debouncedSearch = useDebounce(async (e) => {
    if (e.target.value === "") {
      setValue(cityKey, "");
      setValue(stateKey, null);
      return;
    }
    !!setLoading && setLoading(true);

    const resp = await CommonApis.getZipData({
      country_code: watch(countryKey),
      zipcode: e.target.value,
    });

    setValue(stateKey, resp?.[0]?.state_code || null);
    if (resp?.[0]?.state_code && errors?.state_code?.message && setError) {
      setError(stateKey, null);
    }

    setValue(cityKey, resp?.[0]?.city || "");
    if (resp?.[0]?.city && errors?.city?.message && setError) {
      setError(cityKey, null);
    }
    !!setLoading && setLoading(false);
  }, DEBOUNCE_DELAY);

  // Find state and city on enter zip code
  const handleZipcodeChange = async (e) => {
    if (errors?.zipcode?.message) {
      !!setError && setError(zipcodeKey, null);
    }
    // Remove any character that is not a letter or a digit from the input value
    const sanitizedValue = e.target.value
      .replace?.(/^ /, "")
      ?.replace(/ +/g, " ");

    // Update the zipcode field with the sanitized value
    setValue(zipcodeKey, sanitizedValue);

    // Clear city and state if the zipcode is empty
    if (sanitizedValue.length > 2) {
      debouncedSearch(e);
    } else {
      setValue(cityKey, "");
      setValue(stateKey, null);
    }
    if (onZipcodeChange) {
      onZipcodeChange(e.target.value);
    }
  };

  const handleStateChange = async (e) => {
    setValue(stateKey, getValues(stateKey));
    if (onStateChange) {
      onStateChange();
    }
  };

  const handleCityChange = async (e) => {
    setValue(cityKey, getValues(cityKey));
    if (onCityChange) {
      onCityChange();
    }
  };

  return (
    <>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ padding: "10px" }}
      >
        <TextInput
          inputKey={addressKey}
          inputLabel="Street Address"
          autoComplete="off"
          required={requiredAddress}
          disabled={disabled}
          helperText="Note: P.O. Boxes are not allowed. Please enter a full street address."
          register={register}
          registerChange={handleAddressChange}
          isError={errors?.address_line_1?.message || addressError}
          errorMsg={errors?.address_line_1?.message || addressError}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ padding: "10px" }}>
        <Dropdown
          label="Country"
          id={countryKey}
          options={lookups?.countries_list || []}
          disabled={disabled || disableCountry}
          register={register}
          value={watch(countryKey)}
          registerChange={handleCountryChange}
          isError={errors?.country_code?.message || countryError}
          errorMsg={errors?.country_code?.message || countryError}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ padding: "10px" }}>
        <TextInput
          inputKey={zipcodeKey}
          inputLabel="ZIP Code"
          autoComplete="off"
          disabled={disabled || disableZip || !watch(countryKey)}
          maxLength={9}
          register={register}
          registerChange={handleZipcodeChange}
          isError={errors?.zipcode?.message || zipcodeError}
          errorMsg={errors?.zipcode?.message || zipcodeError}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ padding: "10px" }}>
        <Dropdown
          id={stateKey}
          label="State"
          disabled={disabled || disableState || !watch(zipcodeKey)}
          value={watch(stateKey)}
          options={stateList || []}
          register={register}
          registerChange={handleStateChange}
          isError={errors?.state_code?.message || stateError}
          errorMsg={errors?.state_code?.message || stateError}
        />
      </Grid>
      <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ padding: "10px" }}>
        <TextInput
          inputKey={cityKey}
          inputLabel="City"
          autoComplete="off"
          required={requiredCity}
          disabled={disabled || !watch(countryKey)}
          register={register}
          registerChange={() => {
            handleCityChange();
            !!setError && setError(cityKey, false);
          }}
          isError={errors?.city?.message || cityError}
          errorMsg={errors?.city?.message || cityError}
        />
      </Grid>
    </>
  );
};

export { AddressInput };
